import { Injectable } from '@angular/core';
import { LoggerService } from '../logger/logger.service';
import { IPropertyCompact } from '../../model/properties/property-compact';
import _ from 'lodash';
import { IPropertyKeyValuePair } from '../../model/properties/property';
import { SearchResourceDictionaryService } from '../resource-dictionary/search-resource-dictionary.service';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { BsApiEndPoints } from '../bs-api/endpoints';
import { map } from 'rxjs/operators';
import { PropertyName } from '@app/core/model/properties/property-name';

export class PropertyType {

  public static string = 'string';
  public static stringArray = 'string[]';

  public static sample = 'sample';
  public static sampleArray = 'sample[]';

  public static appresult = 'appresult';
  public static appresultArray = 'appresult[]';

  public static project = 'project';
  public static projectArray = 'project[]';

  public static biosample = 'biosample';
  public static biosampleArray = 'biosample[]';

  public static library = 'library';
  public static libraryArray = 'library[]';

  public static run = 'run';
  public static runArray = 'run[]';

  public static map = 'map';
  public static mapArray = 'map[]';
}

const CONTENT_FIELD_NAMES = [
  'Content',
  'ContentMap',
  'Application',
  'AppSession',
  'BioSample',
  'Dataset',
  'File',
  'SampleLibrary',
  'LibraryPool',
  'Project',
  'Run',
  'Subject',
  'User',
  'ContentMapItems',
  'ContentItems',
  'ApplicationItems',
  'AppSessionItems',
  'BioSampleItems',
  'DatasetItems',
  'FileItems',
  'SampleLibraryItems',
  'LibraryPoolItems',
  'ProjectItems',
  'RunItems',
  'SubjectItems',
  'UserItems',
  'Items' 
];

@Injectable({
  providedIn: 'root'
})
export class PropertyService {

  constructor(
    private logger: LoggerService,
    private resourceDictionaryService: SearchResourceDictionaryService,
    private http: HttpClient
    ) { }


  public getPropertyValue(properties: IPropertyCompact[], propertyName: string) {
    const property = this.getProperty(properties, propertyName);
    
    return (!property) ? null : this.getPropertyItems(property);
  }

  public getProperty(properties: IPropertyCompact[], propertyName: string): IPropertyCompact {
    const property = properties.find((prop) => {
      if (_.isString(prop.Name) && prop.Name.toLowerCase() === propertyName.toLowerCase()) {
        return prop;
      }
    });

    return property ? property : null;
  }

  public getPropertyItems(property: IPropertyCompact) {
    const itemKeys = _.keys(property).filter(key => CONTENT_FIELD_NAMES.indexOf(key) > -1);
    if (itemKeys.length === 1) {
      return property[itemKeys[0]];
    }

    this.logger.warn('Didn\'t find exactly one Items type property in property object of type ' +
      property.Type + ', found [' + itemKeys.join(',') + ']');

    return null;
  }

  public keyValsToObject(keyVals: IPropertyKeyValuePair[]): any {
    const obj = keyVals.reduce((p, c) => {
      p[c.Key] = c.Values.length === 1 ? c.Values[0] : c.Values;
      return p;
    }, {});
    return obj;
  }

  public getPropertyMapValue(properties: IPropertyCompact[], propertyName: string, key: string): string[] {
    const keyValues: IPropertyKeyValuePair[] =
      this.getPropertyValue(properties, propertyName) as IPropertyKeyValuePair[];

    if (!keyValues) {
      return null;
    }

    keyValues.forEach((keyValue) => {
      if (_.isString(keyValue.Key) && keyValue.Key.toLowerCase() === key.toLowerCase()) {
        return keyValue.Values;
      }
    });

    return null;
  }

  public setStringArrayProp(
    resourceType: string,
    resourceId: string,
    propertyName: string,
    strings: string[],
    propertyDescription?: string
  ) {
    const params = {
        Properties: [
            {
                Name: propertyName,
                Description: propertyDescription,
                Type: PropertyType.stringArray,
                Items: strings
            }
        ]
    };
    return this.setProperty(resourceType, resourceId, params);
  }

  public setStringProp(
    resourceType: string,
    resourceId: string,
    propertyName: string,
    theString: string,
    propertyDescription?: string
  ) {
    const params = {
        Properties: [
            {
                Name: propertyName,
                Description: propertyDescription,
                Type: PropertyType.string,
                Content: theString
            }
        ]
    };
    return this.setProperty(resourceType, resourceId, params);
}

  public setProperty(resourceType: string, resourceId: string, params: any): Observable<any> {
    let url;

    // Migrated a minimal amount of code to get this work in new codebase
    switch(resourceType) {
      case 'appresult_file':
        url = BsApiEndPoints.getAppResultFilePropertiesUrl(resourceId);
        break;
      case 'appresult':
        url = BsApiEndPoints.getAppResultPropertiesUrl(resourceId);
        break;
      case 'appsession':
        url = BsApiEndPoints.getAppSessionPropertiesUrl(resourceId);
        break;
      default:
        throw new Error(`Undefined property resource type: '${resourceType}'`);

    }

    return this.http.post(url, {...params, Id: resourceId });
  }

  public getResourceProperty(resourceType: string, resourceId: string, propertyName: PropertyName): Observable<any> {
    let url;

    // Migrated a minimal amount of code to get this work in new codebase
    switch(resourceType) {
      // case 'appresult_file':
      //   url = BsApiEndPoints.getAppResultFilePropertiesUrl(resourceId);
      //   break;
      // case 'appresult':
      //   url = BsApiEndPoints.getAppResultPropertiesUrl(resourceId);
      //   break;
      case 'appsession':
        url = `${BsApiEndPoints.getAppSessionPropertiesUrl(resourceId)}/${propertyName}`;
        break;
      default:
        throw new Error(`Undefined property resource type: '${resourceType}'`);

    }

    return this.http.get(url).pipe(
      map((response: any) => response.Response)
    );
  }

  public setBiosampleArrayProp(
    resourceType: string,
    resourceId: string,
    propertyName: string,
    biosampleIds: string[],
    propertyDescription?: string
  ): Observable<any> {
    const items = biosampleIds.map((id: string) => this.resourceDictionaryService.biosample.apiHrefAccessor({ BioSample: { Id: id } }));
    const params = {
        Properties: [
            {
                Name: propertyName,
                Description: propertyDescription,
                Type: PropertyType.biosampleArray,
                Items: items
            }
        ]
    };
    return this.setProperty(resourceType, resourceId, params);
  }

  public setSampleArrayProp(
    resourceType: string,
    resourceId: string,
    propertyName: string,
    sampleIds: string[],
    propertyDescription?: string
  ): Observable<any> {
    const items = sampleIds.map((id: string) => this.resourceDictionaryService.sample.apiHrefAccessor({ Sample: { Id: id } }));
    const params = {
        Properties: [
            {
                Name: propertyName,
                Description: propertyDescription,
                Type: PropertyType.sampleArray,
                Items: items
            }
        ]
    };
    return this.setProperty(resourceType, resourceId, params);
  }

}
