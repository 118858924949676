import { SearchResourceDictionaryService } from '@app/core/services/resource-dictionary/search-resource-dictionary.service';
import { OverrideColumnDefinition, OverrideColumnParameters } from '@app/search/gridOptions/search-grid-options-formatter';
import { FileSearchColumnFields, FileSearchGridOptionsFormatter } from '@app/search/gridOptions/file-search-grid-options-formatter';
import { BsshDatePipe } from '@app/core/utilities/pipes/bssh-date.pipe';
import { DataTableUtilities } from '@app/core/utilities/data-table-utilities';
import { SiteNavigationLinksService } from '@app/core/services/site-navigation/site-navigation-links.service';
import { trimEnd } from 'lodash';

export class FileChooserGridOptionsFormatter extends FileSearchGridOptionsFormatter {
  constructor(
    public resourceDictionaryService: SearchResourceDictionaryService,
    public bsshDatePipe: BsshDatePipe,
    private siteNavigationLinksService: SiteNavigationLinksService
  ) {
    super(resourceDictionaryService, bsshDatePipe);
  }

  protected getOverrideColumnParameters(): OverrideColumnParameters<FileSearchColumnFields> {
    const inclusiveColumns: FileSearchColumnFields[] = [
      FileSearchColumnFields.Name,
      FileSearchColumnFields.Dataset,
      FileSearchColumnFields.Project,
      FileSearchColumnFields.Created,
    ];

    const overrideColumnDefinitions: OverrideColumnDefinition<FileSearchColumnFields>[] = [
      {
        colId: FileSearchColumnFields.Name,
        cellRendererSelector: (params) => {
          if(params.data) {
            const file = params.data.DatasetFile;
            const dataset = file.ParentDataset;
            const folderPath = trimEnd(file.Path.replace(`${file.Name}`, ''), '/');
            const href = this.siteNavigationLinksService.fileSummaryUrl(dataset.AppSession.Id, dataset.Id)
              + (folderPath ? `/${folderPath}` : '')
            return DataTableUtilities.getLinkRenderer(file.Name, href, null, '_blank');
          } else {
            return DataTableUtilities.getSpanRenderer();
          }
        },
      }
  ];

    return {
      inclusiveColumns,
      overrideColumnDefinitions
    };
  }
}