import { Injectable } from '@angular/core';
import { NavigationEnd, Router, UrlTree, UrlSegmentGroup, PRIMARY_OUTLET, UrlSegment, ActivatedRoute } from '@angular/router';
import { filter } from 'rxjs/operators';
import { ResourceType } from '@app/core/model/resource-type';
import { ErrorMessages } from '@app/core/utilities/error-messages';
import { BaseService } from '@app/core/services/base.service';
import { isNullOrUndefined } from 'util';
import _ from 'lodash';
import { Observable, of, merge } from 'rxjs';

/**
 * The service provides the current page context based on the route url.
 * Note: The service is currently implemented to handle URLs under the
 * 'My Data' tab, i.e., Runs, Projects, Analysis etc.
 *
 * The service assumes following url structures:
 * /runs/active – runs master list
 * /runs/planned – planned runs list
 * /runs/:run-id/ - redirects to /runs/:run-id/details so need not handle this specifically.
 * /runs/:run-id/* – run details page
 *
 * The same structure is assumed for all other resource types as well, like projects, analysis, etc.
 */
@Injectable({
  providedIn: 'root'
})
export class PageContextService extends BaseService {

  private currentRoute: string;

  private context: IPageContext;

  private queryParams: { [key: string]: any };

  private isValidResourceUrl: boolean;
  private isValidSubResourceUrl: boolean = false;

  private readonly PLANNED_RESOURCE_URL_IDENTIFIER = 'planned';
  private readonly ACTIVE_RESOURCE_URL_IDENTIFIER = 'active';
  private readonly RUNS_ID_ROUTE_PARAM = 'run-id';
  private readonly BIOSAMPLES_ID_ROUTE_PARAM = 'biosample-id';
  private readonly PROJECTS_ID_ROUTE_PARAM = 'project-id';
  private readonly ANALYSIS_ID_ROUTE_PARAM = 'analysis-id';
  private readonly SAMPLE_ID_ROUTE_PARAM = 'sample-id';
  private readonly POOL_ID_ROUTE_PARAM = 'pool-id';

  constructor(private router: Router, private activatedRoute: ActivatedRoute) {
    super();
    this.init();
  }

  init() {
    // initialize with the current route. This is required to handle the cases
    // where navigation has already happened before service initialization.
    this.updatePageContext(this.router.url);

    // subscribe to NavigationEnd events
    this.subs.sink = this.router.events
      // router fires a lot of events, so we will just look for the
      // event of our interest
      .pipe(filter(event => event instanceof NavigationEnd))
      .subscribe({
        next: (event) => {
          // TODO: we can explore other properties than 'url'
          // if we need additional information that can be helpful
          // to determine page context
          const url: string = (event as NavigationEnd).urlAfterRedirects;
          this.updatePageContext(url);
        }
      });
  }


  public getPageNavigationChanges(): Observable<any> {
    const pageNavigationChanges = this.router.events.pipe(filter(event => event instanceof NavigationEnd));

    // Need to add initial event in case router/page event occurs before page loads
    return merge(of(null), pageNavigationChanges);
  }

  /**
   * checks if the given value is a valid resource type for page context. If yes, returns the
   * corresponding resource type, else throws an Exception.
   */
  private getPageResourceType(urlParts: UrlSegment[]): ResourceType {
    const path: string = urlParts[0].path;

    for (const type in ResourceType) {
      if (ResourceType[type] === path) {
        // TODO: check the efficiency of below syntax
        if (ResourceType[type] === ResourceType.RUNS && urlParts.length > 1 &&
          urlParts[1].path === this.PLANNED_RESOURCE_URL_IDENTIFIER) {
          return ResourceType.PLANNED_RUN;
        }

        return Object(ResourceType)[type];
      }
    }

    // TODO: handle any other url types apart from ResourceTypes,
    // e.g. /dashboard, /datacentral etc.
    this.isValidResourceUrl = false;
  }

  /**
   * method to get sub resource type of a page
   */
  private getPageSubResourceType(urlParts: UrlSegment[], resourceType: ResourceType): ResourceType {
    // assuming sub resource urls of type --> biosamples/1234/libraries
    if (urlParts.length < 3) {
      return null;
    }
    // page does not have sub resource
    if (urlParts[2].path === 'details') {
      return null;
    }

    let path: string = urlParts[2].path;
    // sub resource urls of type --> run/1234/samples or run/1234/runname/samples
    // Hack for run/<run-id>/<run-name>/<tab-name>
    if (resourceType === ResourceType.RUN) {
      const routeParams = this.getAllRouteParams();
      const runId = routeParams['run-id'];
      const experimentName = routeParams['exp-name'];

      if (runId && experimentName) {
        path = urlParts[3].path;
      }
    }
   
    for (const type in ResourceType) {
      if (ResourceType[type] === path) {
        this.isValidSubResourceUrl = true;
        return Object(ResourceType)[type];
      }
    }

    // Hack to identify /projects/<project-id>/about as analyses subresource because project metadata
    // is on same tab as analyses
    if (urlParts[0].path === ResourceType.PROJECTS && urlParts[2].path === 'about') {
      this.isValidSubResourceUrl = true;
      return ResourceType.ANALYSIS;
    }

    // Hack to identify /projects/<project-id>/otherdatasets as datasets subresource for projects has the 'other' keyword
    if (urlParts[0].path === ResourceType.PROJECTS && urlParts[2].path === 'otherdatasets') {
      this.isValidSubResourceUrl = true;
      return ResourceType.OTHER_DATASETS;
    }

     // Hack to identify /pools/<pool-id>/libraries as pool sub-resource 
     // because pools metadata and libraries are on same tab
    if(urlParts[0].path === ResourceType.POOLS &&  urlParts[2].path === 'about') {
      this.isValidSubResourceUrl = true;
      return ResourceType.LIBRARIES; // set sub resource type to libraries
    }


    this.isValidSubResourceUrl = false;
    return null;
  }

  /**
   * checks if the url represents a master list page.
   * Assumption: the master list page route will have just 1 segment, representing the resource type
   * and if there are more than 1 segment in the route, it will either be details page or planned runs list.
   */
  private isResourceMasterList(urlParts: UrlSegment[]): boolean {
    // if we have reached here, then we can safely assume that first part of the url identifies the
    // resource type like /runs, /projects, etc.

    // We are on the master list page if there is a single urlSegment described as above.
    // We are also on the master list page if there is a second segment which is the active/planned
    // list identifier

    return !!((urlParts && urlParts.length === 1) ||
      (urlParts.length > 1 && (urlParts[1].path === this.ACTIVE_RESOURCE_URL_IDENTIFIER ||
        urlParts[1].path === this.PLANNED_RESOURCE_URL_IDENTIFIER)));
  }

  /**
   * checks if the url represents a details page of a resource
   * @param urlParts list of url segments for the url to be checked
   */
  private isResourceDetailsPage(urlParts: UrlSegment[], resourceType: ResourceType): boolean {
    // The url segments could be >=2 for routes like /runs/active etc
    // Check if it is not a master list route.
    if ((!urlParts || urlParts.length < 2) || this.isResourceMasterList(urlParts)) {
      // details page url should be min 2 parts, having the resource type and the resource ID.
      // E.g.: /run/:run-id/:exp-name/details
      return false;
    }


    const routeParams = this.getAllRouteParams();
    switch (resourceType) {
      // handle checks for individual resource types here.
      case ResourceType.RUN:
        return (routeParams[this.RUNS_ID_ROUTE_PARAM] !== undefined);
      case ResourceType.PLANNED_RUN:
        return false; // TODO: update this if the details page exists for planned runs.
      case ResourceType.PROJECTS:
        return (routeParams[this.PROJECTS_ID_ROUTE_PARAM] !== undefined);
      case ResourceType.BIO_SAMPLES:
        return (routeParams[this.BIOSAMPLES_ID_ROUTE_PARAM] !== undefined);
      case ResourceType.ANALYSIS:
        return (routeParams[this.ANALYSIS_ID_ROUTE_PARAM] !== undefined);
      case ResourceType.SAMPLE:
          return (routeParams[this.SAMPLE_ID_ROUTE_PARAM] !== undefined);
      case ResourceType.POOLS:
            return (routeParams[this.POOL_ID_ROUTE_PARAM] !== undefined);
      default:
        throw new Error(ErrorMessages.UNSUPPORTED_RESOURCE_TYPE + resourceType);
    }
  }

  /**
   * parses the given url to find the page context
   * @param url current router url
   */
  private getContextFromRouteUrl(url: string): IPageContext {
    const tree: UrlTree = this.router.parseUrl(url);
    const segmentGroup: UrlSegmentGroup = tree.root.children[PRIMARY_OUTLET];
    if (!segmentGroup) {
      // this is possible if the user is at base url itself
      return { resourceType: null, isMasterList: false, isDetailsPage: false };
    }

    const segments: UrlSegment[] = segmentGroup.segments;

    // the first segment of the url will denote the resource type for main context
    const resourceType: ResourceType = this.getPageResourceType(segments);

    if (isNullOrUndefined(resourceType)) {
      return { resourceType: null, isMasterList: false, isDetailsPage: false };
    }

    // the remaining fragments, if any, will decide whether the user is at the details page
    const isDetailsPage: boolean = this.isResourceDetailsPage(segments, resourceType);

    // the remaining fragments, if any, will decide whether the user is at master list page
    const isMasterList: boolean = isDetailsPage ? false : this.isResourceMasterList(segments);

    // asuming sub resource is displayed on details page only
    let subResourceType: ResourceType = null;
    if (isDetailsPage) {
      subResourceType = this.getPageSubResourceType(segments, resourceType);
    }

    this.queryParams = tree.queryParams;

    return {
      resourceType,
      isMasterList,
      isDetailsPage,
      subResourceType
    };
  }

  /**
   * updates the page context in accordance with the given url
   * @param url current router url
   */
  private updatePageContext(url: string) {

    // do nothing if the route hasn't changed
    if (url === this.currentRoute) { return; }

    // get the context from url
    const context: IPageContext = this.getContextFromRouteUrl(url);

    // set the new context
    this.setPageContext(context, url);

  }

  /**
   * sets the given context as the current page context
   * @param context the context to be set as the current page context
   */
  private setPageContext(context: IPageContext, url: string) {
    // set class fields according to the current pageContext
    this.context = context;
    this.currentRoute = url;
    this.isValidResourceUrl = true;
  }

  /**
   * this function serves as a sanity check before giving out any information. If the service
   * has encountered a bad URL, this will prevent the service from giving out the information
   * based on the last known good url.
   *
   * Another approach would have been to not compute and store the page context on url change.
   * But rather on demand, check the url and provide required information.
   */
  private checkContextState(): boolean {
    if (this.isValidResourceUrl) { return true; }
    throw new Error(ErrorMessages.PAGE_CONTEXT_INCONSISTENT_STATE);
  }

  private checkContextSubResourceState(): boolean {
    if (this.isValidSubResourceUrl) { return true; }
    return false;
  }

  /**
   * returns a map of all route params from the current url.
   * Note: this contains only the route params, and not the query params.
   */
  getAllRouteParams(): { [key: string]: string } {
    let route: ActivatedRoute = this.activatedRoute;
    const params: { [key: string]: string } = {};

    while (!isNullOrUndefined(route) && !isNullOrUndefined(route.snapshot) &&
      !isNullOrUndefined(route.snapshot.paramMap)) {
      route.snapshot.paramMap.keys.forEach(key => {
        params[key] = route.snapshot.paramMap.get(key);
      });

      route = route.firstChild;
    }

    return params;
  }

  /**
   * check the context and return true if we are on run details page, false otherwise
   */
  isRunDetails(): boolean {
    this.checkContextState();
    return (this.context.resourceType === ResourceType.RUN &&
      this.context.isDetailsPage === true);
  }

  /**
   * check the context and return true if we are on run master list page, false otherwise
   */
  isRunMasterList(): boolean {
    this.checkContextState();
    return (this.context.resourceType === ResourceType.RUNS &&
      this.context.isMasterList === true);
  }

  /**
   * check the context and return true if we are on biosample details page, false otherwise
   */
  isBioSampleDetails(): boolean {
    this.checkContextState();
    return (this.context.resourceType === ResourceType.BIO_SAMPLES &&
      this.context.isDetailsPage === true);
  }

  /**
   * check the context and return true if we are on sample details page, false otherwise
   */
  isSampleDetails():boolean {
    this.checkContextState();
    return (this.context.resourceType === ResourceType.SAMPLE &&
      this.context.isDetailsPage === true);
  }

  /**
   * check the context and return true if we are on sample sub resource list page
   */
  isSubResourceSamplesList(): boolean {
    this.checkContextState();
    if (!this.checkContextSubResourceState()) {
      return false;
    }
    return this.context.subResourceType === ResourceType.SAMPLES;
  }

  /**
   * check the context and return true if we are on sample details page, false otherwise
   */
  isPoolDetails():boolean {
    this.checkContextState();
    return (this.context.resourceType === ResourceType.POOLS &&
      this.context.isDetailsPage === true);
  }

  /**
   * check the context and return true if we are on run master list page, false otherwise
   */
  isBioSampleMasterList(): boolean {
    this.checkContextState();
    return (this.context.resourceType === ResourceType.BIO_SAMPLES &&
      this.context.isMasterList === true);
  }

  /**
   * check the context and return true if we are on project details page, false otherwise
   */
  isProjectDetails(): boolean {
    this.checkContextState();
    return (this.context.resourceType === ResourceType.PROJECTS &&
      this.context.isDetailsPage === true);
  }

  /**
   * check the context and return true if we are on project master list page, false otherwise
   */
  isProjectMasterList(): boolean {
    this.checkContextState();
    return (this.context.resourceType === ResourceType.PROJECTS &&
      this.context.isMasterList === true);
  }

  /**
   * check the context and return true if we are on planned run master list page, false otherwise
   */
  isPlannedRunMasterList(): boolean {
    this.checkContextState();
    return (this.context.resourceType === ResourceType.PLANNED_RUN &&
      this.context.isMasterList === true);
  }

  /**
   * check the context and return true if we are on biosample sub resource list page
   */
  isSubResourceBioSamplesList(): boolean {
    this.checkContextState();
    if (!this.checkContextSubResourceState()) {
      return false;
    }
    return this.context.subResourceType === ResourceType.BIO_SAMPLES;
  }

  /**
   * check the context and return true if we are on libraries sub resource list page
   */
  isSubResourceDatasetsList(): boolean {
    this.checkContextState();
    if (!this.checkContextSubResourceState()) {
      return false;
    }
    return this.context.subResourceType && (this.context.subResourceType === ResourceType.FASTQ_DATASETS ||
      this.context.subResourceType === ResourceType.OTHER_DATASETS ||
      // Using endsWith here, as in Projects, the other datasets tab route is named named 'otherdatasets', while in
      // biosamples it is 'datasets'.
      this.context.subResourceType.endsWith(ResourceType.OTHER_DATASETS));
  }

  /**
   * check the context and return true if we are on fatsqs sub resource list page
   */
  isSubResourceFastqsList(): boolean {
    this.checkContextState();
    if (!this.checkContextSubResourceState()) {
      return false;
    }
    return this.context.subResourceType === ResourceType.FASTQ_DATASETS;
  }

  /**
   * check the context and return true if we are on fatsqs sub resource list page
   */
  isSubResourceFatsqsList(): boolean {
    this.checkContextState();
    if (!this.checkContextSubResourceState()) {
      return false;
    }
    return this.context.subResourceType === ResourceType.FASTQ_DATASETS;
  }

  /**
   * check the context and return true if we are on analysis details page, false otherwise
   */
  isAnalysisDetails(): boolean {
    this.checkContextState();
    return (this.context.resourceType === ResourceType.ANALYSIS &&
      this.context.isDetailsPage === true);
  }

  /**
   * check the context and return true if we are on analyses master list page, false otherwise
   */
  isAnalysisMasterList(): boolean {
    this.checkContextState();
    return (this.context.resourceType === ResourceType.ANALYSIS &&
      this.context.isMasterList === true);
  }

  isSubResourceAnalysisList(): boolean {
    this.checkContextState();
    if (!this.checkContextSubResourceState()) {
      return false;
    }
    return this.context.subResourceType === ResourceType.ANALYSIS;
  }

  // TODO: add functions similar to above functions to get state for other resource types as and when needed.

  /**
   * returns the map of query params associated with the current url
   */
  getMasterListQueryParams(): { [key: string]: any } {
    this.checkContextState();
    return this.queryParams;
  }

  getCurrentContextResourceType(): ResourceType {
    return this.context.resourceType;
  }

  getCurrentContextSubResourceType(): ResourceType {
    this.checkContextState();
    if (!this.checkContextSubResourceState()) {
      return null;
    }
    return this.context.subResourceType;
  }
}

export interface IPageContext {
  resourceType: ResourceType;
  isMasterList: boolean;
  isDetailsPage: boolean;
  subResourceType?: ResourceType; // sub resource type if any
  // NOTE: assuming sub resource will always be visible as list page only inside details page of main resource
}