export class ApplicationCategory {
    static VISUALIZATION = "Visualization";
    static ANALYTIC = "Analytic";
    static INSTANCE = "Instance";
    static QUALITY = "Quality";
    static NATIVE = "Native";
    static WES = "WorkflowExecutionService";
    static WORKFLOW = "Workflow";
    static UPLOADER = "Uploader";
    static DOWNLOAD = "Download";
    static OTHER = "Other";
    static ICA_ANALYSIS_TRACKING = "IcaAnalysisTracking";
    static ICA_WORKFLOW_SESSION_TRACKING = "IcaWorkflowSessionTracking";
    static ICA_PIPELINE = "IcaPipeline";
}