import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'encodeHtml'
})
export class EncodeHtmlPipe implements PipeTransform {

  transform(value: string): any {
    const div = document.createElement('div');
    div.innerText = value;

    return div.innerHTML;
  }

}
