import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { BaseModalComponent } from '@app/shared/modals/base-modal/base-modal.component';
import { BehaviorSubject } from 'rxjs';
import { IAnalysisStepLogsInput } from '@app/shared/modals/model/action-modal';
import { BsApiService } from '@app/core/services/bs-api/bs-api-service';
import { NgxSmartModalComponent, NgxSmartModalService, ProgressComponent } from '@bssh/comp-lib';
import { IAppSessionIcaStep, IcaStepType } from '@models/appSessions/appSession';
import { HttpClient } from '@angular/common/http';
import { finalize, map, switchMap } from 'rxjs/operators';
import { IsoDateTime } from '@app/core/utilities/pipes/iso-date-time.pipe';
import { isNullOrUndefined } from 'util';
import { get } from 'lodash';

@Component({
  selector: 'app-analysis-step-logs',
  templateUrl: './analysis-step-logs.component.html',
  styleUrls: ['./analysis-step-logs.component.scss']
})
export class AnalysisStepLogsComponent extends BaseModalComponent implements OnInit {

  @Input() data = new BehaviorSubject<IAnalysisStepLogsInput>(undefined);

  @ViewChild('logStepsProgressBar', { static: true })
  logStepsProgressBar: ProgressComponent;

  @ViewChild('logPreviewProgressBar', { static: true })
  logPreviewProgressBar: ProgressComponent;

  modalType = 'analysisStepLogs';
  title = 'ICA Analysis Failed Steps';
  confirmButtonText = 'Close';

  isLoadingSteps = true;
  icaSteps: IAppSessionIcaStep[];
  stepsError: string;

  isLoadingLogContent = false;
  previewTitle: string;
  previewSubtitle: string;
  previewContent: string;
  previewError: string;

  constructor(
    public ngxSmartModalService: NgxSmartModalService,
    private bsApiService: BsApiService,
    private http: HttpClient,
    private isoDateTimePipe: IsoDateTime,
  ) {
    super(ngxSmartModalService);
  }

  ngOnInit() {
    this.startLoadingSteps();
    this.subs.sink = this.data.pipe(
      switchMap(data => this.bsApiService.getV2AppSessionFailedIcaSteps(data.appSessionId)),
      map(response => response && (response.Items || []).filter(step => step.StdErrHref || step.StdOutHref)),
      // map(items => sortBy(items, i => i.EndTime)),  // Sorted on the backend already
      map(items => this.formatDateTimeStrings(items)),
      finalize(() => this.completeLoadingSteps()),
    ).subscribe({
      next: steps => {
        this.isLoadingSteps = false;
        this.icaSteps = steps;
      },
      error: err => {
        this.stepsError = 'There was an issue retrieving ICA analysis and workflow session steps.';
        console.error(err);
      },
    });
  }

  accept(currentModal: NgxSmartModalComponent) {
    super.accept(currentModal);
    currentModal.close();
  }

  onLogFileClicked(step: IAppSessionIcaStep, logType: 'StdOut'|'StdErr') {
    this.startLoadingPreview();
    this.resetLogPreview();
    this.previewTitle = this.getPreviewTitle(step, logType);
    this.previewSubtitle = this.generateStepDetails(step);
    const url = this.getPreviewUrl(step, logType);
    this.subs.sink = this.http.get(url, { withCredentials: true, responseType: 'text' }).pipe(
      finalize(() => this.completeLoadingPreview()),
    ).subscribe({
      next: response => {
        if (response) {
          this.previewContent = response;
        } else {
          this.previewError = 'Log file is empty.';
        }
      },
      error: err => {
        this.previewError = 'Failed to load log preview.';
        console.error(err);
      },
    });
  }

  generateStepDetails(step: IAppSessionIcaStep): string {
    switch (step.ParentType) {
      case IcaStepType.ANALYSIS:
        if (get(step, 'Analysis.Pipeline.Code')) {
          return `Analysis Pipeline Code: ${step.Analysis.Pipeline.Code}`;
        } else if (get(step, 'Analysis.Pipeline.Description')) {
          return `Analysis Pipeline Description: ${ step.Analysis.Pipeline.Description }`;
        } else if (get(step, 'Analysis.UserReference')) {
          return `Analysis User Reference ${step.Analysis.UserReference}`;
        } else {
          return '';
        }

      case IcaStepType.WORKFLOW_SESSION:
        if (get(step, 'WorkflowSession.Workflow.Code')) {
          return `Workflow Code: ${step.WorkflowSession.Workflow.Code}`;
        } else if (get(step, 'WorkflowSession.Workflow.Description')) {
          return `Workflow Description: ${ step.WorkflowSession.Workflow.Description }`;
        } else if (get(step, 'WorkflowSession.UserReference')) {
          return `WorkflowSession User Reference ${step.WorkflowSession.UserReference}`;
        } else {
          return '';
        }

      default:
        return '';
    }
  }

  private formatDateTimeStrings(items: IAppSessionIcaStep[]) {
    for (const item of items) {
      item.StartTime = !isNullOrUndefined(item.StartTime) ? this.isoDateTimePipe.transform(item.StartTime, 'LLL') : '--';
      item.EndTime = !isNullOrUndefined(item.EndTime) ? this.isoDateTimePipe.transform(item.EndTime, 'LLL') : '--';
    }
    return items;
  }

  private getPreviewTitle(step: IAppSessionIcaStep, logType: 'StdOut'|'StdErr') {
    switch (logType) {
      case 'StdOut':
        return `Preview: StdOut - ${step.Name}`;

      case 'StdErr':
        return `Preview: StdErr - ${step.Name}`;

      default:
        console.error(`logType ${logType} is not supported.`);
        return;
    }
  }

  private getPreviewUrl(step: IAppSessionIcaStep, logType: 'StdOut'|'StdErr') {
    switch (logType) {
      case 'StdOut':
        return step.StdOutHref;

      case 'StdErr':
        return step.StdErrHref;

      default:
        return;
    }
  }

  private resetLogPreview() {
    this.previewTitle = null;
    this.previewSubtitle = null;
    this.previewError = null;
    this.previewContent = null;
  }

  private startLoadingPreview() {
    this.isLoadingLogContent = true;
    this.logPreviewProgressBar.start();
  }

  private completeLoadingPreview() {
    this.isLoadingLogContent = false;
    this.logPreviewProgressBar.complete();
  }

  private startLoadingSteps() {
    this.isLoadingSteps = true;
    this.logStepsProgressBar.start();
  }

  private completeLoadingSteps() {
    this.isLoadingSteps = false;
    this.logStepsProgressBar.complete();
  }

}
