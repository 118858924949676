<ngx-smart-modal #InitiateRequeueLibraryModal 
    [identifier]="modalType" 
    [title]="ngxSmartModalTitle" 
    closable="true"
    showCloseButton="true" 
    [closeButtonText]="closeButtonText" 
    [disableConfirmButton]="disableConfirmButton"
    [confirmButtonText]="confirmButtonText">
    <app-lab-requeue-library-pool 
        [informationListToDisplay]="infoList" 
        [libraryCount]="libraryCount"
        [errorMsg]="errorMsg" 
        (confirm)="dataChanged($event)"
    ></app-lab-requeue-library-pool>

</ngx-smart-modal>