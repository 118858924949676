<ng-container *ngIf="modalInput$ | async as modalInput">
  <ngx-smart-modal
    #dynamicAlertModal
    class="dynamic-alert-modal"
    identifier="dynamicAlertModal"
    [closable]="modalInput.closable"
    [title]="modalInput.title"
    [showCloseButton]="!!modalInput.closeButtonText"
    [showConfirmButton]="!!modalInput.confirmButtonText"
    [closeButtonText]="modalInput.closeButtonText"
    [confirmButtonText]="modalInput.confirmButtonText">
    <!-- Dynamic structured content -->
    <div id="modal-content">
      <ng-container *ngIf="isContentAnArray(modalInput.content); else htmlStringContent">
        <div *ngFor="let line of modalInput.content" class="my-3 modal-content-list">
          <p *ngIf="!isLineAListItem(line); else listItem">{{ line }}</p>
          <ng-template #listItem>
            <li>{{ trimListItemPrefix(line) }}</li>
          </ng-template>
        </div>
      </ng-container>
      <!-- HTML string content -->
      <ng-template #htmlStringContent>
        <div [innerHTML]="modalInput.content"></div>
      </ng-template>
    </div>
  </ngx-smart-modal>
</ng-container>
