import { IApplicationCompact } from "./application";
import { IUser } from "../user";
import IResource from '../resource';
import { IComputeStatistics } from "../compute-statistics";

export interface IAppSession extends IResource {
    Application?: IApplicationCompact;
    Children?: IAppSession[];
    UserCreatedBy?: IUser;
    Status?: string;
    ExecutionStatus?: string;
    QcStatus?: string;
    StatusSummary?: string;
    Purpose?: string;
    DateStarted?: string;
    DateCompleted?: string;
    DeliveryStatus?: string;
    ContainsComments?: boolean;
    AppSessionParent?: IAppSession;
    AppSessionRoot?: IAppSession;
    HrefComments?: string;
    HrefLogs?: string;
    HrefIcaAnalysis?: string;
    ComputeStatistics?: IComputeStatistics;
    RunningDuration?: number;
}

/**
 * AppSession ICA Step = step of ICA analysis | step for ICA workflow session
 */
export interface IAppSessionIcaStep {
  ParentType: IcaStepType;
  Analysis: IAppSessionIcaStepAnalysis;
  WorkflowSession: IAppSessionIcaStepWorkflowSession;
  Id: string;
  Name: string;
  IsTechnical: boolean;
  Status: string;
  StdErrDataId: string;
  StdErrHref: string;
  StdOutDataId: string;
  StdOutHref: string;
  StartTime: string;
  EndTime: string;
}

export enum IcaStepType {
  ANALYSIS = 'ANALYSIS',
  WORKFLOW_SESSION = 'WORKFLOW_SESSION',
}

export interface IAppSessionIcaStepAnalysis {
  Id: string;
  Reference: string;
  UserReference: string;
  Pipeline: IAppSessionIcaStepAnalysisPipeline;
}

export interface IAppSessionIcaStepAnalysisPipeline {
  Id: string;
  Urn: string;
  Code: string;
  Description: string;
}

export interface IAppSessionIcaStepWorkflowSession {
  Id: string;
  UserReference: string;
  Workflow: IAppSessionIcaStepWorkflowSessionWorkflow;
}

export interface IAppSessionIcaStepWorkflowSessionWorkflow {
  Id: string;
  Urn: string;
  Code: string;
  Description: string;
}

// API execution statuses. If you want display friendly names then refer to:
// enum AnalysisStatus
export enum AppSessionExecutionStatus {
    
        Undefined, 
        Aborted, 
        Running,
        NeedsAttention,
        Complete,
        TimedOut, 
        AwaitingAuthorization, 
        PendingPayment, 
        PaymentComplete, 
        PaymentAborted,
        Aborting,
        PendingExecution,
        Initializing,
        Pending,
        Canceled,
    
}

export type SortBy = "Name" | "Id" | "AppId" | "ExecutionStatus" | "ModifiedOn" | "DateCreated" | "AppName" | "TotalSize" | "QcStatus" | "DeliveryStatus"; 
export type SortDir = "Desc" | "Asc";
