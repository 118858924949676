import { SearchResourceDictionaryService } from '@app/core/services/resource-dictionary/search-resource-dictionary.service';
import { OverrideColumnDefinition, OverrideColumnParameters, SearchGridOptionsFormatter } from './search-grid-options-formatter';
import { BytesFilter } from '@app/core/utilities/filters/bytes.filter';
import { SearchResourceType } from '@app/core/model/search/search-resource-type';
import { ISearchResourceConfig } from '@app/core/services/resource-dictionary/search-resource-dictionary-types';
import { DataTableUtilities } from '@app/core/utilities/data-table-utilities';
import { BsshDatePipe } from '@app/core/utilities/pipes/bssh-date.pipe';
import { FilePreviewCellRendererComponent } from '@app/shared/cell-renderers/file-preview-cell-renderer/file-preview-cell-renderer.component';

export enum FileSearchColumnFields {
    Name = 'Name',
    Analysis = 'Analysis',
    Dataset = 'Dataset',
    Project = 'Project',
    Size = 'Size',
    Created = 'Created'
}


export interface FileOverrideColumnDefinition extends OverrideColumnDefinition<FileSearchColumnFields> {}

export class FileSearchGridOptionsFormatter extends SearchGridOptionsFormatter<FileSearchColumnFields> {
    private fileResourceConfig: ISearchResourceConfig;

    constructor(
        public resourceDictionaryService: SearchResourceDictionaryService,
        public bsshDatePipe: BsshDatePipe
    ) {
        super();
        this.fileResourceConfig = this.resourceDictionaryService.getResourceConfigByType(SearchResourceType.File);
    }

    protected getOverrideColumnParameters(): OverrideColumnParameters<FileSearchColumnFields> {
        const inclusiveColumns: FileSearchColumnFields[] = [
            FileSearchColumnFields.Name,
            FileSearchColumnFields.Analysis,
            FileSearchColumnFields.Size,
            FileSearchColumnFields.Created
        ];

        const overrideColumnDefinitions: OverrideColumnDefinition<FileSearchColumnFields>[] = [
            {
              colId: FileSearchColumnFields.Name,
              checkboxSelection: false
            }
        ];

        return {
            inclusiveColumns,
            overrideColumnDefinitions
        };
    }

    protected getDefaultColumnDefinitions() {
        const fileConfig = this.fileResourceConfig;

        const defaultColumnDefinitions = [
            {
                headerName: 'Name',
                field: 'DatasetFile.Name',
                cellRendererFramework: FilePreviewCellRendererComponent,
                colId: FileSearchColumnFields.Name,
                sortable: false,
                flex: 4,
                minWidth: 300
            },
            {
                headerName: 'Analysis',
                field: 'DatasetFile.ParentDataset.AppSession',
                cellRendererSelector: (params) => {
                    if(params.data) {
                        const appSession = params.data.DatasetFile.ParentDataset.AppSession;
                        return DataTableUtilities.getLinkRenderer(
                            appSession.Name,
                            `/analyses/${appSession.Id}`
                        );
                    } else {
                        return DataTableUtilities.getSpanRenderer();
                    }
                },
                colId: FileSearchColumnFields.Analysis,
                sortable: false,
                flex: 3,
                minWidth: 100
            },
            {
                headerName: 'Dataset',
                field: 'DatasetFile.ParentDataset.Name',
                cellRenderer: 'span',
                colId: FileSearchColumnFields.Dataset,
                sortable: false,
                flex: 3,
                minWidth: 100
            },
            {
                headerName: 'Project',
                field: 'DatasetFile.ParentProject.Name',
                cellRenderer: 'span',
                colId: FileSearchColumnFields.Project,
                sortable: false,
                flex: 3,
                minWidth: 100
            },
            {
                headerName: 'Size',
                valueGetter: (params) => {
                    if (!params.data) { return; }
                    return BytesFilter(params.data.DatasetFile.Size);
                  },
                colId: FileSearchColumnFields.Size,
                cellRenderer: 'span',
                sortable: false,
                flex: 2,
                minWidth: 100
            },
            {
                headerName: 'Created',
                valueGetter: (params) => {
                    if (!params.data) { return; }
                    return this.bsshDatePipe.transform(params.data.DatasetFile.DateCreated);
                  },
                filterActive: false,
                sortable: false,
                colId: FileSearchColumnFields.Created,
                cellRenderer: 'span',
                flex: 1.5,
                minWidth: 100
              },
        ];
        return defaultColumnDefinitions;
    }
}
