import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { FormFieldsComponent } from '@bssh/comp-lib';
import { Constants } from '@app/core/utilities/constants';
import { BasePairUnit, BasePairUtils } from '@app/core/utilities/base-pair.utilities';
import { isNullOrUndefined } from 'util';
import { distinctUntilChanged } from 'rxjs/operators';

@Component({
  selector: 'app-lab-requeue-library-pool',
  templateUrl: './lab-requeue-library-pool.component.html',
  styleUrls: ['./lab-requeue-library-pool.component.scss']
})
export class LabRequeueLibraryPoolComponent implements OnInit {
  private MAX_RAW_BP = Constants.Yields.MaxYieldRawBp;
  
  @Input() public informationListToDisplay = [];
  @Input() public errorMsg: string | string[] = null;
  @Input() public libraryCount: number = 0;

  public formGroup: FormGroup;
  @Output() confirm: EventEmitter<ILibraryPoolRequeueComponentOutput> = new EventEmitter();

  @ViewChild('form', { static: false })
  public form: FormFieldsComponent;

  alertMsg: string = null;
  isValidateOnInputChange = true;

  yieldInputMax: number = this.MAX_RAW_BP;
  yieldInputMin: number = 1;
  requestedAdditionalYield: number | null = null;
  currentBasePairUnit: string = null;
  selectedBasePairUnit: BasePairUnit = null;
  yieldPerLibrary: number;
  basePairChoices: BasePairUnit[];

  public basePairUnitControl: FormControl = new FormControl('');


  constructor(private formBuilder: FormBuilder) {
    this.formGroup = this.formBuilder.group({
      requestedYield: [0, [
      Validators.min(this.yieldInputMin),
      Validators.max(this.yieldInputMax),
      Validators.required]]
    });
   }

  ngOnInit() {
    if (isNullOrUndefined(this.errorMsg)) {
      this.basePairChoices = [
        BasePairUtils.GIGABASE,
        BasePairUtils.MEGABASE,
        BasePairUtils.KILOBASE,
        BasePairUtils.BASE
      ];

      this.selectedBasePairUnit = this.basePairChoices[0];
      this.basePairUnitControl.setValue(this.selectedBasePairUnit.abbreviation, { emitEvent: false });

      this.basePairUnitControl.valueChanges.pipe(
        distinctUntilChanged()
      ).subscribe((selectedOption) => {
        this.selectedBasePairUnit = this.basePairChoices.find(
          (x) => x.abbreviation == selectedOption
        );
        this.updateValidity();
      });
    }
  }

  ngAfterViewInit() {
    this.triggerInputRequestedAdditionalYieldChange();
    this.sendConfirmationToParentComponent()
  }

  public getErrorMessage() {
    const requestedYield = this.formGroup.controls.requestedYield;

    if (requestedYield.errors && requestedYield.errors.max) {
      return "A yield cannot exceed 999.99 Gbp. Check your entry and try again.";
    }
    else if (requestedYield.errors && (requestedYield.errors.min || requestedYield.errors.required)) {
      return "A yield must be at least 1 bp. Check your entry and try again.";
    } 
    else if (!Number.isFinite(this.requestedAdditionalYield) 
      || (this.requestedAdditionalYield > this.yieldInputMax || this.requestedAdditionalYield < this.yieldInputMin)){
      return `Please provide a yield that is between ${this.yieldInputMin} and ${this.yieldInputMax}`;
    }
    
    return "";
  }

  public onYieldChange(e) {
    this.alertMsg = null;
    this.errorMsg = null;

    this.requestedAdditionalYield = e.target.value;
    if (this.libraryCount !== 0) {
      this.yieldPerLibrary = Math.round(this.requestedAdditionalYield / this.libraryCount);
    } else {
      this.yieldPerLibrary = 0; // or handle this case as needed
    }
    
    this.updateValidity();
  }

  private updateValidity(){

    this.yieldInputMax = this.MAX_RAW_BP / this.selectedBasePairUnit.divisor;
    this.yieldInputMin = 1 / this.selectedBasePairUnit.divisor;

    //call form validate 
    this.sendConfirmationToParentComponent();
  }

  public isYieldExplanationVisible(): boolean {

    if (this.formGroup && this.formGroup.valid && this.libraryCount > 1) {
      return true;
    }
    return false;
  }

  private sendConfirmationToParentComponent() {
    // to make validation call to form fields component
    setTimeout(() => {
      this.confirm.emit(this.getOutput());
    }, 500);
  }

  private triggerInputRequestedAdditionalYieldChange() {
    setTimeout(() => {
      if (this.form && this.form.InputComponent) {
        const event = new Event('change');

        Object.defineProperty(event, 'target',
          { writable: false, value: this.form.InputComponent.elementRef.nativeElement });

        this.form.InputComponent.elementRef.nativeElement.dispatchEvent(event);
      }
    }, 500);
  }

  private getOutput(): ILibraryPoolRequeueComponentOutput {
    const divisor = !isNullOrUndefined(this.selectedBasePairUnit) ? this.selectedBasePairUnit.divisor : 0;
    return ({
      RequestedAdditionalYield: Math.round(this.requestedAdditionalYield * divisor),
      errorMsg: this.errorMsg,
      isInputValid: !this.formGroup.invalid,
      yieldInputMax: this.yieldInputMax,
      yieldInputMin: this.yieldInputMin
    });
  }
}

export interface ILibraryPoolRequeueComponentOutput {
  RequestedAdditionalYield: number;
  errorMsg: string | string[];
  isInputValid: boolean;
  yieldInputMax: number;
  yieldInputMin: number;
}
