import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';
import { Router } from '@angular/router';
import _ from 'lodash';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import environment from '../../../environments/environment';
import { PageContextService } from '../services/page-context.service';
import { BsApiEndPoints, IBsshApiEndpoint } from '../services/bs-api/endpoints';
import { TrashItemMessageTypes } from '../store/trash-items/trash-items.store';
import { MessageService } from '../services/message/message.service';

@Injectable()
export class BsApiSuccessInterceptor implements HttpInterceptor {
    private apiResponse: any;

    // Maintain a list of api endpoints with acceptable response codes
    private endpointResponseCode: IBsshApiEndpoint[] = [
        { method: 'DELETE', path: BsApiEndPoints.TRASH_RUN_API_URL, responseCodesOK: _.range(200, 300, 1) },
        { method: 'DELETE', path: BsApiEndPoints.TRASH_PROJECT_API_URL, responseCodesOK: _.range(200, 300, 1) },
        { method: 'DELETE', path: BsApiEndPoints.ANALYSES_V1PRE3_URL, responseCodesOK: _.range(200, 300, 1) },
        { method: 'POST', path: BsApiEndPoints.TRASH_ITEMS_URL, responseCodesOK: _.range(200, 300, 1) },
        { method: 'POST', path: BsApiEndPoints.BULK_DELETE_URL, responseCodesOK: _.range(200, 300, 1) }
    ];

    constructor(
        private router: Router,
        private pageContextService: PageContextService,
        private messageService: MessageService
    ) {}

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(req).pipe(
            tap((event: HttpEvent<any>) => {
                if (_.startsWith(req.url, environment.apiUrl) && event instanceof HttpResponse) {
                    this.apiResponse = event;

                    switch (req.method) {
                        case 'POST':
                            // Bulk delete only used for samples
                            if(this.isRestoreTrashSuccess() || this.isBulkDeleteSuccess()) {
                                this.messageService.sendMessage({ type: TrashItemMessageTypes.UpdateTrashItemsCount });
                            }
                            break;
                        case 'DELETE':
                            // Update trash items count whenever resource(s) successfully trashed or trash emptied
                            if(_.range(200, 300, 1).some(item => item === this.apiResponse.status)) {
                                this.messageService.sendMessage({ type: TrashItemMessageTypes.UpdateTrashItemsCount });
                            }

                            // redirect user from detail run to master
                            if (this.isDeletedResourceDetail(this.pageContextService.isRunDetails(), BsApiEndPoints.TRASH_RUN_API_URL)) {
                                this.navigateToPageAfterDelete('runs');
                            } 
                            // redirect user from detail project to master
                            else if(this.isDeletedResourceDetail(this.pageContextService.isProjectDetails(), BsApiEndPoints.TRASH_PROJECT_API_URL)) {
                                this.navigateToPageAfterDelete('projects');
                            } 
                            // redirect user from detail analysis to master
                            else if(this.isDeletedResourceDetail(this.pageContextService.isAnalysisDetails(), BsApiEndPoints.ANALYSES_V1PRE3_URL)) {
                                this.navigateToPageAfterDelete('analyses');
                            }
                            break;
                    }
                }
            })
        );
    }

    private isBulkDeleteSuccess(): boolean {
        const apiEndPoint = this.endpointResponseCode;
        const restoreTrashEndpoint = apiEndPoint.find(x => x.path === BsApiEndPoints.BULK_DELETE_URL);
        if (restoreTrashEndpoint && `${this.apiResponse.url}`.startsWith(restoreTrashEndpoint.path)) {
            return (restoreTrashEndpoint.responseCodesOK.some(item => item === this.apiResponse.status));
        }
        return false;
    }

    private isRestoreTrashSuccess(): boolean {
        const apiEndPoint = this.endpointResponseCode;
        const restoreTrashEndpoint = apiEndPoint.find(x => x.path === BsApiEndPoints.TRASH_ITEMS_URL);
        if (restoreTrashEndpoint && `${this.apiResponse.url}`.startsWith(restoreTrashEndpoint.path)) {
            return (restoreTrashEndpoint.responseCodesOK.some(item => item === this.apiResponse.status));
        }
        return false;
    }

    private isDeletedResourceDetail(isDeletedResourceDetailPage: boolean, resourceTrashApiUrl: string): boolean {
        if (isDeletedResourceDetailPage) {
            const apiEndPoint = this.endpointResponseCode;
            const resourceTrashEndpoint = apiEndPoint.find(x => x.path === resourceTrashApiUrl);
            if (resourceTrashEndpoint) {
                if (`${this.apiResponse.url}`.startsWith(resourceTrashEndpoint.path)) {
                    return (resourceTrashEndpoint.responseCodesOK.some(item => item === this.apiResponse.status));
                }
            }
        }
        return false;
    }

    /**
    * navigates to another page following successful delete.
    */
    private navigateToPageAfterDelete(pageRoute: string) {
        this.router.navigate([pageRoute]);
    }
}
