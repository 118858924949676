import { Component, OnInit, ViewEncapsulation, ViewChild, AfterViewInit, ElementRef, OnDestroy } from '@angular/core';
import { NgxSmartModalService, ToastrService, NgxSmartModalComponent, SelectComponent } from '@bssh/comp-lib';
import { IndexAdapterKitsService, CreateIndexAdapterKitByDefinitionRequest, IndexAdapterKit, KitDefinitionFormat } from '@stratus/gss-ng-sdk';
import { BaseModalComponent } from '../../base-modal/base-modal.component';
import { ErrorResponse } from '@stratus/gds-ng-sdk';
import { ICreateIndexAdapterKitModalOutput } from '../../model/action-modal';
import { map } from 'lodash';
import { TEMPLATE_OPTIONS, iakTsvTemplate } from './create-index-adapter-kit-templates';
import { AceConfigInterface, AceComponent } from 'ngx-ace-wrapper';
import OverlayScrollbars from 'overlayscrollbars';
import ace from 'brace';
import 'brace/theme/chrome';
import 'brace/mode/yaml';
import 'brace/mode/text';

@Component({
  selector: 'app-index-adapter-kit-modal',
  templateUrl: './index-adapter-kit-modal.component.html',
  styleUrls: ['./index-adapter-kit-modal.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class IndexAdapterKitModalComponent extends BaseModalComponent implements OnInit, AfterViewInit, OnDestroy {
  modalType = 'indexAdapterKitModal';

  @ViewChild(AceComponent, { static: true })
  aceEditor: AceComponent;
  aceInstance: ace.Editor;

  @ViewChild('scrollbarContainer', { static: false })
  scrollbarContainer: ElementRef;
  private osInstance!: OverlayScrollbars;

  @ViewChild('templateSelect', { static: true })
  templateSelect: SelectComponent;

  // Modal Text
  title = 'Custom Index Adapter Kit';
  closeButtonText = 'Cancel';
  confirmButtonText = 'Create New Kit';

  // Template Selection
  templateOptions = TEMPLATE_OPTIONS;
  isDisableReloadTemplate = true;
  cachedTemplateContent: string;

  // Data
  content = '';
  definitionFormat: KitDefinitionFormat = 'Yaml';
  isSkipIndexDiversityValidation = false;

  config: AceConfigInterface = {
    mode: 'yaml',
    theme: 'chrome'
  };

  // Error message
  errorMessage: string;

  constructor(
    public ngxSmartModalService: NgxSmartModalService,
    private toastr: ToastrService,
    private indexAdapterKitsService: IndexAdapterKitsService
  ) {
    super(ngxSmartModalService);
  }

  ngOnInit() {
    super.ngOnInit();
  }

  ngAfterViewInit() {
    super.ngAfterViewInit();
    this.aceInstance = this.aceEditor.directiveRef.ace();
    this.aceInstance.setShowPrintMargin(false);
    setTimeout(() =>  this.aceInstance.resize(true));
  }

  onTemplateSelection(selectedOption: any) {
    this.isDisableReloadTemplate = false;
    this.definitionFormat = 'Yaml';
    this.config.mode = 'yaml';
    this.content = selectedOption.option.value;
    // Cache selected template content for potential reload
    this.cachedTemplateContent = selectedOption.option.value;
    this.aceInstance.resize(true);
  }

  onReloadTemplate(event) {
    // replace content with cached template content
    this.content = this.cachedTemplateContent;
  }

  toggleSkipIndexDiversityValidation(event) {
    this.isSkipIndexDiversityValidation = !this.isSkipIndexDiversityValidation;
  }

  onImportTsvDefinitionFile(event) {
    const tsvFile = (event.target as HTMLInputElement).files[0];
    this.isDisableReloadTemplate = true;
    this.templateSelect.input.nativeElement.value = 'Select';

    const fileReader = new FileReader();
    fileReader.onloadend = (ev) => this.processTsvDefinitionString(fileReader.result.toString());
    fileReader.readAsText(tsvFile);
  }

  onDownloadTsvDefinitionFile(_event) {
    const encodedUri = `data:text/tsv;charset=utf-8,${encodeURIComponent(iakTsvTemplate)}`;
    const link = document.createElement('a');
    link.setAttribute('href', encodedUri);
    link.setAttribute('download', 'Create_IndexAdapterKit_Import_Template-v1.tsv');
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }

  /**
   * Process the parsed tsv definition content by populating the editor and switching the definition format.
   * @param tsvDefinition parsed tsv definition content as raw string
   */
  processTsvDefinitionString(tsvDefinition: string) {
    this.config.mode = 'text';
    this.definitionFormat = 'Tsv';
    // Trim trailing extra tabs from excel formatted tsv files (excel treats definition in tabular form with blank cells)
    this.content = tsvDefinition.replace(/[\t]+$/gm, '');
  }

  /**
   * Process the error message returned from api and display it on the modal.
   * @param errorResponse GSS error response model object
   */
  private processErrorMessages(errorResponse: ErrorResponse) {
    this.errorMessage = errorResponse.message;
    // Format error response with details
    if (errorResponse.details) {
      const errorDetails = map(errorResponse.details, 'Message').join('\n');
      this.errorMessage += '\n' + errorDetails;
    }
    // transform scrollbar styles
    this.osInstance = OverlayScrollbars(this.scrollbarContainer.nativeElement, {
      overflowBehavior: {
        x: 'hidden',
        y: 'scroll'
      }
    });
    // show toast message
    this.toastr.error(`<strong>Failed to save</strong><br/>
      Your index adapter kit has not been saved due to: <br/>
      "${this.errorMessage}"`);
  }

  /**
   * Prompt successful toast message, emit the event and close the modal.
   * @param indexAdapterKitCreated created iak data returned from GSS
   * @param modal instance needed to close the modal
   */
  private processSuccessfulKitCreation(indexAdapterKitCreated: IndexAdapterKit, modal: NgxSmartModalComponent) {
    this.toastr.success(`Your "${indexAdapterKitCreated.displayName}" index adapter kit has been successfully saved.`);
    const modalOuptut: ICreateIndexAdapterKitModalOutput = { indexAdapterKitCreated };
    this.confirm.emit(modalOuptut);
    modal.close();
  }

  accept(modal: NgxSmartModalComponent) {
    const requestBody: CreateIndexAdapterKitByDefinitionRequest = {
      definitionFormat: this.definitionFormat,
      definition: this.content,
      skipIndexDiversityValidation: this.isSkipIndexDiversityValidation
    };
    this.subs.sink = this.indexAdapterKitsService.createIndexAdapterKitByDefinition(requestBody)
      .subscribe({
        next: iakCreated => this.processSuccessfulKitCreation(iakCreated, modal),
        error: response => this.processErrorMessages(response.error)
      });
  }

  ngOnDestroy() {
    // clean up overlayScrollbar instance
    if (this.osInstance && this.osInstance.destroy) {
      this.osInstance.destroy();
    }
    super.ngOnDestroy();
  }
}
