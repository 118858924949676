import { Component, OnInit } from '@angular/core';
import { AnalysisUtilityService } from '@app/analyses/services/analysis-utility.service';
import { StringUtilities } from '@app/core/utilities/string-utilities';
import { FilePreviewModalComponent } from '@app/shared/modals/file-preview-modal/file-preview-modal.component';
import { IFilePreviewModalInput } from '@app/shared/modals/model/action-modal';
import { IApiFile } from '@app/shared/modals/model/file-preview.interface';
import { ModalService } from '@app/shared/modals/services/modal.service';
import environment from '@environments/environment';
import { AgRendererComponent } from 'ag-grid-angular';

@Component({
  selector: 'app-file-preview-cell-renderer',
  templateUrl: './file-preview-cell-renderer.component.html',
  styleUrls: ['./file-preview-cell-renderer.component.scss']
})
export class FilePreviewCellRendererComponent implements AgRendererComponent {
  params = null;
  file: IApiFile;

  constructor(private analysisUtilityService: AnalysisUtilityService) { }

  ngOnInit() {}

  refresh(params: any): boolean {
    return true;
  }

  agInit(params: any): void {
    if (!params || !params.data || !params.data.DatasetFile) {
      return;
    }

    this.params = params;
    this.file = this.params.data.DatasetFile;
  }

  showFilePreview($event: Event): void {
    this.analysisUtilityService.showFilePreview(this.file);
    $event.stopPropagation();
  }

}
