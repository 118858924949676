export enum AppStatus {
    DEVELOPMENT = 'Development',
    INITIALIZING = 'Initializing',
    BETA = 'Beta',
    TESTING = 'Testing', // Submitted for Review
    PUBLISHED = 'Published',
    DOMAIN_PUBLISHED = 'DomainPublished',
    DEPRECATED = 'Deprecated',
    DISABLED = 'Disabled',
    DELETED = 'Deleted'
}