import { AfterViewInit, Component, OnDestroy, OnInit } from '@angular/core';
import { ModalTexts } from '../modal-texts';
import { ILibraryPoolRequeueModalInput, ILibraryPoolRequeueModalOutput } from '../model/action-modal';
import { NgxSmartModalService } from '@bssh/comp-lib';
import { BaseModalComponent } from '../base-modal/base-modal.component';
import { IRequeueLibraryPool } from '@app/core/model/pool/requeue-library-pool';
import { ResourceType } from '@app/core/model/resource-type';
import { DecimalPipe } from '@angular/common';
import { isNullOrUndefined } from 'util';
import { IRequeueParams } from '@app/core/bsshapi/api-wrappers';
import { ILibraryPoolRequeueComponentOutput } from '../lab-requeue-library-pool/lab-requeue-library-pool.component';

@Component({
  selector: 'app-requeue-library-pool-modal',
  templateUrl: './requeue-library-pool-modal.component.html',
  styleUrls: ['./requeue-library-pool-modal.component.scss']
})
export class RequeueLibraryPoolModalComponent extends BaseModalComponent implements OnInit, AfterViewInit, OnDestroy {

  modalType = 'InitiateRequeueLibraryPoolModal';
  ngxSmartModalTitle: string = ModalTexts.REQUEUE_LIBRARY_POOL.MODAL_TITLE;
  confirmButtonText: string = ModalTexts.REQUEUE_LIBRARY_POOL.CONFIRM_BUTTON_TEXT;
  closeButtonText: string = ModalTexts.REQUEUE_LIBRARY_POOL.CANCEL_BUTTON_TEXT;

  infoList = []; // information to display in lab-requeue component
  libraryCount: number;

  modalOutput: ILibraryPoolRequeueModalOutput = null;
  
  constructor(public ngxSmartModalService: NgxSmartModalService, 
    private decimalPipe: DecimalPipe) {
    super(ngxSmartModalService);
   }
  
  ngOnInit() {
    super.ngOnInit();
    this.disableConfirmButton = true;
    this.setUpInputSubscriber();
  }

  ngAfterViewInit() {
    super.ngAfterViewInit();
    this.setModalData(this.modalOutput);
  }

  ngOnDestroy() {
    super.ngOnDestroy();
  }

  private setUpInputSubscriber() {
    this.subs.sink = this.data.subscribe((response: ILibraryPoolRequeueModalInput) => {
      if (response) {
        this.updateInfoList(response.associatedParentResourceType, response.requeueLibraryPool);
        if (!response.errorMsg) {
          this.disableConfirmButton = false;
        } else {
          this.errorMsg = response.errorMsg;
        }
      }
    });
  }

  dataChanged($event: ILibraryPoolRequeueComponentOutput) {
    this.modal = this.ngxSmartModalService.getModal(this.modalType);
    const requeueParams: IRequeueParams = {
      requestedAdditionalYield: $event.RequestedAdditionalYield,
      yieldInputMax: $event.yieldInputMax,
      yieldInputMin: $event.yieldInputMin,
      isValid: $event.errorMsg == null,
    };
    const data: ILibraryPoolRequeueModalOutput = {
      requeueParams
    };

    if (!isNullOrUndefined(requeueParams)) {
      this.updateDisableConfirmButton($event);
    }
    this.modalOutput = data;
    this.setModalData(this.modalOutput);
  }

  private updateInfoList(resourceType: ResourceType, poolToRequeue: IRequeueLibraryPool) {
    if (!resourceType || !poolToRequeue) {
      return;
    }
    
    this.libraryCount = poolToRequeue.libraryCount;

    this.infoList.push({
      resourceName: 'Pool Name',
      resourceValue: poolToRequeue.poolName
    });
    this.infoList.push({
      resourceName: 'Current Status',
      resourceValue: poolToRequeue.poolStatus
    });
    this.infoList.push({
      resourceName: 'Total Libraries in Pool',
      resourceValue: this.decimalPipe.transform(poolToRequeue.libraryCount) || ''
    });
  }

  private setModalData(data: ILibraryPoolRequeueModalOutput) {
    if (!this.modal) {
      return;
    }
    this.modal.setData(data, true);
  }

  protected constructError(error: any): string {
    if (error && error.error && error.error.ErrorMessage) {
      return error.error.ErrorMessage;
    }
    return 'An unexpected error occured while requeuing library.';
  }

  /**
   * Checks if the Confirm button should be enabled
   * @param output Output from the modal
   */
  private updateDisableConfirmButton(output: ILibraryPoolRequeueComponentOutput) {

    // checks if the form is valid and the requested additional yield
    // is within the min and max range
    if (!isNullOrUndefined(output.errorMsg) ||
        isNullOrUndefined(output.RequestedAdditionalYield) ||
        !output.isInputValid ) {
      this.disableConfirmButton = true;
    } else {
      this.disableConfirmButton = false;
    }
  }

}
