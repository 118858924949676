export class ApplicationFeatures {
  static UNDEFINED = "Undefined";
  static BILLING = "Billing";
  static AUTOMATABLE = "Automatable";
  static WORKFLOW = "Workflow";
  static PROVIDE_PRESETS = "ProvidesPresets";
  static AUTO_LAUNCHABLE = "AutoLaunchable";
  static BYPASS_FORM = "BypassForm";
  static DISABLE_GRANT_INHERITANCE = "DisableGrantInheritance";
  static ENABLE_HTML_REPORTS = "EnableHtmlReports";
}