import { Component, OnInit, ViewEncapsulation, AfterViewInit } from '@angular/core';
import { Validators, FormControl, FormGroup } from '@angular/forms';
import { CloudRunMapperService } from '@app/core/services/mapper/cloud-run-mapper.service';
import { INITIAL_READ_TYPE_OPTIONS, LIST_INDEX_ADAPTER_KIT_PAGE_SIZE } from '@app/cloud-run-prep/constants';
import { ReadType, IndexAdapterKitsService, CreateLibraryPrepKitRequest, LibraryPrepKitsService, ErrorResponse, LibraryPrepKit } from '@stratus/gss-ng-sdk';
import { Observable } from 'rxjs';
import { shareReplay, retryWhen, map } from 'rxjs/operators';
import { genericRetryWhen } from '@app/core/rxjsutils/rxjs-utilities';
import { RunSetupHelperService } from '@app/cloud-run-prep/run-setup/helper/run-setup-helper.service';
import { NgxSmartModalService, ToastrService, NgxSmartModalComponent } from '@bssh/comp-lib';
import { BaseModalComponent } from '@app/shared/modals/base-modal/base-modal.component';
import { ICreateLibraryPrepKitModalOutput } from '../../model/action-modal';
import _ from 'lodash';

@Component({
  selector: 'library-prep-kit-modal',
  templateUrl: './library-prep-kit-modal.component.html',
  styleUrls: ['./library-prep-kit-modal.component.scss'],
})

export class LibraryPrepKitModalComponent extends BaseModalComponent implements OnInit, AfterViewInit {
  public modalType = 'libraryPrepKit';
  // Modal Text
  public title = 'Custom Library Prep Kit';
  public closeButtonText = 'Cancel';
  public confirmButtonText = 'Create New Kit';

  // Error message
  public errorMessage: string;

  // Compatible IAK list
  public compatibleIAKs$: Observable<any>

  // LPK Form validation rules
  public ValidationRule = {
    displayName: {
      maxLength: 255
    },
    integer: {
      pattern: new RegExp(/^(0|[1-9]\d*)$/)
    },
    minReadLengths: 1,
    maxReadLengths: 2147483647
  }

  // Initial data
  public readTypeOptions = this.mapper.mapReadTypes(INITIAL_READ_TYPE_OPTIONS);
  // Text to be shown in dropdown
  public selectedIAKText = "Select";
  // If paired read type is selected
  public pairedReadTypeSelected = true;

  constructor(private mapper: CloudRunMapperService = null,
    private indexAdapterKitService: IndexAdapterKitsService,
    private libraryPrepKitsService: LibraryPrepKitsService,
    private helper: RunSetupHelperService,
    public ngxSmartModalService: NgxSmartModalService,
    private toastr: ToastrService) {
    super(ngxSmartModalService);
  }

  // Initialize form with validations
  public lpkFormGroup: FormGroup = new FormGroup({
    lpkDisplayName: new FormControl('', [
      Validators.required,
      this.noWhitespaceValidator,
      Validators.maxLength(this.ValidationRule.displayName.maxLength)
    ]),
    readType: new FormControl(
      [ReadType.SINGLE, ReadType.PAIRED],
      [Validators.required]
    ),
    defaultReadCycle1: new FormControl('', [
      Validators.required,
      Validators.pattern(this.ValidationRule.integer.pattern),
      Validators.max(this.ValidationRule.maxReadLengths),
      Validators.min(this.ValidationRule.minReadLengths)
    ]),
    defaultReadCycle2: new FormControl('', [
      Validators.required,
      Validators.pattern(this.ValidationRule.integer.pattern),
      Validators.max(this.ValidationRule.maxReadLengths),
      Validators.min(this.ValidationRule.minReadLengths)
    ]),
    compatibleIAKs: new FormControl([], [Validators.required])
  });

  public noWhitespaceValidator(control: FormControl) {
    return (control.value || '').trim().length ? null : { 'required': true };       
}

  get lpkDisplayName() { return this.lpkFormGroup ? this.lpkFormGroup.get('lpkDisplayName') : null; }
  get readType() { return this.lpkFormGroup ? this.lpkFormGroup.get('readType') : null; }
  get defaultReadCycle1() { return this.lpkFormGroup ? this.lpkFormGroup.get('defaultReadCycle1') : null; }
  get defaultReadCycle2() { return this.lpkFormGroup ? this.lpkFormGroup.get('defaultReadCycle2') : null; }
  get compatibleIAKs() { return this.lpkFormGroup ? this.lpkFormGroup.get('compatibleIAKs') : null; }

  public getLPKDisplayNameErrorMessage() {
    if (!this.lpkDisplayName.touched || !this.lpkDisplayName.errors) {
      return null;
    }
    if (this.lpkDisplayName.errors.maxlength) {
      return "Maximum 255 characters.";
    }
    if (this.lpkDisplayName.errors.required) {
      return "Kit Display Name is required";
    }
    return "";
  }

  // Load all IAKs from GSS
  public loadCompatibleIAKs() {
    const indexAdapterKits$ = this.indexAdapterKitService.listIndexAdapterKits({
      sort: 'name',
      pageSize: LIST_INDEX_ADAPTER_KIT_PAGE_SIZE
    });

    this.compatibleIAKs$ = indexAdapterKits$.pipe(
      shareReplay(1),
      retryWhen(genericRetryWhen()),
      map(x => this.helper.groupByCustomAndStandardOptions(this.mapper.mapIndexAdapterKitListToCustomOptions(x.items))));
  }

  public subscribeToFormChange() {
    // When paired read type is not selected, default the read cycle 2 value to be 0 and disable the control
    // So that form validation will pass
    this.readType.valueChanges.subscribe(x => {
      this.pairedReadTypeSelected = x.indexOf(ReadType.PAIRED) > -1;
      if (!this.pairedReadTypeSelected) {
        this.defaultReadCycle2.disable();
        this.defaultReadCycle2.patchValue(0);
      } else {
        this.defaultReadCycle2.enable();
      }
    });
    // Upon change in compatible IAK, need to update the display text
    this.compatibleIAKs.valueChanges.subscribe(() => {
      this.setSelectedIAKText();
    });
  }

  /**
   * When no iak is selected, show 'Select'
   * When only one iak is selected, show its text
   * When multiple iak is selected, show 'Multiple IAKs'
   */
  public setSelectedIAKText() {
    if (this.compatibleIAKs.value.length > 1) {
      this.selectedIAKText = "Multiple IAKs";
    } else if (this.compatibleIAKs.value.length > 0) {
      this.compatibleIAKs$.subscribe(groupedIAK => {
        groupedIAK.forEach(x => {
          if (!x.options) {
            return;
          }
          let selectedIAK = x.options.find(iak => iak.id === this.compatibleIAKs.value[0]);
          if (selectedIAK) {
            this.selectedIAKText = selectedIAK.text;
          }
        })
      })
    } else {
      this.selectedIAKText = "Select";
    }
  }

  ngOnInit() {
    super.ngOnInit();
    this.loadCompatibleIAKs();
    this.subscribeToFormChange();
  }

  ngAfterViewInit() {
    super.ngAfterViewInit();
  }

  /**
   * Upon submission, process the form and submit request to GSS to create LPK and handle return result
   */
  accept(modal: NgxSmartModalComponent) {
    const requestBody: CreateLibraryPrepKitRequest = {
      name: this.cleanDisplayName(this.lpkDisplayName.value),
      displayName: this.lpkDisplayName.value.trim(),
      allowedReadTypes: this.readType.value,
      defaultRead1Length: +this.defaultReadCycle1.value,
      defaultRead2Length: +this.defaultReadCycle2.value,
      indexAdapterKitIds: this.compatibleIAKs.value
    };

    this.subs.sink = this.libraryPrepKitsService.createLibraryPrepKit(requestBody)
      .subscribe({
        next: lpkCreated => this.processSuccessfulKitCreation(lpkCreated, modal),
        error: response => this.parseErrorResponse(response.error, requestBody)
      });
  }

  /**
   * Strip all invalid character for name field
   */
  private cleanDisplayName(displayName : string) {
    // Remove invalid first character (if any)
    // Must start with alphanumeric, dash, or underscore
    let cleanedName = displayName.replace(/^[^a-zA-Z0-9_\-]+/, '');

    // Replace invalid characters for the rest of the string
    // Only contain alphanumeric, period, dash, and underscore characters
    cleanedName = cleanedName.replace(/[^a-zA-Z0-9_\-\.]/g, '');

    return cleanedName;
}

  /**
   * Parse error to proper error messages.
   * GSS error code mapping should handle here.
   * @returns error message as HTML string
   */
  private parseErrorResponse(err, createRequest: CreateLibraryPrepKitRequest) {
    let errMessage = 'Something is wrong, please try again later.';
    const errData = err || {};
    if (errData.message) {
      if ((errData.code || '') === 'GenomicSequencingService.LibraryPrepKits.LibraryPrepKitAlreadyExist') {
        errMessage = `Name '${createRequest.displayName}' already exists, please choose a different name.`;
      } else {
        errMessage = `<h4>${errData.message}</h4>`;
        ((errData.details) || []).forEach(obj => {
          Object.keys(obj).forEach((key) => {
            errMessage = errMessage.concat(`<b>${key}</b></br>${obj[key]}</br></br>`);
          });
        });
      }
    }
    this.toastr.error(errMessage);
  }

  /**
   * Prompt successful toast message, emit the event and close the modal.
   * @param libraryPrepKitCreated created iak data returned from GSS
   * @param modal instance needed to close the modal
   */
  private processSuccessfulKitCreation(libraryPrepKitCreated: LibraryPrepKit, modal: NgxSmartModalComponent) {
    this.toastr.success(`Your "${libraryPrepKitCreated.displayName}" library prep kit has been successfully saved.`);
    const modalOuptut: ICreateLibraryPrepKitModalOutput = { libraryPrepKitCreated };
    this.confirm.emit(modalOuptut);
    modal.close();
  }
}
