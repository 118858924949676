import { Component, AfterViewInit, OnInit, Input, SecurityContext, ViewChild, ElementRef, ChangeDetectorRef } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { IDownloadModalInput } from '../model/action-modal';
import { DomSanitizer } from '@angular/platform-browser';
import { NgxSmartModalComponent, NgxSmartModalService, IFileListTypes } from '@bssh/comp-lib';
import { ResourceType } from '../../../core/model/resource-type';
import { ModalTexts } from '../modal-texts';
import { BaseModalComponent } from '../base-modal/base-modal.component';
import { HttpClient } from '@angular/common/http';
import { ProgressComponent } from '@bssh/comp-lib';
import { BytesPipe } from '../../../core/utilities/pipes/bytes.pipe';
import get from 'lodash/get';

@Component({
  selector: 'app-download-modal',
  templateUrl: './download-modal.component.html',
  styleUrls: ['./download-modal.component.scss']
})
export class DownloadModalComponent extends BaseModalComponent implements AfterViewInit, OnInit {

  modalType = 'DownloadModal';
  confirmButtonText = ModalTexts.DOWNLOAD.CONFIRM_BUTTON_TEXT;

  public resourceType = null; // type of the resource to download
  public resourceList: any[] = []; // list of the resource to download
  public showOauthErrorBody = false; // To show the authorisation error body that is received as response on making call to downloadUri
  public showError = false; // To show the error messages if any
  public authErrorBodyToDisplay: string;
  public isLoading = false;
  private isProceed = false;


  // Contains header field values for different section of modal
  public headers = {
    modalHeader: '',
    resourceTitleHeader: '',
    resourcePropertyHeader: '',
    downloadOptionHeader: '',
  };

  public downloadMessage: string;
  public downloadLinkText: string;
  public downloadLink = '#'; // Download link for baseSpace downloader setup
  public downloadAlertMessage = '';

  // configs for downloading the resource
  private downloadConfigs = {
    webStartDefaultPath: '',
    webStartAppSessionVcfPath: '',
    webStartAppSessionBamPath: '',
    installUri: '',
    altInstallUri: '',
    downloadType: '',
    fastqDownloadEnabled: false,
    launchSavUrl: '',
    savInfoUrl: '',
    runAnalysisUrl: '',
  };

  public downloadConfigHtmlDiv = ''; // string value that contains the download config html response
  public itemsToDownload: IItemToDownload[] = [];
  public downloadOptionTypes: IFileListTypes[];
  public activeWrapper = false;
  public loadingSubject$ = new BehaviorSubject<boolean>(undefined);
  private downloadUri = '';

  @ViewChild('spinnerLoader', { static: false }) public spinnerLoader: ProgressComponent;

  constructor(
    public ngxSmartModalService: NgxSmartModalService,
    private sanitizer: DomSanitizer,
    private httpClient: HttpClient,
    private _elementRef: ElementRef, private cdr: ChangeDetectorRef,
    private bytePipe: BytesPipe) {
    super(ngxSmartModalService);
  }

  ngOnInit() {
    super.ngOnInit();

    this.disableConfirmButton = true;
    this.subs.sink = this.data.subscribe((response: IDownloadModalInput) => {
      if (response) {
        this.resourceList = response.resourceList ? response.resourceList : [];
        this.resourceType = response.resourceType;
        this.downloadUri = response.downloadUri;

        if (response.errorMsg) {
          this.makeErrorVisible();
          this.error.next(response.errorMsg);
          return;
        }

        if (!this.downloadUri || !(this.downloadUri.length > 0)) {
          this.makeErrorVisible();
          this.error.next('DownloadUri is Null');
          return;
        }

        if (this.resourceList.length === 0) {
          this.makeErrorVisible();
          this.error.next('No Resource Selected');
          return;
        }

      }
    });

  }

  ngAfterViewInit() {
    super.ngAfterViewInit();

    this.subs.sink = this.loadingSubject$.subscribe({
      next: value => {
        if (value) {
          this.activeWrapper = true;
          this.spinnerLoader.start();
        } else {
          if (this.activeWrapper) {
            setTimeout(() => this.activeWrapper = false, 250);
            this.spinnerLoader.complete();
          }
        }
      }
    });

    // To check if any error is already caught in ngOnInit()...no need to make api call in that case
    if (this.showError === true) {
      return;
    }
    setTimeout(this.initDownloadInfo.bind(this));
  }
  
  initDownloadInfo() {
    this.loadingSubject$.next(true);

    if (this.downloadUri && this.downloadUri.length > 0) {
      this.loadingSubject$.next(true);
      this.httpClient.get(this.downloadUri, { responseType: 'text' }).subscribe({
        next: (response) => {
          this.downloadConfigHtmlDiv = this.extractDownloadConfigs(response.toString());

          // Check if Authorization error is there in response html
          this.showOauthErrorBody = this.downloadConfigHtmlDiv.indexOf('oauth-error') > -1;

          if (this.showOauthErrorBody) {
            this.makeErrorVisible();
            // if there is an Oauth error, html response will be a modal itself....
            // need to extract the body of that modal and show in component
            const authErrorBody = this.extractOauthErrorModalBody(this.downloadConfigHtmlDiv);

            // To show auth error from the response html modal
            this.authErrorBodyToDisplay = this.sanitizer.sanitize(SecurityContext.HTML, authErrorBody);
            this.disableConfirmButton = true;
          } else {
            this.loadDownloadConfigs(this.downloadConfigHtmlDiv);
            this.downloadLink = this.downloadConfigs.installUri;
            this.downloadMessage = ModalTexts.DOWNLOAD.BASESPACE_DOWNLOADER_MESSAGE;
            this.downloadLinkText = ModalTexts.DOWNLOAD.BASESPACE_DOWNLOADER_LINK_TEXT;
            this.initModalProperties();
          }

          this.loadingSubject$.next(false);
        },
        error: error => {
          this.makeErrorVisible();
          this.error.next('Error getting Download Configs: ' + error.error);
          this.loadingSubject$.next(false);
        }
      });

      this.cdr.detectChanges();
    }
  }

  // Overriding the base modal method
  // Action on clicking the Download button
  accept(modal: NgxSmartModalComponent) {
    let selectedOptionName = null;
    if ((this.resourceType === ResourceType.RUN || this.resourceType === ResourceType.ANALYSIS)) {
      if (modal.hasData()) {
        const modalData: IDownloadModalData = modal.getData();
        selectedOptionName = modalData.selectedOptionName;
      } else {
        // for resource types - run & analysis where multiple file types can be selected
        // we need to check the modal data (which stores selected option by user) and use that value for download
        this.disableConfirmButton = true;
        return;
      }
    }
    this.triggerDownload(this.resourceType, selectedOptionName);
  }

  close(modal: NgxSmartModalComponent){
    if(this.resourceType == ResourceType.SAMPLE_FILE){
      this.isProceed = true;
      this.confirm.emit(this.isProceed);
      this.confirm.complete();
    }
  }

  // For Initializing the values to be passed on to the sub components
  private initModalProperties() {
    switch (this.resourceType) {
      case ResourceType.RUN:
        this.initDownloadRun();
        break;

      case ResourceType.PROJECT:
        this.initDownloadProject();
        break;
        
      case ResourceType.DATASET:
      case ResourceType.DATASETS:
      case ResourceType.OTHER_DATASETS:
      case ResourceType.FASTQ_DATASETS:
        this.initDownloadDataset();
        break;

      case ResourceType.ANALYSIS:
        this.initDownloadAnalysis();
        break;
      
      case ResourceType.SAMPLE:
      case ResourceType.SAMPLES:
        this.initDownloadSample();
        break;

      case ResourceType.SAMPLE_FILE:
          this.initDownloadSampleFiles();
          break;

      default:
        break;
    }
  }

  // Initializes the values to be passed on to the sub components when downloading a analysis
  private initDownloadAnalysis() {
    this.headers.resourceTitleHeader = 'Analysis Name';
    this.headers.resourcePropertyHeader = 'Size';
    this.headers.modalHeader = 'Download Analyses';

    this.initItemsToDownload();
    if (this.itemsToDownload.length > 0) {
      this.headers.downloadOptionHeader = 'Select the file types to be downloaded:';

      // initializes the download option types with pre-selected option
      this.downloadOptionTypes = this.getDownloadOptionTypes(this.resourceType);

      // setting the pre-selected option value in modalData
      this.modal = this.ngxSmartModalService.getModal(this.modalType);
      this.modal.setData({ selectedOptionName: 'allFiles' }, true); // pre-selected option for Analysis
      this.disableConfirmButton = false;
    }
  }

  // Initializes the values to be passed on to the sub components when downloading a sample
  private initDownloadSample() {
    this.headers.resourceTitleHeader = 'Sample Name';
    this.headers.resourcePropertyHeader = 'Size';
    this.headers.modalHeader = 'Download Samples';

    this.initItemsToDownload();
    this.disableConfirmButton = false;
  }

  private initDownloadSampleFiles() {
    this.headers.resourceTitleHeader = 'Sample Name';
    this.headers.resourcePropertyHeader = 'Size';
    this.headers.modalHeader = 'Download Samples Files';

    this.initItemsToDownload();
    this.disableConfirmButton = false;
  }

  // Initializes the values to be passed on to the sub components when downloading a run
  private initDownloadRun() {
    this.headers.resourceTitleHeader = 'Run Name';
    this.headers.resourcePropertyHeader = 'Size';
    this.headers.modalHeader = 'Download Run';

    this.initItemsToDownload();

    if (this.itemsToDownload.length > 0) {

      // In the context of a run, there should be only one item in this list.
      if (this.itemsToDownload[0].isFileDataDeleted) {
        this.downloadAlertMessage = ModalTexts.DOWNLOAD.RUN_ANALYSIS_FILES_NOT_AVAILABLE;
      }

      this.headers.downloadOptionHeader = 'Select the file types to be downloaded:';

      // initializes the download option types with pre-selected option
      this.downloadOptionTypes = this.getDownloadOptionTypes(this.resourceType);

      // setting the pre-selected option value in modalData
      this.modal = this.ngxSmartModalService.getModal(this.modalType);
      this.modal.setData({ selectedOptionName: 'SAV' }, true); // pre-selected option for run

      const isFastQDownloadEnabled = this.downloadConfigs.fastqDownloadEnabled;

      if (!isFastQDownloadEnabled) {
        this.downloadOptionTypes[0].disabled = true; // for disabling the selection of fastq option
      }

      this.disableConfirmButton = false;
    }
  }

  // Initializes the values to be passed on to the sub components when downloading a project
  private initDownloadProject() {
    this.headers.resourceTitleHeader = 'Project Name';
    this.headers.resourcePropertyHeader = 'Size';
    this.headers.modalHeader = 'Download Project';

    this.initItemsToDownload();
    this.disableConfirmButton = false;
  }
  
  private initDownloadDataset() {
    this.headers.resourceTitleHeader = 'Dataset Name';
    this.headers.resourcePropertyHeader = 'Size';
    this.headers.modalHeader = 'Download Datasets';

    // initializing dataset items to be shown in Modal
    let downloadableDatasetsExist = false;
    this.resourceList.forEach((dataset) => {
      const itemToDownload: IItemToDownload = {
        name: '',
        status: '',
        value: '',
        isFileDataDeleted: get(dataset, 'IsFileDataDeleted', false)
      };

      itemToDownload.name = dataset.Name;
      itemToDownload.value = this.bytePipe.transform(dataset.TotalSize);

      // If any of the datasets has files in the trash or deleted, display a warning.
      // Assuming there are some datasets that aren't in the trash, we can enable the download button.
      if (dataset.IsFileDataDeleted) {
        itemToDownload.status = 'failed'; // to display notice/warning icon in front of this item
        this.downloadAlertMessage = ModalTexts.DOWNLOAD.DATASETS_ALERT_MESSAGE;
      } else {
        downloadableDatasetsExist = true;
      }
      this.itemsToDownload.push(itemToDownload);
    });
    this.disableConfirmButton = !this.itemsToDownload.some(ds => ds.status !== 'failed');
  }

  // Initializes resource items to be shown in Modal
  private initItemsToDownload() {
    if (!this.resourceList) {
      return;
    }

    for (let resource of this.resourceList) {
      const itemToDownload: IItemToDownload = {
        name: '',
        status: '',
        value: '',
        isFileDataDeleted: get(resource, 'IsFileDataDeleted', false)
      };

      if (this.resourceType === ResourceType.RUN) {
        itemToDownload.name = resource.ExperimentName;
      } else if(this.resourceType === ResourceType.SAMPLE_FILE) {
        itemToDownload.name = resource.name;
      
      } else {
        itemToDownload.name = resource.Name;
      }
      itemToDownload.value = this.bytePipe.transform(this.resourceType === ResourceType.SAMPLE_FILE ? resource.sizeInBytes : resource.TotalSize, 2);
      this.itemsToDownload.push(itemToDownload);
    }
  }

  // Triggers the download of resource by opening basespace downloader
  private triggerDownload(resourceType: ResourceType, selectedOptionValue: string) {
    // by default webStartDefaultPath config is used to download all resource types
    // except for run and analysis there we select download config based on selected option
    switch (resourceType) {

      case ResourceType.RUN:
        if (selectedOptionValue === 'FASTQ') {
          window.location.href = this.downloadConfigs.webStartDefaultPath;
        } else if (selectedOptionValue === 'SAV') {
          window.location.href = this.downloadConfigs.launchSavUrl;
        }
        break;

      case ResourceType.ANALYSIS:
        if (selectedOptionValue === 'allFiles') {
          window.location.href = this.downloadConfigs.webStartDefaultPath;
        } else if (selectedOptionValue === 'vcfFiles') {
          window.location.href = this.downloadConfigs.webStartAppSessionVcfPath;
        } else if (selectedOptionValue === 'bamFiles') {
          window.location.href = this.downloadConfigs.webStartAppSessionBamPath;
        }
        break;

      default:
        window.location.href = this.downloadConfigs.webStartDefaultPath;
        break;
    }

    this.disableConfirmButton = true;
    this.isProceed = true;
  }

  // Listens to the events from lib-download-data for button selection changes
  dataChanged($event) {
    const downloadOptionTypes = $event.fileTypes;

    // only one option can be selected for any resource type at a time
    // filter the selected option
    const selectedOption = downloadOptionTypes.filter((option) => {// returns an array with selected option
      if (option.checked) {
        return option.value;
      } else {
        return;
      }
    });

    const modalData: IDownloadModalData = {
      selectedOptionName: null
    };

    // extract selected value from selectedOption array and set in modal data
    if (selectedOption.length === 1) {
      modalData.selectedOptionName = selectedOption[0].value;
    }

    this.modal.setData(modalData, true);
    this.disableConfirmButton = false;
  }

  // Populates downloadConfigs object with attributes from config div
  private loadDownloadConfigs(downloadConfigHtml: string) {
    if (!downloadConfigHtml || downloadConfigHtml === '') {
      return;
    }

    const attributeToProperty = {
      'data-bs-web-start-default-path': 'webStartDefaultPath',
      'data-bs-web-start-app-session-vcf-path': 'webStartAppSessionVcfPath',
      'data-bs-web-start-app-session-bam-path': 'webStartAppSessionBamPath',
      'data-bs-install-uri': 'installUri',
      'data-bs-alt-install-uri': 'altInstallUri',
      'data-bs-download-type': 'downloadType',
      'data-bs-analysis-link-enabled': 'analysisLinkEnabled',
      'data-bs-fastq-download-enabled': 'fastqDownloadEnabled',
      'data-bs-launch-sav-url': 'launchSavUrl',
      'data-bs-sav-info-url': 'savInfoUrl',
      'data-bs-run-analysis-url': 'runAnalysisUrl'
    };

    const domElement = document.createElement('div');
    domElement.innerHTML = downloadConfigHtml;
    const configDom = domElement.querySelector('#downloader-config');
    const attributeList = configDom.attributes;

    for (let i = 0; i < attributeList.length; i++) {
      let attribute = attributeList[i];
      if (attribute.name === 'id') {
        continue;
      }

      if (attribute.value === 'true' || attribute.value === 'false') {
        this.downloadConfigs[attributeToProperty[attribute.name]] = attribute.value === 'true' ? true : false;
      } else {
        this.downloadConfigs[attributeToProperty[attribute.name]] = attribute.value;
      }
    }
  }

  // extracts config div from response
  private extractDownloadConfigs(htmlData: string) {
    if (!htmlData) {
      return '';
    }
    const openBodyTagStart = '<body';
    const endBodyTag = '</body>';
    const startIdx = htmlData.indexOf('>', htmlData.indexOf(openBodyTagStart)) + 1;
    const endIdx = htmlData.indexOf(endBodyTag, startIdx);
    return htmlData.substring(startIdx, endIdx);
  }

  private extractOauthErrorModalBody(htmlData: string): string {
    if (!htmlData || htmlData === '') {
      return '';
    }
    // We want to keep the div, but remove the class="modal-body", because we're putting the content inside an
    // existing modal body. We can't use the whole dialog returned by the /oauth/authorize endpoint , because it contains
    // a header and a footer with buttons that would not be appropriate here.
    const openBodyTagStart = '<div class=\"modal-body\"';
    const endBodyTag = '<div class=\"modal-footer\">';
    const startIdx = htmlData.indexOf(openBodyTagStart) + openBodyTagStart.length;
    const endIdx = htmlData.indexOf(endBodyTag, startIdx);
    return '<div' + htmlData.substring(startIdx, endIdx);
  }

  // Returns Initial values for downloadOptionTypes with one of the buttons pre-selected based on resourceType
  private getDownloadOptionTypes(resourceType: ResourceType): IFileListTypes[] {
    if (!resourceType) {
      return [];
    }
    switch (resourceType) {
      case ResourceType.RUN:
        const runDownloadOptionTypes = [
          {
            label: 'FASTQ',
            value: 'FASTQ',
            checked: false,
            type: 'radio',
            disabled: false
          },
          {
            label: 'SAV',
            value: 'SAV',
            checked: true, // for pre-selected option
            type: 'radio',
            disabled: false
          }
        ];
        return runDownloadOptionTypes;

      case ResourceType.ANALYSIS:
        const analysisDownloadOptionTypes = [
          {
            label: 'All file types including VCF, BAM, & FASTQ',
            value: 'allFiles',
            checked: true, // for pre-selected option
            type: 'radio',
            disabled: false
          },
          {
            label: 'VCF',
            value: 'vcfFiles',
            checked: false,
            type: 'radio',
            disabled: false
          },
          {
            label: 'BAM',
            value: 'bamFiles',
            checked: false,
            type: 'radio',
            disabled: false
          }
        ];
        return analysisDownloadOptionTypes;

      default:
        return [];
    }
  }

  private makeErrorVisible() {
    this.showError = true;
    this.headers.modalHeader = 'Error Downloading Resource';
  }

  public onStarted(): void {
    this.spinnerLoader.spinner = true;
    this.activeWrapper = true;
    this._elementRef.nativeElement.ownerDocument.body.style.overflow = 'hidden';
  }

  public onCompleted(): void {
    this.activeWrapper = false;
    this.spinnerLoader.spinner = false;
    this._elementRef.nativeElement.ownerDocument.body.style.overflow = null;
  }
}

export interface IItemToDownload {
  name: string;
  status?: string;
  value: any;
  isFileDataDeleted: boolean;
}

export interface IDownloadModalData {
  selectedOptionName: string;
}
