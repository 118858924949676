import { Injectable } from "@angular/core";
import { ApplicationCategory } from "@app/core/model/applications/application-category";
import { IAppSession } from "@app/core/model/appSessions/appSession";
import { BaseService } from "@app/core/services/base.service";
import { PropertyService } from "@app/core/services/property/property.service";
import { StringUtilities } from "@app/core/utilities/string-utilities";
import { FilePreviewModalComponent } from "@app/shared/modals/file-preview-modal/file-preview-modal.component";
import { IFilePreviewModalInput, IFilePreviewModalOutput } from "@app/shared/modals/model/action-modal";
import { ModalService } from "@app/shared/modals/services/modal.service";
import { IApiFile } from "@bssh/comp-lib";
import environment from "@environments/environment";
import _ from "lodash";


export interface IAnalysisUtilityService {
    isMultiNode(appSession: IAppSession): boolean;
    isWorkflow(appSession: IAppSession): boolean;
}

@Injectable({
    providedIn: 'root'
  })
  
  export class AnalysisUtilityService  extends BaseService implements IAnalysisUtilityService  {
   
    constructor(private propertyService : PropertyService, 
        private modalService: ModalService) { super()}

    public isMultiNode(appSession: IAppSession): boolean {
        if (appSession.Properties && appSession.Properties.Items) {
            const multiNodeProp = this.propertyService.getPropertyValue(
                appSession.Properties.Items, "BaseSpace.Private.IsMultiNode") as string;
            return (_.isString(multiNodeProp) && multiNodeProp === 'True');
        }
        return false;
    }

    public isWorkflow(appSession: IAppSession): boolean {
        return appSession.Application && appSession.Application.Category === ApplicationCategory.WORKFLOW;
    }

    showFilePreview(fileToPreview: IApiFile, isPreviewable?: boolean) {

        const data: IFilePreviewModalInput = {
          apiBaseUrl: `${StringUtilities.trimTrailingSlash(environment.apiUrl)}/`,
          requestedFile: fileToPreview,
        };
        if (isPreviewable) {
          data.isPreviewableTextFile = true;
        }
        this.subs.sink = this.modalService.openModal(data, FilePreviewModalComponent).confirm.subscribe({
          next: (output: IFilePreviewModalOutput) => {
            if (output) {
              this.downloadFile(output.downloadLink, output.fileName);
            }
          },
          error: error => { console.error(error); /* TODO: handle */ }
        });
      }
    
      downloadFile(link: string, fileName: string): void {
        const element = document.createElement('a');
        element.setAttribute('href', link);
        element.setAttribute('download', fileName);
    
        element.style.display = 'none';
        document.body.appendChild(element);
    
        element.click();
    
        document.body.removeChild(element);
      }
  }
