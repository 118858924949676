import { Pipe, PipeTransform, Injectable } from '@angular/core';

@Pipe({
    name: 'bytes',
})
@Injectable({
    providedIn: 'root'
  })
export class BytesPipe implements PipeTransform {
    transform(bytes: number = 0, precision?: number, zeroBytesDisplay?: string): string {

        let kilobyte = 1024;
        let megabyte = Math.pow(kilobyte, 2);
        let gigabyte = Math.pow(kilobyte, 3);
        let terabyte = Math.pow(kilobyte, 4);
        let petabyte = Math.pow(kilobyte, 5);

        zeroBytesDisplay = zeroBytesDisplay || '--';
    
        if (typeof bytes === 'undefined') {
            return '--'; // Standard notation for missing values as of 2016/03
        } else if(bytes === 0) {
            return zeroBytesDisplay;
        } else if ((bytes > 0) && (bytes < kilobyte)) {
            return bytes + " B";
        } else if ((bytes >= kilobyte) && (bytes < megabyte)) {        
            return (bytes/kilobyte).toFixed(precision) + " KB";
    
        } else if ((bytes >= megabyte) && (bytes < gigabyte)) {
            return (bytes / megabyte).toFixed(precision) + " MB";
    
        } else if ((bytes >= gigabyte) && (bytes < terabyte)) {
            return (bytes / gigabyte).toFixed(precision) + " GB";
    
        } else if ((bytes >= terabyte) && (bytes < petabyte)) {
            //Default format for large items should include 2 decimal places e.g. 1.23 TB
            return (bytes / terabyte).toFixed(precision || 2) + " TB";
    
        } else if (bytes >= petabyte) {
            return (bytes / petabyte).toFixed(precision || 2) + " PB";
    
        } else {
            return bytes + " B";
        }
    }
}
