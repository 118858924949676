import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { IV1ApiResponse, IV1ListResponse } from '@app/core/model/v1-api/v1-api-wrappers';
import { BsApiEndPoints } from '@app/core/services/bs-api/endpoints';
import environment from '@environments/environment';
import { delay, finalize, retryWhen, shareReplay, map, catchError, tap } from 'rxjs/operators';
import { BehaviorSubject, Observable, Subject, EMPTY, throwError, of } from 'rxjs';
import { isNullOrUndefined } from 'util';
import { genericRetryWhen, observableEmitDelay } from '@app/core/rxjsutils/rxjs-utilities';
import { PageContextService } from '@app/core/services/page-context.service';
import { IProject } from '@app/core/model/projects/project';
import { TrashService } from '@app/core/services/toolbar/trash.service';
import { BsApiService } from '@app/core/services/bs-api/bs-api-service';
import { ConsoleLogger } from '@app/core/utilities/consolelogger';
import { ErrorMessages } from '@app/core/utilities/error-messages';
import { ICollaborator, ICollaboratorListResponse } from '../../model/collaborator';
import { TransferOwnershipService } from '@app/core/services/toolbar/transfer-ownership.service';
import { ArrayUtilities } from '@app/core/utilities/array-utilities';
import { ToastrService } from '@bssh/comp-lib';
import { ResourceType } from '@app/core/model/resource-type';
import { ToastrMessages } from '@app/core/utilities/toastr-messages';
import { ShareService } from '@app/core/services/toolbar/share.service';
import { IShareUrlResponse } from '@app/core/model/collaborator';
import { BasespaceService } from '@bssh/ng-sdk';
import _ from 'lodash';
import { IResourceStore, ITransferrableResource } from '../resource/resource.store';
import { IProjectsState } from './projects.state';
import { AbstractResourceStore } from '../resource/abstract.resource.store';
import { DownloadService } from '../../services/toolbar/download.service';
import { DownloadUrl } from '../../model/download-url';
import { Invitation } from '@app/core/model/v2-api/invitation';
import { IApiListResponseWrapper, IApiResponseWrapper } from '@app/core/model/v2-api/v2-api-wrappers';
import { ITrashModalInput } from '@app/shared/modals/model/action-modal';
import { IItemToTrash } from '@app/shared/modals/trash-modal/trash-modal.component';
import { BytesPipe } from '@app/core/utilities/pipes/bytes.pipe';
import { Constants } from '@app/core/utilities/constants';
import { CodeFeaturesService } from '@app/core/services/user/code-features.service';
import { ICapability } from '@app/core/model/Capability/Capability';

export interface IProjectsStore extends IResourceStore<IProjectsState> {
    loadCurrentProject(id: string, forceLoad: boolean): void;
    loadProjectsList(offset: number, limit: number, sortby: string, sortdir: string, forceLoad: boolean): void;
    updateSelectedProjects(updatedSelections: IProject[]): void;
    unloadCurrentProject(): void;
    createNew(params: BasespaceService.PostV2ProjectsParams): Observable<IProject>;
    getProjectById(id: string): Observable<IProject>;
    getProjectCapabilities(projectId: string, projectCapabilityType: ProjectCapabilityType): Observable<IProjectCapabilities>;
}

@Injectable({
    providedIn: 'root'
})
export class ProjectsStore extends AbstractResourceStore<IProjectsState> implements IProjectsStore, ITransferrableResource {

    private refreshSubject = new BehaviorSubject<boolean>(false);
    projectsRefreshed$ = this.refreshSubject.asObservable();

    private v1ProjectsListEndpoint = `${environment.apiUrl}/v1pre3/users/current/projects`;

    constructor(private pageContextService: PageContextService,
        private shareService: ShareService,
        private httpClient: HttpClient,
        private trashService: TrashService,
        private toastrService: ToastrService,
        private bsApiService: BsApiService,
        private basespaceApi: BasespaceService,
        private transferOwnershipService: TransferOwnershipService,
        private downloadService: DownloadService,
        private bytePipe: BytesPipe,
        private codeFeatureService: CodeFeaturesService) {
        super(['projectsList', 'projectsOffset', 'projectsLimit', 'projectsSortBy',
            'projectsSortDir', 'selectedProjects', 'currentProject', 'projectStateError', 'selectedBiosamplesForCurrentProject',
            'currentUser', 'currentUserId']);
    }

    /**
     * Calls BSSH API to load project details and cache in the  store
     * @param id Id of the project to fetch. Will not fetch if the state's currentProject already matches this id.
     */
    loadCurrentProject(id: string, forceLoad: boolean = false): void {

        const currentState = this.getState();
        if (currentState && currentState.currentProject && currentState.currentProject.Id === id && !forceLoad) {
            // if project already set don't fetch, but dispatch current state if another call is made later
            this.dispatchCurrentState(currentState);
        } else {
            this.loadingSubject.next(true);

            // Subscribe to retrieve
            this.subs.sink = this.basespaceApi.GetV2ProjectsId({ id, include: [Constants.RelatedUserWithAccessParam] }).pipe(
                // Do not make an additional API call if any additional subscribers have subscribed later on
                shareReplay(1),
                // Retry in case of HTTP errors
                retryWhen(genericRetryWhen()),
                // To avoid a Flash of content, maintain a delay
                delay(observableEmitDelay),
                // Stop loading in finalize.
                finalize(() => this.loadingSubject.next(false))
            ).subscribe({
                next: (project) => {
                    this.setState({ currentProject: project }, ProjectStoreActions.LoadProjectDetails);
                },
                error: error => this.handleError(error, () => ({ ...currentState, projectStateError: error }))
            });
        }
    }


    /**
     * Gets a Project by Id without mutating the Store's state.
     * If the current context project is the same as the one requested, returns from state.
     *  Otherwise calls API to obtain it.
     * @param id Project Id
     */
    getProjectById(id: string): Observable<IProject> {
        const currentState = this.getState();
        if (currentState && currentState.currentProject && currentState.currentProject.Id === id) {
          return of( currentState.currentProject);
        } else {
          return this.basespaceApi.GetV2ProjectsId({ id });
        }
    }

    /**
     * Unload current project data to avoid stale data displayed when enter another project details page
     */
    unloadCurrentProject(): void {
        this.setState({ currentProject: undefined });
    }

    /**
     * Calls BSSH API to load a list of projects and cache in the projects store as 'projectsList'
     */
    loadProjectsList(offset: number = 0, limit: number = 25, sortby: string = 'DateCreated',
        sortdir: string = 'Desc', forceLoad: boolean = false): void {

        const currentState = this.getState();
        if (currentState && currentState.projectsOffset === offset && currentState.projectsLimit === limit
            && currentState.projectsSortBy === sortby && currentState.projectsSortDir === sortdir && !forceLoad) {

            // if same settings, use the list we already have cached
            this.dispatchCurrentState(currentState);
        } else {
            this.loadingSubject.next(true);

            const requestUrl = this.v1ProjectsListEndpoint;
            const requestParams: any = { limit, offset, sortby, sortdir };

            this.subs.sink = this.httpClient.get<IV1ApiResponse<IV1ListResponse<IProject>>>(requestUrl,
                { params: requestParams })
                .pipe(
                    // Retry in case of HTTP errors
                    retryWhen(genericRetryWhen()),

                    // To avoid a Flash of content, maintain a delay
                    delay(observableEmitDelay),

                    // Stop loading in finalize.
                    finalize(() => this.loadingSubject.next(false))
                ).subscribe({
                    next: (apiResponse) => {
                        const projectsList = apiResponse.Response.Items;
                        this.setState({
                            projectsList,
                            projectsOffset: apiResponse.Response.Offset,
                            projectsLimit: apiResponse.Response.Limit,
                            projectsSortBy: apiResponse.Response.SortBy,
                            projectsSortDir: apiResponse.Response.SortDir,
                            projectStateError: null,
                            selectedProjects: null,
                            selectedBiosamplesForCurrentProject: null,
                        }, ProjectStoreActions.LoadProjectsList);
                    },
                    error: error => {
                        this.handleError(error, () => ({ ...this.getState(), projectStateError: error }));
                    }
                });
        }
    }

    updateSelectedProjects(updatedSelections: IProject[]) {
        this.setState({ selectedProjects: updatedSelections }, ProjectStoreActions.UpdateSelectedProjects);
    }

    getActiveList(): IProject[] {
        const { currentProject, selectedProjects } = this.getState();
        if (this.pageContextService.isProjectMasterList()) {
            return selectedProjects;
        }

        return isNullOrUndefined(currentProject) ? null : [currentProject];
    }

    public forceRefreshStore(): void {
        this.refreshSubject.next(true);
    }

    public createNew(params: BasespaceService.PostV2ProjectsParams): Observable<IProject> {
        this.loadingSubject.next(true);
        if (_.isEmpty(params.name)) {
            throw new Error('Name is required to create a new project');
        }

        return this.basespaceApi.PostV2Projects(params).pipe(
            tap(() => {
                const currentState = this.getState();
                // Reload the current project list in order to fetch and load the newly created project into the store.
                this.loadProjectsList(currentState.projectsOffset,
                    currentState.projectsLimit,
                    currentState.projectsSortBy,
                    currentState.projectsSortDir,
                    true);
                this.refreshSubject.next(true);
            }),
            finalize(() => this.loadingSubject.next(false))
        );
    }

    /**
     * Updates the project that is being modified in the list of projects based on id.
     * @param project: project that is being modified
     * @param projectsList: list of projects to update the project that is being modified
     * @param propertyUpdate: property changed
     */
    private getUpdatedProjects(project: IProject, projects: IProject[], propertyUpdate: object): IProject[] {
        if (isNullOrUndefined(projects)) {
            return projects;
        }

        const index = projects.findIndex(r => r.Id === project.Id);
        if (index !== -1) {
            projects[index] = Object.assign(projects[index], propertyUpdate);
        }

        return projects;
    }

    /**
     * set state based on page context. Update projectsList and selected projects if page context is
     * master list.
     * Updates projectsList, selected projects and the current project if page context is
     * details page.
     * @param propertyChange: property that are changing
     * @param projects: the projects acted upon
     * @param actionName: name of action based on service
     * @param error: error in case of partial success ( i.e in case of list of resources)
     * @param forceRefresh: whether to refresh projects list if action was performed from list page
     */
    private setStateOnSuccess(propertyChange: object, projects: IProject[], actionName: ProjectStoreActions,
        error: any = null, forceRefresh?: boolean) {
        const { projectsList, selectedProjects, currentProject } = this.getState();

        let newCurrentProject = null;
        let newProjectsList = projectsList;
        let newSelectedProjectsList = selectedProjects;

        if (this.pageContextService.isProjectMasterList()) {
            if (isNullOrUndefined(projectsList) || isNullOrUndefined(selectedProjects) || selectedProjects.length === 0) {
                return;
            }

            projects.forEach(project => {
                newProjectsList = this.getUpdatedProjects(project, projectsList, propertyChange);
                newSelectedProjectsList = this.getUpdatedProjects(project, selectedProjects, propertyChange);
            });
        }

        if (this.pageContextService.isProjectDetails()) {
            if (projects.length === 1) {
                newCurrentProject = Object.assign(projects[0], propertyChange);
            } else {
                newCurrentProject = currentProject;
            }
        }

        this.setState({
            projectsList: newProjectsList,
            selectedProjects: newSelectedProjectsList,
            currentProject: newCurrentProject,
            projectStateError: error,
        }, actionName);

        this.refreshSubject.next(forceRefresh);
    }

    canTrash(): boolean {
        if (this.isTransferInProgress() || this.isTrashRestoreInProgress()) {
            return false;
        }
        const activeProjects = this.getActiveList();
        const { currentUserId } = this.getState();

        return this.trashService.canTrash(activeProjects, currentUserId);
    }

    canSendProjectToTrash(project: IProject): Observable<IProjectCapabilities> {
        const { currentUserId } = this.getState();
        
        // If I can see the current project but am not the owner, then "deleting" it really just removes my
        // ability to see it. Checking the project capabilities for whether I can delete the project will always
        // return false if I'm not the owner. So we'll generate fake capabilities allowing us to delete the
        // project.
        if (project.UserOwnedBy && project.UserOwnedBy.Id !== currentUserId) {
            return of({
                Delete: {
                    IsCapable: true
                }
            } as IProjectCapabilities);
        } else {
            return this.getProjectCapabilities(project.Id, ProjectCapabilityType.TRASH);
        }
    }

    trash(): void {
        const activeProjects = this.getActiveList();

        if (!activeProjects) {
            throw new Error(ErrorMessages.MISSING_RESOURCE_ERROR);
        }

        const project = activeProjects[0];
        const { currentUserId } = this.getState();
        const isOwner = project.UserOwnedBy ? project.UserOwnedBy.Id === currentUserId : false;

        this.loadingSubject.next(true);
        this.trashService.trashResources([project.Id], ResourceType.PROJECTS).pipe(
            finalize(() => this.loadingSubject.next(false))
        ).subscribe({
            next: (apiResponses) => {
                const totalSuccessCount = apiResponses.TotalSuccessCount;
                const totalFailureCount = apiResponses.TotalFailureCount;

                // getting project to update that were successfully trashed
                const deletedProjects = [];
                let isDeleted = false;
                if (totalSuccessCount > 0) {
                    const deletedProjectFromResponse = apiResponses.SuccessItems[0].Response.DeletedProject;
                    deletedProjects[0] = activeProjects.find(p => p.Id === deletedProjectFromResponse.Id);
                    isDeleted = deletedProjectFromResponse.IsDeleted;
                }

                // getting error information for project that failed deletion
                let trashError = null;
                if (totalFailureCount > 0) {
                    const failureItem = apiResponses.FailureItems[0];
                    trashError = [{
                        errorMessage: failureItem.ErrorMessage,
                        data: failureItem,
                    }];
                }

                this.setStateOnSuccess({
                    IsDeleted: isDeleted,
                }, deletedProjects,
                    ProjectStoreActions.DeletedProject, trashError,
                    this.pageContextService.isProjectMasterList());

                // showing toaster message on success only. Errors are shown in modal itself
                if ((!trashError || trashError.length === 0)) {
                    this.toastrService.success(ToastrMessages.TRASH_COMPLETE_PROJECT_MESSAGE(isOwner));
                }
            },
            error: error => {
                this.handleError(error, () => ({ ...this.getState(), projectStateError: error }));
            }
        });
    }

    canTransferOwnership(): boolean {
        const activeProjects = this.getActiveList();

        if (!ArrayUtilities.isArrayOfSize(activeProjects, 1)) {
            return false;
        }

        if (this.isTransferInProgress() || this.isTrashRestoreInProgress()) {
            return false;
        }

        const { currentUser } = this.getState();
        return this.transferOwnershipService.canTransferResourceOwnership(activeProjects[0], currentUser);
    }

    transferOwnership(email: string, comment?: string): void {
        if (!this.canTransferOwnership()) {
            // re-check, make no state change if currentUser cannot transfer the ownership.
            return;
        }

        this.loadingSubject.next(true);
        const activeProjects = this.getActiveList();

        this.subs.sink = this.transferOwnershipService.transferOwnership(ResourceType.PROJECT, activeProjects[0].Id, email, comment).pipe(
            finalize(() => {
                this.loadingSubject.next(false);
            }))
            .subscribe({
                next: (apiResponse: IApiResponseWrapper<Invitation>) => {
                    this.setStateOnSuccess({ IsTransferPending: true }, activeProjects, ProjectStoreActions.TransferProjectsOwnership);
                    this.toastrService.success(ToastrMessages.TRANSFER_INVITATION_SENT);
                },
                error: error => {
                    this.handleError(error, () => ({ ...this.getState(), projectStateError: error }));
                }
            });
    }

    canCancelOwnershipTransfer(): boolean {
        const activeResources = this.getActiveList();

        if (!ArrayUtilities.isArrayOfSize(activeResources, 1)) {
            return false;
        }

        const { currentUser } = this.getState();
        return this.transferOwnershipService.canCancelResourceOwnershipTransfer(activeResources[0], currentUser);
    }

    getPendingTransfers(): Observable<Invitation[]> {
        const activeResources = this.getActiveList();

        if (!ArrayUtilities.isArrayOfSize(activeResources, 1)) {
            return EMPTY;
        }

        this.loadingSubject.next(true);

        return this.transferOwnershipService.getPendingTransfers(ResourceType.PROJECT, activeResources[0].Id).pipe(
            map((response: IApiListResponseWrapper<Invitation>) => response.Response.Items),
            catchError((error: Error) => {
                this.handleError(error, () => ({ ...this.getState(), projectStateError: error }));
                return throwError(error);
            }),
            finalize(() => {
                this.loadingSubject.next(false);
            })
        );
    }

    cancelInvitation(InvitationId: string): void {
        // note: have to use v1Pre3Id throughout to call v1 APIs

        if (!this.canCancelOwnershipTransfer()) {
            // re-check, make no state change if currentUser cannot transfer the ownership.
            return;
        }

        this.loadingSubject.next(true);
        const activeResources = this.getActiveList();

        this.subs.sink = this.transferOwnershipService.cancelInvitation(InvitationId).pipe(
            finalize(() => {
                this.loadingSubject.next(false);
            }))
            .subscribe({
                next: (apiResponse: IApiResponseWrapper<Invitation>) => {
                    this.setStateOnSuccess({ IsTransferPending: false }, activeResources, ProjectStoreActions.CancelProjectsTransfer);
                    this.toastrService.success(ToastrMessages.CANCEL_OWNERSHIP_SUCCESS_MESSAGE);
                },
                error: error => {
                    this.handleError(error, () => ({ ...this.getState(), projectStateError: error }));
                }
            });
    }

    private getActiveProject(): IProject {
        const activeProjects = this.getActiveList();

        return (!isNullOrUndefined(activeProjects) && activeProjects.length === 1) ?
            activeProjects[0] : null;
    }

    public canShare(): boolean {
        const activeProject = this.getActiveProject();
        if (isNullOrUndefined(activeProject)) {
            return false;
        }
        if (this.isTransferInProgress() || this.isTrashRestoreInProgress()) {
            return false;
        }

        const { currentUserId } = this.getState();
        return this.shareService.canShare(activeProject.UserOwnedBy, currentUserId);
    }

    getCollaborators(): Observable<ICollaborator[]> {
        const activeProjects = this.getActiveList();

        if (!ArrayUtilities.isArrayOfSize(activeProjects, 1)) {
            return EMPTY;
        }

        this.loadingSubject.next(true);
        return this.shareService.getCollaborators(ResourceType.PROJECT, activeProjects[0].Id).
            pipe(
                map((response: ICollaboratorListResponse) => !isNullOrUndefined(response) ? response.results : null),
                finalize(() => {
                    this.loadingSubject.next(false);
                })
            );
    }

    sendInvitations(collaborators: ICollaborator[]): void {
        const activeProjects = this.getActiveList();

        if (!ArrayUtilities.isArrayOfSize(activeProjects, 1)) {
            return;
        }

        this.loadingSubject.next(true);
        this.subs.sink = this.shareService.sendInvitations(ResourceType.PROJECT, activeProjects[0].Id, collaborators).pipe(
            finalize(() => {
                this.loadingSubject.next(false);
            }))
            .subscribe({
                next: (apiResponse) => {
                    this.setState({ projectStateError: null }, ProjectStoreActions.ShareProjectByEmail);
                },
                error: error => {
                    this.handleError(error, () => ({ ...this.getState(), projectStateError: error }));
                }
            });
    }

    public getShareLink(): Observable<string> {
        this.loadingSubject.next(true);
        const subject = new Subject<string>();
        const activeProject = this.getActiveProject();

        if (isNullOrUndefined(activeProject)) {
            return EMPTY;
        }

        this.subs.sink = this.shareService.getLink(ResourceType.PROJECT, activeProject.Id).pipe(
            finalize(() => {
                this.loadingSubject.next(false);
            }))
            .subscribe({
                next: (apiResponse: IShareUrlResponse) => {
                    subject.next(apiResponse.ShareUrl);
                    this.setState({ projectStateError: null });
                },
                error: error => {
                    this.handleError(error, () => ({ ...this.getState(), projectStateError: error }));
                }
            });

        return subject.asObservable();
    }

    public activateLink(): Observable<string> {
        this.loadingSubject.next(true);
        const subject = new Subject<string>();
        const activeProject = this.getActiveProject();

        if (isNullOrUndefined(activeProject)) {
            return EMPTY;
        }

        this.subs.sink = this.shareService.activateLink(ResourceType.PROJECT, activeProject.Id).pipe(
            finalize(() => {
                this.loadingSubject.next(false);
            }))
            .subscribe({
                next: (apiResponse: IShareUrlResponse) => {
                    subject.next(apiResponse.ShareUrl);
                    this.setState({ projectStateError: null });
                },
                error: error => {
                    this.handleError(error, () => ({ ...this.getState(), projectStateError: error }));
                }
            });

        return subject.asObservable();
    }

    public deactivateLink(): Observable<string> {
        this.loadingSubject.next(true);
        const subject = new Subject<string>();
        const activeProject = this.getActiveProject();

        if (isNullOrUndefined(activeProject)) {
            return EMPTY;
        }

        this.subs.sink = this.shareService.deactivateLink(ResourceType.PROJECT, activeProject.Id).pipe(
            finalize(() => {
                this.loadingSubject.next(false);
            }))
            .subscribe({
                next: (apiResponse: IShareUrlResponse) => {
                    subject.next(apiResponse.ShareUrl);
                    this.setState({ projectStateError: null });
                },
                error: error => {
                    this.handleError(error, () => ({ ...this.getState(), projectStateError: error }));
                }
            });

        return subject.asObservable();
    }

    edit(newProjectName: string, newProjectDescription?: string) {

        if (!this.canEdit()) {
            // re-check, make no state change if edit project name not allowed.
            return;
        }

        this.loadingSubject.next(true);
        const activeProjects = this.getActiveList();

        this.subs.sink = this.editProjectInfo(activeProjects[0], newProjectName, newProjectDescription).pipe(
            finalize(() => this.loadingSubject.next(false))
        ).subscribe({
            next: (apiResponse: IApiResponseWrapper<IProject>) => {
                const { Name, Description } = apiResponse.Response;
                this.setStateOnSuccess(
                    { Name, Description },
                    activeProjects,
                    ProjectStoreActions.EditProject,
                    null,
                    this.pageContextService.isProjectMasterList()
                );
            },
            error: error => {
                this.handleError(error, () => ({ ...this.getState(), projectStateError: error }));
            }
        });
    }

    /**
     * edit Project name
     * @param newProjectName: new name
     * @param project: the project acted upon
     */
    // tslint:disable-next-line: max-line-length
    private editProjectInfo(project: IProject, newProjectName: string, newProjectDescription?: string): Observable<IApiResponseWrapper<IProject>> {

        if (!project) {
            return throwError(ErrorMessages.PROJECT_EDIT.NO_PROJECT_SPECIFIED);
        }

        if (!this.isResourceOwner(project)) {
            return throwError(ErrorMessages.PROJECT_EDIT.NOT_OWNER);
        }

        const requestUrl = BsApiEndPoints.getProjectDetailsUrl(project.Id);
        const postData = {
            Name: newProjectName,
            Id: project.Id,
            ...(!isNullOrUndefined(newProjectDescription) && { Description: newProjectDescription })
        };
        return this.httpClient.post<IApiResponseWrapper<IProject>>(requestUrl, postData);
    }

    canEdit(): boolean {
        const activeProjects = this.getActiveList();
        return activeProjects && ArrayUtilities.isArrayOfSize(activeProjects, 1) && this.isResourceOwner(activeProjects[0]);
    }

    canUploadFiles(): boolean {
        const activeProjects = this.getActiveList();
        return activeProjects && ArrayUtilities.isArrayOfSize(activeProjects, 1) && this.isResourceOwner(activeProjects[0]);
    }

    /**
     * Determines whether the active project can be downloaded according the store's state.
     * user is not required to be the owner to download
     * project size should be greater than 0
     */
    canDownload(): boolean {
        const activeProjects = this.getActiveList();

        if (!isNullOrUndefined(activeProjects) && ArrayUtilities.isArrayOfSize(activeProjects, 1)) {
            const projectSize = activeProjects[0].TotalSize;
            return (projectSize > 0) && !this.isTransferInProgress() && !this.isTrashRestoreInProgress();
        }
        return false;
    }

    isTransferInProgress(): boolean {
        const activeProjects = this.getActiveList();

        if (!isNullOrUndefined(activeProjects) && ArrayUtilities.isArrayOfSize(activeProjects, 1)) {
            return activeProjects[0].IsTransferInProgress ? true : false;
        }
        return false;
    }

    isTrashRestoreInProgress(): boolean {
        const activeProjects = this.getActiveList();

        if (!isNullOrUndefined(activeProjects) && ArrayUtilities.isArrayOfSize(activeProjects, 1)) {
            return !isNullOrUndefined(activeProjects[0].IsTrashRestoreInProgress) ? activeProjects[0].IsTrashRestoreInProgress : false;
        }
        return false;
    }

    /**
     * Calls BSSH API to get Project Download Uri.
     */
    getDownloadUri(): Observable<DownloadUrl> {
        const activeProject = this.getActiveProject();

        if (isNullOrUndefined(activeProject)) {
            return EMPTY;
        }

        this.loadingSubject.next(true);

        return this.downloadService.getDownloadUri([activeProject.Id], ResourceType.PROJECT).pipe(
            finalize(() => {
                this.loadingSubject.next(false);
            })
        );
    }

    constructTrashModalInput(project: IProject): Observable<ITrashModalInput> {
        return this.canSendProjectToTrash(project).pipe(
            map((projectCapabilities: IProjectCapabilities) => {
                const projectCanBeDeleted = projectCapabilities.Delete.IsCapable;
                const isOwner = Boolean((project.UserOwnedBy && this.isResourceOwner(project)));
                const hasCollaborators = Boolean(project.HasCollaborators);

                let trashModalInput: ITrashModalInput = {
                    resourceType: ResourceType.PROJECT,
                    alertMessages: [],
                    infoMessage: true
                };

                if (projectCanBeDeleted) {
                    trashModalInput = {
                        ...trashModalInput,
                        itemToTrash: [{
                            id: project.Id,
                            name: project.Name,
                            value: this.bytePipe.transform(project.TotalSize)
                        } as IItemToTrash]
                    };

                    // pushing alert and error messages in this block
                    if (isOwner) {
                        trashModalInput.alertMessages.push(ErrorMessages.TRASH_PROJECT.OWNER_ALERT);
                        if (hasCollaborators) {
                            trashModalInput.alertMessages.push(ErrorMessages.TRASH_PROJECT.COLLABORATORS_ALERT);
                        }
                    } else {
                        trashModalInput.alertMessages.push(ErrorMessages.TRASH_PROJECT.NOT_OWNER_ALERT);
                    }
                    trashModalInput.alertMessages.push(ErrorMessages.TRASH_PROJECT.PROJECT_ALERT);
                } else {
                    if (projectCapabilities.Delete.Code === Constants.trashDefaultProjectNotAllowed) {
                        if (this.codeFeatureService.nullifyBioSampleDefaultProjectEnabled) {
                            //show warning message
                            trashModalInput.alertMessages.push(ErrorMessages.TRASH_PROJECT.NULLIFY_BIOSAMPLE_DEFAULT_PROJECT_ALERT);

                            // allow project delete on user confirmation with an alert message
                            // On User confirmation, DELETE projects/{id} API logic will nullify project Biosample(s) DefualtProject
                            // project before deleting the project
                            trashModalInput = {
                                ...trashModalInput,
                                itemToTrash: [{
                                    id: project.Id,
                                    name: project.Name,
                                    value: this.bytePipe.transform(project.TotalSize)
                                } as IItemToTrash]
                            };
                        } else {
                            trashModalInput.errorMsg = ErrorMessages.TRASH_PROJECT.PROJECT_BIOSAMPLE_ERROR;
                            trashModalInput.alertMessages.push(ErrorMessages.TRASH_PROJECT.CHANGE_PROJECT_ALERT);
                        }
                    } else {
                        trashModalInput.errorMsg = 'This project can\'t be deleted: ' + projectCapabilities.Delete.Message;
                    }
                }
                return trashModalInput;
            }),
            catchError((error) => {
                const trashModalInput: ITrashModalInput = {
                    resourceType: ResourceType.PROJECT,
                    alertMessages: [],
                    errorMsg: error.message
                };
                return of(trashModalInput);
            })
        );
    }
    getResourceType(): ResourceType {
        return ResourceType.PROJECTS;
    }
    
    getProjectCapabilities(projectId: string, projectCapabilityType: ProjectCapabilityType): Observable<IProjectCapabilities> {
        return this.bsApiService.getProjectCapabilities(projectId, projectCapabilityType).pipe(
             map((response) => response.Response));
    }
}

// The action-names for this store, for logging/tracking.
export enum ProjectStoreActions {
    LoadProjectDetails = 'LOAD_PROJECT',
    LoadProjectsList = 'LOAD_PROJECTS_LIST',
    UpdateSelectedProjects = 'UPDATE_SELECTED_PROJECTS',
    DeletedProject = 'DELETE_PROJECT',
    ShareProjectByEmail = 'SHARE_PROJECT_BY_EMAIL',
    EditProject = 'EDIT_PROJECT_INFO',
    TransferProjectsOwnership = 'TRANSFER_PROJECTS_OWNERSHIP',
    CancelProjectsTransfer = 'CANCEL_PROJECTS_OWNERSHIP_TRANSFER',
    CreateProject = 'CREATE_PROJECT'
}

export interface IProjectCapabilities {
    Delete: ICapability;
    Transfer: ICapability;
}

export enum ProjectCapabilityType {
    TRASH = 'trash',
    TRANSFER = 'transfer'
}