<ngx-smart-modal #libraryPrepKit 
[identifier]="modalType" 
[title]="title"
closable="false" 
dismissible="false" 
escapable="false"
[closeButtonText]="closeButtonText" 
[confirmButtonText]="confirmButtonText" 
[disableConfirmButton]="!lpkFormGroup.valid">
    <form [formGroup]="lpkFormGroup" novalidate ng-submit="onCreateNewKitClick()" autocomplete="off">
        <div class="form-group">
            <h4 class="form-group-title">Display Name</h4>
            <lib-form-field [label]="" [attr.for]="lpkDisplayName" hint="Maximum 255 characters" [isValidateOnTouch]="true"
                [errorMessage]="getLPKDisplayNameErrorMessage()">
                <input lib-input type="text" formControlName="lpkDisplayName" data-cy="lpkDisplayName">
            </lib-form-field>
        </div>
        <div class="form-group">
            <div class="d-flex">
                <h4 class="form-group-title">Read Type</h4>
                <lib-popover popoverType="info" popoverTriggerEvents="mouseenter:mouseleave" popoverSize="medium" popoverPlacement="right">
                    <span>Number of reads per run. Single reads perform one read, paired end runs perform two
                        reads.</span>
                </lib-popover>
            </div>
            <!-- Read Type radio -->
            <lib-radio-checkbox-list type="checkbox" [options]="readTypeOptions" name="readType"
                formControlName="readType" valueProperty="value" labelProperty="text">
            </lib-radio-checkbox-list>
            <div class="error-message" *ngIf="readType.value.length == 0">Must select at least one.</div>
        </div>
        <div class="d-flex">
            <h4 class="form-group-title">Default Read Cycle</h4>
            <lib-popover popoverType="info" popoverTriggerEvents="mouseenter:mouseleave" popoverSize="medium" popoverPlacement="right">
                <span>Number of sequencing cycles per read.</span>
            </lib-popover>
        </div>
        <div class="form-group">
            <!-- Read 1 -->
            <h4>Read 1 Cycles</h4>
            <input lib-input type="text" name="defaultReadCycle1" formControlName="defaultReadCycle1"
                data-cy="defaultReadCycle1">
            <div class="error-message"
                *ngIf="defaultReadCycle1.touched && defaultReadCycle1.errors && defaultReadCycle1.errors.required">Read 1 Cycles is required.</div>
            <div class="error-message"
                *ngIf="defaultReadCycle1.touched && defaultReadCycle1.errors && (defaultReadCycle1.errors.pattern || defaultReadCycle1.errors.min || defaultReadCycle1.errors.max)">
                Read 1 Cycles must be {{ValidationRule.minReadLengths}} - {{ ValidationRule.maxReadLengths }}</div>
        </div>
        <div class="form-group" *ngIf="pairedReadTypeSelected">
            <!-- Read 2 -->
            <h4>Read 2 Cycles</h4>
            <input lib-input type="text" name="defaultReadCycle2" formControlName="defaultReadCycle2"
                data-cy="defaultReadCycle2">
            <div class="error-message"
                *ngIf="defaultReadCycle2.touched && defaultReadCycle2.errors && defaultReadCycle2.errors.required">Read 2 Cycles is required.</div>
            <div class="error-message"
                *ngIf="defaultReadCycle2.touched && defaultReadCycle2.errors && (defaultReadCycle2.errors.pattern || defaultReadCycle2.errors.min || defaultReadCycle2.errors.max)">
                Read 2 Cycles must be {{ValidationRule.minReadLengths}} - {{ ValidationRule.maxReadLengths }}</div>
        </div>
        <div class="form-group">
            <div class="d-flex">
                <h4 class="form-group-title">Compatible Index Adapter Kits</h4>
                <lib-popover popoverType="info" popoverTriggerEvents="mouseenter:mouseleave" popoverSize="medium" popoverPlacement="top">
                    <span>List of compatible Index Adapter Kit.</span>
                </lib-popover>
            </div>
            <combobox [placeholder]="selectedIAKText" formControlName="compatibleIAKs" label="At least one Index Adapter Kit">
                <div class="dropdown-options">
                    <ng-container *ngFor="let groupedOptions of compatibleIAKs$ | async">
                        <label class="groupheader">{{groupedOptions.groupName}}</label>
                        <lib-radio-checkbox-list type="checkbox" [options]="groupedOptions.options"
                            name="compatibleIAKs" formControlName="compatibleIAKs" valueProperty="value"
                            labelProperty="text">
                        </lib-radio-checkbox-list>
                    </ng-container>
                </div>
            </combobox>
        </div>
    </form>
</ngx-smart-modal>
