import environment from '@environments/environment';

export interface Scripts {
    name: string;
    src?: string;
    loadAsync: boolean;
    type: string;
    innerText?: string;
}

 export const ScriptStore: Scripts[] = [
     { name: 'walkmeProd', src: 'https://cdn.walkme.com/users/5b32612733d9430e8009968749f9fa73/walkme_5b32612733d9430e8009968749f9fa73_https.js', loadAsync: true,  type: "walkme" },
     { name: 'walkmeTest', src: 'https://cdn.walkme.com/users/5b32612733d9430e8009968749f9fa73/test/walkme_5b32612733d9430e8009968749f9fa73_https.js', loadAsync: true, type: "walkme"  },
     { name: 'gtmscript', loadAsync: true, type: "googleTagManager", innerText: `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start': new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0], j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src= 'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f); })(window,document,'script','dataLayer', '${environment.googleTagManagerId}');` },
     { name: 'gtmnoscript', loadAsync: true, type: "googleTagManager", src: `https://www.googletagmanager.com/ns.html?id=${environment.googleTagManagerId}` }
];
