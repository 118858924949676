import { AfterViewInit, Component, OnInit, ViewEncapsulation } from '@angular/core';
import { BaseModalComponent } from '../base-modal/base-modal.component';
import { NgxSmartModalService } from '@bssh/comp-lib';
import { IAppLauncherModalInput } from '../model/action-modal';
import { ResourceType } from '@app/core/model/resource-type';
import environment from '@environments/environment';
import { isNullOrUndefined } from 'util';

@Component({
  selector: 'app-app-launcher-modal',
  templateUrl: './app-launcher-modal.component.html',
  styleUrls: ['./app-launcher-modal.component.scss']
})
export class AppLauncherModalComponent extends BaseModalComponent implements AfterViewInit, OnInit {

  modalType = 'InitiateAppLauncherModal';
  ngxSmartModalTitle: string = '';

  projectId?: string;
  sampleId?: string;
  resourceType: ResourceType;

  constructor(public ngxSmartModalService: NgxSmartModalService) {
    super(ngxSmartModalService);
  }

  ngOnInit() {
    super.ngOnInit();
    this.subs.sink = this.data.subscribe((input: IAppLauncherModalInput) => {
      this.projectId = input.projectId;
      this.sampleId = input.sampleId;
      this.resourceType = input.resourceType;

      this.error.next(input.errorMsg);
    });
  }

  public createAppLaunchUrl = (appData: any): string => {

    let appLaunchUrl =  `/apps/${appData.Id}/start?redirectToPreferredVersion=true`;

    switch(this.resourceType){
      case ResourceType.PROJECTS:
        appLaunchUrl += `&project-id=${this.projectId}`
        break;
      case ResourceType.SAMPLES:
      case ResourceType.SAMPLE:
        appLaunchUrl += !isNullOrUndefined(this.projectId) 
        ? `&project-id=${this.projectId}&sample-id=${this.sampleId}` 
        : `&sample-id=${this.sampleId}`;
        break;
    }
    return appLaunchUrl;
  }

  constructError(error: any): string | string[] {
    return '';
  }

}
