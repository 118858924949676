import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, forkJoin, of } from 'rxjs';
import { throwError } from 'rxjs';
import { catchError, delay, finalize, map, retryWhen, shareReplay, switchMap, tap } from 'rxjs/operators';
import { genericRetryWhen, observableEmitDelay } from '../../rxjsutils/rxjs-utilities';
import { PageContextService } from '../../services/page-context.service';
import { AbstractResourceStore } from '../resource/abstract.resource.store';
import { isNullOrUndefined } from 'util';
import { IBioSamplesState } from './biosamples.state';
import { IBioSample } from '@app/core/model/bioSamples/bioSample';
import { IProject } from '@app/core/model/projects/project';
import { BiosampleWorkflowUploadService } from '../../../biosamples/services/biosample-workflow-upload.service';
import { IV2ManifestImportResponse } from '../../bsshapi/api-wrappers';
import {
    BasespaceService, V2BiologicalSampleCompactList, V2PostBioSamplesBulkUpdateRequest, V2LabRequeue,
    V2CreateNewLibraryLabRequeueRequest, V2LabRequeueCompact
} from '@bssh/ng-sdk';
import { IResourceStore } from '../resource/resource.store';
import { INewLibraryRequeueInput } from '@app/shared/modals/model/action-modal';
import { ToastrService } from '@bssh/comp-lib';
import { ToastrMessages } from '@app/core/utilities/toastr-messages';
import { ErrorMessages } from '@app/core/utilities/error-messages';
import { ArrayUtilities } from '@app/core/utilities/array-utilities';
import { BsApiService } from '@app/core/services/bs-api/bs-api-service';
import { isEmpty } from 'lodash';
import { LabWorkflowService } from '@app/biosamples/services/lab-workflow.service';
import { ResourceType } from '@app/core/model/resource-type';
import { V2CreateBiologicalSampleRequest } from '@bssh/ng-sdk/api/models/v2create-biological-sample-request';
import { Constants } from '@app/core/utilities/constants';

export interface IBioSamplesStore extends IResourceStore<IBioSamplesState> {
    loadCurrentBioSample(id: string, forceLoad: boolean): void;
    loadBioSamplesList(offset: number, limit: number, sortby: string, sortdir: string, forceLoad: boolean): void;
    updateSelectedBioSamples(updatedSelections: IBioSample[]): void;
    unloadCurrentBioSample(): void;
    uploadBiosampleWorkflow(preview: boolean, biosampleManifestFile: File): Observable<IV2ManifestImportResponse>;
    cancelLabRequeue(Type: string): void;
    canCancelLabRequeue(requeueType: string): boolean;
    updateSelectedBioSampleRequeue(updatedSelections: any);
    updateSelectedLibraryRequeue(updatedSelections: any);
    updateSelectedPoolRequeue(updatedSelections: any);
    canRequeueBiosample(): boolean;
    requeueBiosample(prepRequestsId: string, requestedAdditionalYield: number): void;
    createNew(params: V2CreateBiologicalSampleRequest): Observable<IBioSample>;
}

@Injectable({
    providedIn: 'root'
})
export class BioSamplesStore extends AbstractResourceStore<IBioSamplesState> implements IBioSamplesStore {

    private refreshSubject = new BehaviorSubject<boolean>(false);
    bioSamplesRefreshed$ = this.refreshSubject.asObservable();

    constructor(private basespaceApi: BasespaceService,
        private pageContextService: PageContextService,
        private toastrService: ToastrService,
        private biosampleUploadService: BiosampleWorkflowUploadService,
        private labWorkflowService: LabWorkflowService,
        private bsApiService: BsApiService) {
        super(['bioSamplesList', 'bioSamplesOffset', 'bioSamplesLimit', 'bioSamplesSortBy',
            'bioSamplesSortDir', 'selectedBioSamples', 'currentBioSample', 'bioSampleStateError',
            'currentUserId', 'selectedBioSampleRequeue', 'selectedLibraryRequeue', 'selectedPoolRequeue',
            'unlockBiosamplesProgress']);
    }

    // method to access @refreshSubject externally to refresh current view
    forceRefreshStore() {
        this.refreshSubject.next(true);
    }

    /**
     * Calls BSSH API to load biosample details and cache in the  store
     * @param id Id of the biosample to fetch. Will not fetch if the state's currentBioSample already matches this id.
     */
    loadCurrentBioSample(id: string, forceLoad: boolean = false) {

        const currentState = this.getState();
        if (currentState && currentState.currentBioSample && currentState.currentBioSample.Id === id && !forceLoad) {
            // if biosamle already set don't fetch, but dispatch current state if another call is made later
            this.dispatchCurrentState(currentState);
        } else {
            this.loadingSubject.next(true);

            // Subscribe to retrieve
            this.subs.sink = this.basespaceApi.GetV2BiosamplesId(
                { id, include: ['PrepRequests', 'Yields', 'FastqDatasetAggregationCheck', Constants.RelatedUserWithAccessParam] }).pipe(
                    // Do not make an additional API call if any additional subscribers have subscribed later on
                    shareReplay(1),
                    // Retry in case of HTTP errors
                    retryWhen(genericRetryWhen()),
                    // To avoid a Flash of content, maintain a delay
                    delay(observableEmitDelay),
                    // Stop loading in finalize.
                    finalize(() => this.loadingSubject.next(false))
                ).subscribe({
                    next: (v2BioSampleDetails) => {
                        this.setState({ currentBioSample: v2BioSampleDetails }, BioSampleStoreActions.LoadBioSamplesList);
                    },
                    error: error => this.handleError(error, () => ({ ...currentState, bioSampleStateError: error }))
                });
        }
    }

    /**
     * Unload current biosample data to avoid stale data displayed when enter another biosample details page
     */
    unloadCurrentBioSample() {
        this.setState({ currentBioSample: undefined });
    }

    /**
     * Calls BSSH API to load a list of biosamples and cache in the biosamples store as 'bioSamplesList'
     */
    loadBioSamplesList(offset: number = 0, limit: number = 50, sortby: string = 'DateModified',
        sortdir: string = 'Desc', forceLoad: boolean = false) {

        const currentState = this.getState();
        if (currentState && currentState.bioSamplesOffset === offset && currentState.bioSamplesLimit === limit
            && currentState.bioSamplesSortBy === sortby && currentState.bioSamplesSortDir === sortdir && !forceLoad) {

            // if same settings, use the list we already have cached
            this.dispatchCurrentState(currentState);
        } else {
            this.loadingSubject.next(true);

            // TODO: include sortby
            this.subs.sink = this.basespaceApi.GetV2Biosamples({ sortdir, offset, limit })
                .pipe(
                    // Retry in case of HTTP errors
                    retryWhen(genericRetryWhen()),

                    // To avoid a Flash of content, maintain a delay
                    delay(observableEmitDelay),

                    // Stop loading in finalize.
                    finalize(() => this.loadingSubject.next(false))
                ).subscribe({
                    next: (apiResponse: V2BiologicalSampleCompactList) => {
                        const bioSamplesList = apiResponse.Items;
                        this.setState({
                            bioSamplesList,
                            bioSamplesOffset: apiResponse.Paging.Offset,
                            bioSamplesLimit: apiResponse.Paging.Limit,
                            bioSamplesSortBy: apiResponse.Paging.SortBy,
                            bioSamplesSortDir: apiResponse.Paging.SortDir,
                            bioSampleStateError: null,
                            selectedBioSamples: null,
                            selectedBioSampleRequeue: null,
                            selectedLibraryRequeue: null,
                            selectedPoolRequeue: null,
                        }, BioSampleStoreActions.LoadBioSamplesList);
                        this.loadingSubject.next(false);
                    },
                    error: error => {
                        this.handleError(error, () => ({ ...this.getState(), bioSampleStateError: error }));
                        this.loadingSubject.next(false);
                    }
                });
        }
    }

    updateSelectedBioSamples(updatedSelections: IBioSample[]) {
        this.setState({ selectedBioSamples: updatedSelections }, BioSampleStoreActions.UpdateSelectedBioSamples);
    }

    updateSelectedBioSampleRequeue(updatedSelections: V2LabRequeueCompact[]) {
        this.setState({ selectedBioSampleRequeue: updatedSelections }, BioSampleStoreActions.UpdateSelectedRequeues);
    }

    updateSelectedLibraryRequeue(updatedSelections: V2LabRequeueCompact[], BioSample?: IBioSample) {
        this.setState({ selectedLibraryRequeue: updatedSelections }, BioSampleStoreActions.UpdateSelectedRequeues);
        if (BioSample) {
            this.setState({ currentBioSample: BioSample });
        }
    }

    updateSelectedPoolRequeue(updatedSelections: V2LabRequeueCompact[]) {
        this.setState({ selectedPoolRequeue: updatedSelections }, BioSampleStoreActions.UpdateSelectedRequeues);
    }

    getActiveRequeue(requeueType: string): any {
        switch (requeueType) {
            case BioSampleLabRequeues.NewLibrary:
                return isEmpty(this.getState().selectedBioSampleRequeue) ? null : this.getState().selectedBioSampleRequeue[0];
            case BioSampleLabRequeues.ExistingLibrary:
                return isEmpty(this.getState().selectedLibraryRequeue) ? null : this.getState().selectedLibraryRequeue[0];
            case BioSampleLabRequeues.ExistingPool:
                return isEmpty(this.getState().selectedPoolRequeue) ? null : this.getState().selectedPoolRequeue[0];
            default:
                return null;
        }

    }

    getActiveList(): IBioSample[] {
        const { currentBioSample, selectedBioSamples } = this.getState();

        if (this.pageContextService.isBioSampleMasterList() || this.pageContextService.isSubResourceBioSamplesList()) {
            return selectedBioSamples;
        } else if (this.pageContextService.isBioSampleDetails() && !isNullOrUndefined(currentBioSample)) {
            return [currentBioSample];
        } else if (this.pageContextService.isPoolDetails() && !isNullOrUndefined(selectedBioSamples)) {
                return selectedBioSamples;
        } else {
            return null;
        }
    }

    /**
     * Updates the biosample that is being modified in the list of biosamples based on id.
     * @param bioSample: bioSample that is being modified
     * @param bioSamplesList: list of bioSamples to update the bioSample that is being modified
     * @param propertyUpdate: property changed
     */
    private getUpdatedBioSamples(bioSample: IBioSample, bioSamples: IBioSample[], propertyUpdate: object): IBioSample[] {
        if (isNullOrUndefined(bioSamples)) {
            return bioSamples;
        }

        const index = bioSamples.findIndex(r => r.Id === bioSample.Id);
        if (index !== -1) {
            bioSamples[index] = Object.assign(bioSamples[index], propertyUpdate);
        }

        return bioSamples;
    }

    /**
     * set state based on page context. Update biosamplesList and selected biosamples if page context is
     * master list.
     * Updates biosamplesList, selected biosamples and the current bipsample if page context is
     * details page.
     * @param propertyChange: property that are changing
     * @param bioSamples: the biosamples acted upon
     * @param actionName: name of action based on service
     * @param error: error in case of partial success ( i.e in case of list of resources)
     * @param forceRefresh: whether to refresh biosamples list if action was performed from list page
     */
    private setStateOnSuccess(propertyChange: object, bioSamples: IBioSample[], actionName: BioSampleStoreActions,
        error: any = null, forceRefresh?: boolean) {
        const { bioSamplesList, selectedBioSamples, currentBioSample } = this.getState();

        let newCurrentBioSample = null;
        let newBioSamplesList = bioSamplesList;
        let newSelectedBioSamplesList = selectedBioSamples;

        if (this.pageContextService.isBioSampleMasterList()) {
            if (isNullOrUndefined(bioSamplesList) || isNullOrUndefined(selectedBioSamples) || selectedBioSamples.length === 0) {
                return;
            }

            bioSamples.forEach(bioSample => {
                newBioSamplesList = this.getUpdatedBioSamples(bioSample, bioSamplesList, propertyChange);
                newSelectedBioSamplesList = this.getUpdatedBioSamples(bioSample, selectedBioSamples, propertyChange);
            });
        }

        if (this.pageContextService.isBioSampleDetails()) {
            if (bioSamples.length === 1) {
                newCurrentBioSample = Object.assign(bioSamples[0], propertyChange);
            } else {
                newCurrentBioSample = currentBioSample;
            }
        }

        this.setState({
            bioSamplesList: newBioSamplesList,
            selectedBioSamples: newSelectedBioSamplesList,
            currentBioSample: newCurrentBioSample,
            bioSampleStateError: error,
        }, actionName);

        this.refreshSubject.next(forceRefresh);
    }

    /**
     * Upload biosample does not need to change the state of biosample store (does not modify currentBiosample or biosampleList)
     * neccessarily. So, this mehtod returns the observable instead of subscribing and setting state on success or failure.
     * Caller of the method can handle the observable
     * @param preview: whether upload is final or preview
     * @param biosampleManifestFile: biosample manifest file to be uploaded
     * @return Observable<IV2ManifestImportResponse>
     */
    uploadBiosampleWorkflow(preview: boolean, biosampleManifestFile: File): Observable<IV2ManifestImportResponse> {
        this.loadingSubject.next(true);
        return this.biosampleUploadService.uploadBiosampleWorkflow(biosampleManifestFile, preview).pipe(
            finalize(() => {
                this.loadingSubject.next(false);
            }));
    }


    // Use this on biosample details page
    canUnlockBiosample(): boolean {
        const biosamples = this.getActiveList();
        if (!biosamples || (biosamples.length !== 1)) {
            return false;
        }

        return biosamples[0].HasFastqDatasetsAggregatedFromMultipleSources === true &&
            biosamples[0].UserHasUnlockedBioSampleForAggregation === false;
    }

    // Use this on biosamples master list because we can't derive if biosample is locked or unlocked from biosamples list api
    canUnlockBiosamples(): boolean {
        const biosamples = this.getActiveList();
        return (!biosamples || biosamples.length < 1) ? false : true;
    }

    unlockBiosample(biosample: IBioSample): Observable<any> {
        const params: BasespaceService.PostV2BiosamplesIdAggregationParams = {
            id: biosample.Id,
            payload: {
                Locked: false
            }
        };

        return this.basespaceApi.PostV2BiosamplesIdAggregation(params).pipe(
            retryWhen(genericRetryWhen({maxRetryAttempts: 3})),
            delay(observableEmitDelay),
            catchError((error: Error) => {
                return of({ errorMessage: error.message, data: biosample });
            })
        );
    }

    unlock(): Observable<void> {
        this.setState({ unlockBiosamplesProgress: 0 });
        this.loadingSubject.next(true);

        const biosamples = this.getActiveList();

        const successUnlockedBiosamples: IBioSample[] = [];
        const failureUnlockedBioampleItems: { errorMessage: string; data: IBioSample }[] = [];

        let unlockBiosamples = of(null);
        const batchSize = 5;
        for(let startIndex = 0; startIndex <= biosamples.length; startIndex += batchSize) {
            const biosampleBatch = biosamples.slice(startIndex, startIndex + batchSize);
            unlockBiosamples = unlockBiosamples.pipe(
                switchMap(() => {
                    return forkJoin(
                        biosampleBatch.map((biosample: IBioSample) => {
                            return this.unlockBiosample(biosample).pipe(
                                tap((response: any) => {
                                    if(response.errorMessage) {
                                        failureUnlockedBioampleItems.push(response);
                                    } else {
                                        successUnlockedBiosamples.push(biosample);
                                    }
                                })
                            )
                        })
                    );
                }),
                tap(() => {
                    const unlockBiosamplesProgress = (startIndex + biosampleBatch.length) / biosamples.length;
                    this.setState({
                        unlockBiosamplesProgress: unlockBiosamplesProgress
                    });

                    if(unlockBiosamplesProgress >= 1 && failureUnlockedBioampleItems.length > 0) {
                        throw failureUnlockedBioampleItems;
                    }
                }),
            );
        }

        return unlockBiosamples
            .pipe(
                // To avoid a Flash of content, maintain a delay
                delay(observableEmitDelay),
                // Stop loading in finalize.
                finalize(() => {
                    this.loadingSubject.next(false);
                    if(successUnlockedBiosamples.length > 0) {
                        this.setStateOnSuccess(
                            { UserHasUnlockedBioSampleForAggregation: true },
                            successUnlockedBiosamples,
                            BioSampleStoreActions.UnlockBioSample
                        );
                        this.toastrService.success(ToastrMessages.BIOSAMPLES_UNLOCK.SUCCESS(successUnlockedBiosamples.length));
                    }
                }));
    }

    edit(newBioSampleName: string): void {
        if (!this.canEdit()) {
            // re-check, make no state change if edit biosample name not allowed.
            return;
        }

        this.loadingSubject.next(true);
        const activeBioSamples = this.getActiveList();

        this.subs.sink = this.editBioSampleName(newBioSampleName, activeBioSamples[0]).pipe(
            finalize(() => this.loadingSubject.next(false))
        ).subscribe({
            next: (apiResponse: IBioSample) => {
                this.setStateOnSuccess(
                    { BioSampleName: apiResponse.BioSampleName },
                    activeBioSamples, 
                    BioSampleStoreActions.EditBioSampleName, 
                    null, 
                    true
                );
            },
            error: error => {
                this.handleError(error, () => ({ ...this.getState(), bioSampleStateError: error }));
            }
        });

    }

    /**
     * edit biosample name
     * @param newBioSampleName: new name
     * @param bioSamples: the biosample acted upon
     */
    private editBioSampleName(newBioSampleName: string, bioSample: IBioSample): Observable<IBioSample> {

        if (!bioSample) {
            return throwError(ErrorMessages.BIOSAMPLE_EDIT.NO_BIOSAMPLE_SPECIFIED);
        }

        const params = {
            id: bioSample.Id,
            payload: {
                BioSampleName: newBioSampleName,
                DefaultProjectId: bioSample.DefaultProject.Id,
                SubjectId: (bioSample.Subject && bioSample.Subject.Id) ? bioSample.Subject.Id : '',
                ContainerName: bioSample.ContainerName,
                ContainerPosition: bioSample.ContainerPosition,
                Status: bioSample.Status
            }
        };
        return this.basespaceApi.PostV2BiosamplesId(params);
    }

    canEdit(): boolean {
        const activeBioSamples = this.getActiveList();
        if (!ArrayUtilities.isArrayOfSize(activeBioSamples, 1)) {
            return;
        }
        return this.isResourceOwner(activeBioSamples[0]);
    }

    createNew(params: V2CreateBiologicalSampleRequest): Observable<IBioSample> {
        this.loadingSubject.next(true);

        return this.basespaceApi.PostV2Biosamples(params).pipe(
            tap(() => {
                const currentState = this.getState();
                // Reload the current biosample list in order to fetch and load the newly created biosample into the store.
                this.loadBioSamplesList(
                    currentState.bioSamplesOffset,
                    currentState.bioSamplesLimit,
                    currentState.bioSamplesSortBy,
                    currentState.bioSamplesSortDir,
                    true
                );
                this.refreshSubject.next(true);
            }),
            finalize(() => this.loadingSubject.next(false))
        );
    }

    canCancelLabRequeue(requeueType: string): boolean {

        let requeuesCount: number = null;
        if (!isEmpty(this.getState().selectedBioSampleRequeue)) {
            requeuesCount++;
        }
        if (!isEmpty(this.getState().selectedLibraryRequeue)) {
            requeuesCount++;
        }
        if (!isEmpty(this.getState().selectedPoolRequeue)) {
            requeuesCount++;
        }

        // If more than 1 requeues type are selected return false
        if (isNullOrUndefined(requeuesCount) || requeuesCount > 1) {
            return false;
        }
        const resource = this.getActiveRequeue(requeueType);
        return !isEmpty(resource);
    }

    cancelLabRequeue(requeueType: string): void {
        let requeue;
        // cancel requeue page is a single selection page. getting first and only item
        if (requeueType === BioSampleLabRequeues.NewLibrary) {
            requeue = this.getState().selectedBioSampleRequeue[0];
        } else if (requeueType === BioSampleLabRequeues.ExistingLibrary) {
            requeue = this.getState().selectedLibraryRequeue[0];
        } else if (requeueType === BioSampleLabRequeues.ExistingPool) {
            requeue = this.getState().selectedPoolRequeue[0];
        }

        this.loadingSubject.next(true);

        this.subs.sink = this.bsApiService.cancelLabRequeue(requeue.Href).pipe(
            delay(observableEmitDelay),
            finalize(() => this.loadingSubject.next(false))
        ).subscribe({
            next: () => {
                this.toastrService.success(ToastrMessages.CANCEL_REQUEUE_SUCCESS_MESSAGE);
                this.setStateOnSuccess(null, [], BioSampleStoreActions.UpdateSelectedRequeues, null, true);
                this.updateSelectedBioSampleRequeue(undefined);
                this.updateSelectedLibraryRequeue(undefined);
                this.updateSelectedPoolRequeue(undefined);
            },
            error: (error) => {
                this.handleError(error, () => ({ ...this.getState(), bioSampleStateError: error }));
            }
        });

    }

    /**
* Checks if the currently selected bisample(s) can be requeued.
*/
    canRequeueBiosample(): boolean {
        const biosamples = this.getActiveList();

        if (!ArrayUtilities.isArrayOfSize(biosamples, 1)) {
            return false;
        }

        return this.isResourceOwner(biosamples[0]) && biosamples[0].PrepRequests && biosamples[0].Yields ? true : false;
    }

    requeueBiosample(prepRequestId: string, requestedAdditionalYield: number): void {
        this.loadingSubject.next(true);
        const biosamples = this.getActiveList();

        if (!ArrayUtilities.isArrayOfSize(biosamples, 1)) {
            return;
        }

        const payload: V2CreateNewLibraryLabRequeueRequest = {
            RequestedAdditionalYield: requestedAdditionalYield,
            LIMSId: null,
        };
        const params: BasespaceService.PostV2PreprequestsPreprequestidLabrequeuesParams = {
            preprequestid: prepRequestId.toString(), payload
        };

        this.subs.sink = this.basespaceApi.PostV2PreprequestsPreprequestidLabrequeues(params).pipe(
            // Retry in case of HTTP errors
            retryWhen(genericRetryWhen()),
            // To avoid a Flash of content, maintain a delay
            delay(observableEmitDelay),
            finalize(() => this.loadingSubject.next(false))
        ).subscribe({
            next: (apiResponses: V2LabRequeue) => {

                this.toastrService.success(ToastrMessages.LAB_REQUEUE_REQUESTED);
                this.setState({ bioSampleStateError: null });
            },
            error: error => {
                this.handleError(error, () => ({ ...this.getState(), bioSampleStateError: error }));
            }
        });
    }

    getRequeueInput(): INewLibraryRequeueInput {
        const biosamples = this.getActiveList();
        if (!ArrayUtilities.isArrayOfSize(biosamples, 1)) {
            return;
        }

        const labWorkflows = this.labWorkflowService.getSortedActiveLabWorkflows(biosamples[0].Yields, biosamples[0].PrepRequests);

        const requeue: INewLibraryRequeueInput = {
            biosample: biosamples[0],
            labWorkflows,
            selectedLabWorkflow: labWorkflows[0]
        };

        return requeue;
    }
    canEditDefaultProjectInfo(): boolean {
        const activeBiosamples = this.getActiveList();

        if (ArrayUtilities.isNullOrEmpty(activeBiosamples)) {
            return false;
        }

        return activeBiosamples.every(biosample => this.isResourceOwner(biosample));
    }

    EditDefaultProjectInfo(chosenProject: IProject): void {
        if (!chosenProject) {
            return;
        }

        this.loadingSubject.next(true);
        const activeBiosamples = this.getActiveList();
        const ids = activeBiosamples.map(b => b.Id);
        const payload: V2PostBioSamplesBulkUpdateRequest = {
            Ids: ids,
            DefaultProjectId: chosenProject.Id,
            ContainerPosition: '',
            ContainerName: ''
        };

        this.basespaceApi.PostV2BiosamplesBulkupdate(payload).pipe(
            finalize(() => this.loadingSubject.next(false))
        ).subscribe({
            next: (apiResponse: any) => {
                const res = apiResponse.Response;
                const totalSuccessCount = res.TotalSuccessCount;
                const totalFailureCount = res.TotalFailureCount;

                // handle successful updates
                const updatedBiosamples = [];
                if (totalSuccessCount > 0) {
                    res.SuccessItems.forEach((item) => {
                        const biosample = activeBiosamples.find(b => b.Id === item.Id);
                        updatedBiosamples.push(biosample);
                    });
                }

                // handle failed updates
                let updateError = null;
                if (totalFailureCount > 0) {
                    updateError = [];
                    res.FailureItems.forEach(item => {
                        updateError.push({
                            errorMessage: item.ErrorMessage,
                            data: item,
                        });
                        // toast failure
                        this.toastrService.error(ToastrMessages.EDIT_DEFAULT_PROJECT_ERROR(item.BioSampleName, item.ErrorMessage, item.ErrorCode));
                    });
                }

                this.setStateOnSuccess(
                    { DefaultProject: chosenProject },
                    updatedBiosamples,
                    BioSampleStoreActions.UpdateSelectedBioSamples,
                    updateError,
                    true
                );

                // toast success
                this.toastrService.success(ToastrMessages.EDIT_DEFAULT_PROJECT_SUCCESS(totalSuccessCount, chosenProject.Name));

            },
            error: (error) => {
                if (ids.length === 1) {
                    this.toastrService.error(ToastrMessages.EDIT_DEFAULT_PROJECT_ERROR(
                        activeBiosamples[0].BioSampleName, error.error.ErrorMessage, error.error.ErrorCode));
                } else {
                    this.toastrService.error(ToastrMessages.EDIT_DEFAULT_PROJECT_ERROR(null, error.error.ErrorMessage, error.error.ErrorCode));
                }
                this.handleError(error, () => ({ ...this.getState(), bioSampleStateError: error }));
            }

        });
    }

    getResourceType(): ResourceType {
        return ResourceType.BIO_SAMPLES;
    }
}
// The action-names for this store, for logging/tracking.
export enum BioSampleStoreActions {
    LoadBioSamplesList = 'LOAD_SAMPLES_LIST',
    UpdateSelectedBioSamples = 'UPDATE_SELECTED_SAMPLES',
    UploadBiosampleWorkflow = 'UPLOAD_BIOSAMPLE_WORKFLOW',
    UpdateSelectedRequeues = 'UPDATE_SELECTED_REQUEUES',
    UnlockBioSample = 'UNLOCK_BIOSAMPLE',
    EditBioSampleName = 'EDIT_NAME'
}

export enum BioSampleLabRequeues {
    NewLibrary = 'NewLibrary',
    ExistingLibrary = 'ExistingLibrary',
    ExistingPool = 'ExistingPool'
}