import { Injectable } from '@angular/core';
import { IApplication, IApplicationScreenshots } from '@app/core/model/applications/applications';
import { AgreementStatus, IUserAgreement } from '@app/core/model/user-agreements/user-agreements';
import { UserSessionType } from '@app/core/model/user/session-type';
import { IApiListResponseWrapper, IApiResponseWrapper, IListResponseWrapper } from '@app/core/model/v2-api/v2-api-wrappers';
import { genericRetryWhen, observableEmitDelay } from '@app/core/rxjsutils/rxjs-utilities';
import { UserSettingsService } from '@app/core/services/user/user-settings.service';
import { Constants } from '@app/core/utilities/constants';
import { BasespaceService, V2UserAgreementCompact, V2UserSetting } from '@bssh/ng-sdk';
import _ from 'lodash';
import { BehaviorSubject, combineLatest, Observable, of } from 'rxjs';
import { retryWhen, finalize, delay, filter, first, shareReplay, tap, switchMap } from 'rxjs/operators';
import { AbstractResourceStore } from '../resource/abstract.resource.store';
import { IResourceStore } from '../resource/resource.store';
import { UserSettingsStore } from '../usersettings/user-settings.store';
import { IAppsState } from './apps.state';
import environment from '@environments/environment';
import { UserRestrictionService } from '@app/core/services/user/user-restriction.service';
import { ApiApplicationUtilities } from '@app/core/utilities/api-application.utilities';


export interface IAppsStore extends IResourceStore<IAppsState> {
  loadCurrentApp(id: string, forceLoad: boolean): void;
  loadAppsList(offset: number, limit: number, sortby: string, sortdir: string, forceLoad: boolean): void;
  unloadCurrentApp(): void;
  toggleAppBookmark(app: IApplication): void;
}


@Injectable({
  providedIn: 'root'
})
export class AppsStore extends AbstractResourceStore<IAppsState> implements IAppsStore {

  // Separate loading observables to enable deffered or parallel rendering of app details page
  private currentAppLoadingSubject = new BehaviorSubject<boolean>(false);
  currentAppLoading$ = this.currentAppLoadingSubject.asObservable();

  private currentAppVersionsLoadingSubject = new BehaviorSubject<boolean>(false);
  currentAppVersionsLoading$ = this.currentAppVersionsLoadingSubject.asObservable();

  private currentAppScreenShotsLoadingSubject = new BehaviorSubject<boolean>(false);
  currentAppScreenShotsLoading$ = this.currentAppScreenShotsLoadingSubject.asObservable();

  private currentAppAgreementsLoadingSubject = new BehaviorSubject<boolean>(false);
  currentAppAgreementsLoading$ = this.currentAppAgreementsLoadingSubject.asObservable();

  constructor(
    private basespaceApi: BasespaceService,
    private userSettingsService: UserSettingsService,
    private userSettingsStore: UserSettingsStore,
    private userRestrictionService: UserRestrictionService
  ) {
    super(['appsStateError',
      'appsList',
      'appsOffset',
      'appsLimit',
      'appsSortBy',
      'appsSortDir',
      'appsTotalCount',
      'bookmarkedAppsList',
      'currentApp',
      'currentAppVersions',
      'currentAppScreenshots',
      'currentAppUserAgreements',
      'bookmarkedAppSlugs']);
  }

  /**
   * Loads Apps into the store by calling the Apps list API
   * @param offset Apps Offset (Default:0)
   * @param limit Apps Limit (Default:1000)
   * @param sortby Apps Sort by (Default:Name)
   * @param sortdir Apps Sort direction (Default:Asc)
   * @param forceLoad Apps Offset (Default:false)
   */
  loadAppsList(
    offset: number = 0,
    limit: number = 1000,
    sortby: 'DateCreated' | 'DatePublished' | 'Id' | 'Name' | 'VersionNumber' = 'Name',
    sortdir: string = Constants.ServerSortDirection.Asc,
    forceLoad: boolean = false): void {

    const currentState = this.getState();
    if (currentState && currentState.appsOffset === offset && currentState.appsLimit === limit
      && currentState.appsSortBy === sortby && currentState.appsSortDir === sortdir && !forceLoad) {
      this.dispatchCurrentState(currentState);
      return;
    }

    this.loadingSubject.next(true);
    // Load settings for the acting user(if they are not already loaded)
    // Needed to load the bookmarked apps for the user.
    const actingUserSettingsChanged$ = this.loadActingUserSettings();

    // Update displayed apps (and bookmarks) because different accounts have access to different apps
    this.subs.sink = actingUserSettingsChanged$.pipe(
      tap(() => this.loadingSubject.next(true)),
      switchMap(() => {
        return this.basespaceApi.GetV2Applications({
          offset, limit, sortby, sortdir, cwlactive: null,
          autolaunchableonruncompleteappsonly: null,
          allversions: null
        })
      }),
      retryWhen(genericRetryWhen()),
      delay(observableEmitDelay),
      finalize(() => this.loadingSubject.next(false))
    ).subscribe({
      next: (appsListResponse) => {

        // Need to add app logos and restrictions as they are omitted server side
        const appsList: IApplication[] = appsListResponse.Items.map((app) => {
          return { 
            ...app, 
            HrefLogo: this.getAppHrefLogo(app), 
            AppLaunchRestriction: this.userRestrictionService.isUserRestrictedFromApp(app) 
          }
        });

        const results = this.filterByBookmarkedApps(appsList);

        this.setState({
          appsList: results.appsList,
          appsOffset: appsListResponse.Paging.Offset,
          appsLimit: appsListResponse.Paging.Limit,
          appsSortBy: appsListResponse.Paging.SortBy,
          appsSortDir: appsListResponse.Paging.SortDir,
          appsTotalCount: appsListResponse.Paging.TotalCount,
          appsStateError: null,
          bookmarkedAppsList: results.bookmarkedApps,
          bookmarkedAppSlugs: results.bookmarkedAppSlugs
        }, AppsStoreActions.LoadAppsList);
      },
      error: error => this.handleError(error, () => ({ ...currentState, appsStateError: error }))
    }
    );
  }

  /**
   * Calls BSSH API to load the current context app into the store.
   * Also loads the app's versions, screenshots and agreements into the store
   * @param id The app's id
   * @param forceLoad Force load
   */
  loadCurrentApp(id: string, forceLoad: boolean = false): void {

    const currentState = this.getState();
    if (currentState && currentState.currentApp && currentState.currentApp.Id === id && !forceLoad) {
      this.dispatchCurrentState(currentState);
      return;
    }
    this.currentAppLoadingSubject.next(true);

    const actingUserSettingsChanged$ = this.loadActingUserSettings();
    const appByIdApiResponse$ = this.basespaceApi.GetV2ApplicationsId({ id });

    this.subs.sink = combineLatest([appByIdApiResponse$, actingUserSettingsChanged$]).pipe(
      retryWhen(genericRetryWhen()),
      delay(observableEmitDelay),
      first(),
      finalize(() => this.currentAppLoadingSubject.next(false))
    ).subscribe({
      next: ([app]: [IApplication, boolean]) => {
        // Need bookmarked app slugs to determine if the current app is bookmarked.
        const bookmarkedAppSlugs = this.getUserBookmarkedAppSlugsFromSettings();
        app.Bookmarked = this.isAppBookmarked(app, bookmarkedAppSlugs);
        app.HrefLogo = this.getAppHrefLogo(app);
        app.AppLaunchRestriction = this.userRestrictionService.isUserRestrictedFromApp(app);
        app.VersionLabel = ApiApplicationUtilities.getAppStatusVersionLabel(app);
        
        this.setState({ currentApp: app, bookmarkedAppSlugs }, AppsStoreActions.LoadCurrentApp);
      },
      error: error => this.handleError(error, () => ({ ...currentState, appsStateError: error }))
    });

    // Load App versions
    this.loadAppVersions(id);
    // Screenshots
    this.loadAppScreenshots(id);
    // Agreements
    this.loadAppAgreements(id);

  }

  /**
   * Removes the current context app from state.
   */
  unloadCurrentApp(): void {
    this.setState({
      currentApp: undefined,
      currentAppVersions: undefined,
      currentAppScreenshots: undefined,
      currentAppUserAgreements: undefined
    }, AppsStoreActions.UnLoadCurrentApp);
  }

  /**
   * Adds/removes current app from the list of user app Bookmarks, by calling the settings API, and updates state appropriately
   */
  toggleAppBookmark(app: IApplication): void {
    const { appsList, currentApp, bookmarkedAppSlugs } = this.getState();

    const isAppBookmarked = this.isAppBookmarked(app, bookmarkedAppSlugs);
    const updatedBookmarkedAppSlugs = bookmarkedAppSlugs.filter(slug => slug !== app.AppFamilySlug);

    if (!isAppBookmarked) {
      updatedBookmarkedAppSlugs.push(app.AppFamilySlug);
    }

    const results = appsList ? this.filterByBookmarkedApps(appsList, updatedBookmarkedAppSlugs) : null;

    // Update bookmark state in app context so update appears instant
    this.setState({
      bookmarkedAppSlugs: updatedBookmarkedAppSlugs,
      ...(results && { bookmarkedAppsList: results.bookmarkedApps, appsList: results.appsList }),
      ...(currentApp && {  currentApp: { ...app, Bookmarked: !isAppBookmarked } })
    });

    const appBookmarkSetting: V2UserSetting = {
      Id: null,
      Href: null,
      Name: UserSettingsService.UserAppBookmarksSettingsName,
      Type: 'string',
      Value: JSON.stringify({ apps: updatedBookmarkedAppSlugs }),
    };

    this.userSettingsService.setUserSettings(UserSessionType.LoggedInUser, [appBookmarkSetting]);
  }


  /**
   * Filters the complete apps list to return the book marked apps
   * @param completeAppsList The complete apps list
   */
  private filterByBookmarkedApps(completeAppsList: IApplication[], bookmarkedAppSlugs?: string[]): { 
    bookmarkedAppSlugs: string[], 
    bookmarkedApps: IApplication[], 
    appsList: IApplication[] 
  } {
    const updatedCompleteAppsList = completeAppsList.map((app: IApplication) => {
      return { ...app, Bookmarked: false }
    });

    const bookmarkedApps: IApplication[] = [];
    bookmarkedAppSlugs = bookmarkedAppSlugs ? bookmarkedAppSlugs : this.getUserBookmarkedAppSlugsFromSettings();
    if (!_.isEmpty(completeAppsList)) {
      if (!_.isEmpty(bookmarkedAppSlugs)) {
        updatedCompleteAppsList.forEach((app: IApplication) => {
          if (this.isAppBookmarked(app, bookmarkedAppSlugs)) {
            app.Bookmarked = true;
            bookmarkedApps.push(app);
          }
        });
      }
    }
    return { bookmarkedAppSlugs, bookmarkedApps, appsList: updatedCompleteAppsList };
  }

  /**
   * Loads versions for a given app into the app state
   * @param id The App Id
   */
  private loadAppVersions(id: string): void {

    const fetchData$ = this.basespaceApi.GetV2ApplicationsIdVersions({
      id,
      limit: 500,
      sortby: 'VersionNumber',
      sortdir: Constants.ServerSortDirection.Desc
    });

    const nextCallback = (apiResponse: IApiListResponseWrapper<IApplication>) => {
      const currentAppVersions = apiResponse.Response.Items;
      currentAppVersions.forEach((appVersion) => {
        appVersion.VersionLabel = ApiApplicationUtilities.getAppStatusVersionLabel(appVersion);
      });

      this.setState({ currentAppVersions: currentAppVersions }, AppsStoreActions.LoadCurrentAppVersions);
    };

    this.loadStateSlice(this.currentAppVersionsLoadingSubject, fetchData$, nextCallback, this.errorCallBack);
  }

  /**
   * Loads screen shot paths for an app
   * @param id The App Id
   */
  private loadAppScreenshots(id: string): void {

    const fetchData$ = this.basespaceApi.GetV2ApplicationsIdScreenshots({
      id
    });

    const nextCallback = (appScreenshotsResponse: IApiResponseWrapper<IApplicationScreenshots>) => {
      this.setState({ currentAppScreenshots: appScreenshotsResponse.Response.Items }, AppsStoreActions.LoadCurrentAppScreenshots);
    };

    this.loadStateSlice(this.currentAppScreenShotsLoadingSubject, fetchData$, nextCallback, this.errorCallBack);
  }

  /**
   * Loads User agreements for an app
   * @param id The App Id
   */
  private loadAppAgreements(id: string): void {

    const fetchData$ = this.basespaceApi.GetV2Useragreements({
      applicationid: id,
      include: [AgreementStatus.PENDING]
    });

    const nextCallback = (agreementsResponse: IListResponseWrapper<IUserAgreement>) => {
      this.setState({ currentAppUserAgreements: agreementsResponse.Items }, AppsStoreActions.LoadCurrentAppAgreements);
    };

    this.loadStateSlice(this.currentAppAgreementsLoadingSubject, fetchData$, nextCallback, this.errorCallBack);
  }

  public acceptUserAgreement(userAgreement: IUserAgreement): Observable<V2UserAgreementCompact> {
    this.loadingSubject.next(true);

    return this.basespaceApi.PostV2Useragreements({ AgreementId: userAgreement.Agreement.Id.toString() }).pipe(
      shareReplay(1),
      retryWhen(genericRetryWhen()),
      tap(() => {
        const { currentApp} = this.getState();
        this.loadAppAgreements(currentApp.Id);
      }),
      finalize(() => {
        this.loadingSubject.next(false)
      })
    );
  }

  /**
   * Gets the user bookmarked app slugs
   */
  private getUserBookmarkedAppSlugsFromSettings(): string[] {
    const setting = this.userSettingsService.getUserSetting(UserSessionType.LoggedInUser,
      UserSettingsService.UserAppBookmarksSettingsName);
    return setting ? JSON.parse(setting.Value.toString()).apps : [];
  }


  /**
   * Loads the settings in the settings store for the acting user.
   */
  private loadActingUserSettings(): Observable<boolean> {
    this.userSettingsStore.loadUserSettings(UserSessionType.ActingUser);
    return this.userSettingsStore.actingInUserSettingsChanged$.pipe(filter(s => s === true));
  }

  /**
   * Checks whether an app was bookmarked
   * @param app The app to verify
   * @param bookmarkedAppSlugs The current list of bookmarked app slugs
   */
  private isAppBookmarked(app: IApplication, bookmarkedAppSlugs: string[]): boolean {
    return app && bookmarkedAppSlugs &&
      bookmarkedAppSlugs.findIndex(appSlug => appSlug === app.AppFamilySlug) > -1;
  }

  private getAppHrefLogo(app: IApplication): string {
    const { Id } = app;
    return `${environment.apiUrl}/v1pre3/applications/${Id}/icon/?ImageSize=large`;
  }

  private errorCallBack = error => this.handleError(error, () => ({ ...this.getState(), appsStateError: error }));
}

// The action-names for this store, for logging/tracking.
export enum AppsStoreActions {
  LoadAppsList = 'LOAD_APPS_LIST',
  LoadCurrentApp = 'LOAD_CURRENT_APP',
  LoadCurrentAppVersions = 'LOAD_CURRENT_APP_VERSIONS',
  LoadCurrentAppScreenshots = 'LOAD_CURRENT_APP_SCREENSHOTS',
  LoadCurrentAppAgreements = 'LOAD_CURRENT_APP_AGREEMENTS',
  UnLoadCurrentApp = 'UNLOAD_CURRENT_APP',
}
