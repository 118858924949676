import { FilterMethod, ITableDatasource } from '@app/core/data-table/table-datasource';
import { ISearchRequest } from '@app/core/model/search/search-request';
import { ISearchResultItem } from '@app/core/model/search/search-result-item';
import { IListResponseWrapper } from '@app/core/model/v2-api/v2-api-wrappers';
import { ISearchResourceConfig } from '@app/core/services/resource-dictionary/search-resource-dictionary-types';
import { ISearchFilter } from '@app/core/services/search/resource-search/search-query-types.service';
import { SearchQueryService } from '@app/core/services/search/resource-search/search-query.service';
import { Constants } from '@app/core/utilities/constants';
import { StringUtilities } from '@app/core/utilities/string-utilities';
import { IServerSideRequestOptions } from '@bssh/comp-lib';
import environment from '@environments/environment';
import { GridOptions, IServerSideGetRowsRequest } from 'ag-grid-community';
import _, { cloneDeep, forEach } from 'lodash';
import { isNullOrUndefined } from 'util';

export interface ISearchTableDataSource extends ITableDatasource<ISearchRequest, IListResponseWrapper<ISearchResultItem>> {
  datasourceOptions: ISearchDataSourceOptions;
  rawTextFilterProcessing: (filterValue: string, gridOptions: GridOptions) => void;
  rawTextFilterColumnName: string;
}
export class SearchDataSource implements ISearchTableDataSource {

  public response: IListResponseWrapper<ISearchResultItem> = null;

  constructor(
    private searchQueryService: SearchQueryService,
    private resourceConfig: ISearchResourceConfig,
    private searchDataSourceOptions?: ISearchDataSourceOptions
  ) {
    this.setDefaultParams();
  }

  get requestOptions(): IServerSideRequestOptions {
    return {
      origin: this.origin,
      path: this.path,
      params: this.defaultParams,
      preRequestParamsProcessing: (params, rowsRequest) => this.getUpdatedParams(params, rowsRequest),
      postSuccessRequestCallback: (params, rowsRequest, response) => this.getRowsRequest(params, rowsRequest, response),
      postErrorRequestCallback: (params, rowsRequest, err) => {
        console.error(err);
      }
    };
  }

  get datasourceOptions(): ISearchDataSourceOptions {
    return {
      useStrictPaging: this.useStrictPaging,
      defaultSortby: this.sortBy,
      defaultSortDir: this.sortDir,
      defaultLimit: this.limit,
      scope: this.scope,
      pagination: this.pagination
    };
  }

  get rawTextFilterColumnName(): string {
    return null;
  }

  origin = StringUtilities.trimTrailingSlash(environment.apiEndpoint);
  path = 'search';
  defaultParams: ISearchRequest;

  private scope = '';
  private offset = 0;
  public pagination = true;

  // Defaults:
  public limit = 100;
  private sortBy = Constants.ServerSortFields.ModifiedOn;
  private sortDir = Constants.ServerSortDirection.Desc;
  private useStrictPaging = false;
  private fileExtension: IFileExtensionFilter = null;

  private _rawTextQuery: string;
  private _searchFilters: ISearchFilter[] = [];
  private _prevQueryString: string = null;

  // Todo: handle filter params using ISearchFilter, rawTextQuery and the `applyFilterParams` method, as the need arises.
  // For now, project chooser can only be filtered by free search text box
  // tslint:disable-next-line: member-ordering
  filterMethods: { [field: string]: FilterMethod<any>; } = {};

  rawTextFilterProcessing = (filterValue: string, gridOptions: GridOptions) => {
    if (!isNullOrUndefined(this.rawTextFilterColumnName)) {
      const filterInstance = gridOptions.api.getFilterInstance(this.rawTextFilterColumnName);
      if (typeof filterValue === "string") {
        // Need to set a filter model to indicate what to filter by.
        // The display name of the filter model indicates the field that was choosen for filtering.
        // For raw text searches, it will just be 'Name'.
        // The 'preRequestParamsProcessing' will handle building the appropriate query string for api filtering.
        filterInstance.setModel({
          displayName: this.rawTextFilterColumnName,
          filterValue
        });
      } else {
        filterInstance.setModel(null);
      }
      gridOptions.api.onFilterChanged();
    }
  }

  applyPaginationParams(params: ISearchRequest, startRow: number) {
    const updatedParams = cloneDeep(params);
    updatedParams.offset = startRow;
    if (this.useStrictPaging && updatedParams.sortBy === Constants.ServerSortFields.Score) {
      updatedParams.pagingStyle = Constants.PagingStyles.Strict;
    }
    return updatedParams;
  }

  applySortParams(params: ISearchRequest, sortModel: { colId: string, sort: string }[]) {
    const updatedParams = cloneDeep(params);
    const sortRequest = sortModel[0];
    updatedParams.sortBy = sortRequest.colId;
    updatedParams.sortDir = sortRequest.sort;
    return updatedParams;
  }

  applyFilterParams(params: ISearchRequest, filterModel: any) {
    const updatedParams = cloneDeep(params);
    forEach(filterModel, (filterObj, field) => this.filterMethods[field](updatedParams, filterObj));
    return updatedParams;
  }

  getUpdatedParams(params: ISearchRequest, rowsRequest: IServerSideGetRowsRequest) {
    const { startRow, sortModel, filterModel } = rowsRequest;
    // todo: refactor using the 'filterMethods' approach.
    if (!_.isEmpty(filterModel) && !isNullOrUndefined(this.rawTextFilterColumnName)) {
      this._rawTextQuery = _.isEmpty(filterModel) ? '' : filterModel[this.rawTextFilterColumnName].value;
      // Bypass condition to set initial raw query
    } else {
      this._rawTextQuery =  this._rawTextQuery ? this._rawTextQuery : '';
    }

    let updatedParams = cloneDeep(params);
    // sorting params
    if (!_.isEmpty(rowsRequest.sortModel)) {
      updatedParams = this.applySortParams(updatedParams, sortModel);
    }
    // Search query string params
    updatedParams = this.setQueryString(updatedParams);
    // Sort by score when it is a raw text search
    if (!_.isEmpty(this._rawTextQuery)) {
      updatedParams.sortBy = Constants.ServerSortFields.Score;
    } else if (_.isEmpty(updatedParams.sortBy)) {
      updatedParams.sortBy = this.sortBy;
    }
    if (_.isEmpty(updatedParams.sortDir)) {
      updatedParams.sortDir = this.sortDir;
    }

    // Paging params
    updatedParams = this.applyPaginationParams(updatedParams, startRow);

    // Scope i.e. projects, biosamples etc.
    updatedParams.scope = this.resourceConfig.searchScope;
    return updatedParams;
  }

  getRowsRequest(params: ISearchRequest, rowsRequest: IServerSideGetRowsRequest, response: IListResponseWrapper<ISearchResultItem>) {
    this.response = response;
    return {
      ...rowsRequest,
      rowData: response.Items,
      startRow: response.Paging.Offset,
      lastRow: this.pagination ? response.Paging.TotalCount : response.Paging.Offset + response.Items.length
      /*
      Why this 'lastRow' hack is necessary

      When pagination is turned on:
      lastRow parameter is used to determine if there should be a next page arrow enabled so the user
      has the ability to load the next set of rows (this makes sense)

      When pagination is turned off:
      grid will attempt to display all rows at once. What this means is that it will use the 'lastRow' value to determine
      how many rows it should render, even if it doesn't have data beyond what's found in the 'rowData' value
      So you'll end up with a bunch of empty rows render
      */
    };
  }

  setDefaultRawTextQuery(query: string): void {
    this._rawTextQuery = query;
  }


  setQueryString(params: ISearchRequest): ISearchRequest {
    const updatedParams = cloneDeep(params);
    const queryString = this.searchQueryService.buildQueryString(
      this.resourceConfig.baseQuery,
      this._rawTextQuery,
      this._searchFilters,
      this.resourceConfig
    );
    if (this._prevQueryString != null && queryString !== this._prevQueryString) {
      // reset the offset if the query has changed
      updatedParams.offset = 0;
    }
    this._prevQueryString = queryString;
    updatedParams.query = queryString;
    return updatedParams;
  }

  private setDefaultParams() {
    this.limit = _.get(this.searchDataSourceOptions, 'defaultLimit', this.limit);
    this.sortBy = _.get(this.searchDataSourceOptions, 'defaultSortby', this.sortBy);
    this.sortDir = _.get(this.searchDataSourceOptions, 'defaultSortDir', this.sortDir);
    this.useStrictPaging = _.get(this.searchDataSourceOptions, 'useStrictPaging', this.useStrictPaging);
    this.scope = _.get(this.searchDataSourceOptions, 'scope', this.resourceConfig.searchScope);
    this.pagination = _.get(this.searchDataSourceOptions, 'pagination', this.pagination);
    this.fileExtension = _.get(this.searchDataSourceOptions, 'fileExtension', this.fileExtension);

    const defaultRawTextQuery = this.searchDataSourceOptions.defaultRawTextQuery;
    if(defaultRawTextQuery) {
      this.setDefaultRawTextQuery(defaultRawTextQuery);
    }

    this.defaultParams = {
      offset: this.offset,
      limit: this.limit,
      sortDir: this.sortDir,
      sortBy: this.sortBy,
      query: '',
      scope: this.scope,
      pagination: this.pagination
    };

    if (this.fileExtension && this.fileExtension.value) {
      if (this.fileExtension.value[0] !== '.') {
        this.fileExtension.value = `.${this.fileExtension.value}`;
      }
      this._searchFilters.push({
        facets: ['fileextension'],
        currentChoice: {
          value: this.fileExtension.value,
          label: this.fileExtension.value,
          strict: this.fileExtension.strict
        }
      })
    }
  }
}


export interface ISearchDataSourceOptions {
  useStrictPaging?: boolean;
  defaultSortby?: string;
  defaultSortDir?: string;
  defaultLimit?: number;
  defaultRawTextQuery?: string;
  scope?: string;
  pagination?: boolean;
  fileExtension?: IFileExtensionFilter;
}

export interface IFileExtensionFilter {
  value: string;
  strict?: boolean;
}
