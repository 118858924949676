<div class="step-log-container">
  <ngx-smart-modal #analysisStepLogs
    [identifier]="modalType"
    [title]="title"
    [closable]="true"
    [escapable]="true"
    [dismissible]="true"
    [showCloseButton]="false"
    [confirmButtonText]="confirmButtonText"
    [disableConfirmButton]="false"
  >
    <div class="modal-content-parent">
      <div class="modal-content-steps-list">
        <ng-container *ngIf="!isLoadingSteps">
          <table>
            <!-- Not showing table headers before loading as col width is dynamic depending on content, if show first will see layout shift -->
            <tr>
              <th class="p-4 rad-typography-font-weight--semiBold">Step Name</th>
              <th class="p-4 rad-typography-font-weight--semiBold min-w-18">End Time</th>
              <th class="p-4 rad-typography-font-weight--semiBold">StdOut</th>
              <th class="p-4 rad-typography-font-weight--semiBold">StdErr</th>
              <th class="py-4 pl-4 rad-typography-font-weight--semiBold">More details</th>
            </tr>
              <tr *ngFor="let step of icaSteps">
                <td class="p-4">{{ step.Name }}</td>
                <td class="p-4">{{ step.EndTime }}</td>
                <td class="p-4">
                  <span *ngIf="step.StdOutHref; else noStdOutHref">
                    <a class="preview-ica-log-link" href="" (click)="$event.preventDefault(); onLogFileClicked(step, 'StdOut')">
                      Preview
                    </a>
                  </span>
                  <ng-template #noStdOutHref>-</ng-template>
                </td>
                <td class="p-4">
                  <span *ngIf="step.StdErrHref; else noStdErrHref">
                    <a class="preview-ica-log-link" href="" (click)="$event.preventDefault(); onLogFileClicked(step, 'StdErr')">
                      Preview
                    </a>
                  </span>
                  <ng-template #noStdErrHref>-</ng-template>
                </td>
                <td class="py-4 pl-4">
                  {{ generateStepDetails(step) }}
                </td>
              </tr>
          </table>
        </ng-container>
        <div *ngIf="stepsError">
          <lib-error-alert-message [errorMsg]="stepsError">
          </lib-error-alert-message>
        </div>
        <div *ngIf="!isLoadingSteps && !stepsError && (!icaSteps || icaSteps.length === 0)" class="m-4">
          <lib-error-alert-message alertMsg="For this AppSession, there are no failed step logs found in ICA.">
          </lib-error-alert-message>
        </div>
        <lib-progress #logStepsProgressBar
                      id="log-steps-progress-bar"
                      [loader]="true"
                      [spinner]="false"
                      [spinnerPosition]="'center'"
                      [hidden]="!isLoadingSteps">
        </lib-progress>
      </div>

      <div class="divider"></div>

      <div class="modal-content-preview-title">
        <div class="mt-6 mb-4" *ngIf="previewTitle">
          <h3 class="rad-typography-font-weight--semiBold">{{ previewTitle }}</h3>
          <p class="my-3">{{ previewSubtitle || '-' }}</p>
        </div>
      </div>

      <div *ngIf="!isLoadingLogContent" class="modal-content-child">
        <div class="file-preview__container" *ngIf="previewContent">
          <div class="file-preview__content">
            <div class="file-preview__content--scrollable">
              <pre>{{ previewContent }}</pre>
            </div>
          </div>
        </div>
        <div *ngIf="!previewTitle && !previewContent" class="h-50 d-flex justify-content-center align-items-center">
          <span>No preview selected yet</span>
        </div>
        <div *ngIf="previewError">
          <lib-error-alert-message [errorMsg]="previewError">
          </lib-error-alert-message>
        </div>
      </div>
      <div [hidden]="!isLoadingLogContent" class="modal-content-child">
        <div class="file-preview__container">
          <div class="file-preview__content">
            <lib-progress #logPreviewProgressBar
                          id="log-preview-progress-bar"
                          [loader]="true"
                          [spinner]="false"
                          [spinnerPosition]="'center'">
            </lib-progress>
          </div>
        </div>
      </div>
    </div>
  </ngx-smart-modal>
</div>
