import { Injectable } from "@angular/core";
import { ApplicationCategory } from "../model/applications/application-category";
import { IAppSession } from "../model/appSessions/appSession";
import { ApplicationFeatures } from "../model/applications/application-features";
import { IApplication, IApplicationClassification } from "../model/applications/applications";
import { AppStatus } from "../model/applications/application-status";

export class ApiApplicationUtilities {
  constructor() { }

  static isWes(appsession: IAppSession): boolean {
    const category = this.getCategory(appsession);
    return this.isWesCategory(category);
  }

  static isWesCategory(category: string): boolean {
    return category === ApplicationCategory.WES;
  }

  static isIca(appsession: IAppSession): boolean {
    const category = this.getCategory(appsession);
    return this.isIcaCategory(category);
  }

  static isIcaCategory(category: string): boolean {
    return [
      ApplicationCategory.ICA_ANALYSIS_TRACKING, 
      ApplicationCategory.ICA_PIPELINE,
      ApplicationCategory.ICA_WORKFLOW_SESSION_TRACKING
    ].includes(category);
  }

  static isWesOrIcaCategory(category: string): boolean {
    return this.isWesCategory(category) || this.isIcaCategory(category);
  }

  static getCategory(appsession: IAppSession): string {
    return appsession && appsession.Application && appsession.Application.Category;
  }

  static isHtmlReportEnabled(appsession: IAppSession): boolean {
    return (appsession.Application.Features.indexOf(ApplicationFeatures.ENABLE_HTML_REPORTS) > -1);
  }

  static getClassificationDescription(classificationName: string, appClassifications: IApplicationClassification[]): string {
    if(appClassifications) {
      const foundClassification = appClassifications.find((classification) => classification.Name === classificationName);
      if(foundClassification) {
        return foundClassification.Description;
      }
    }
    return classificationName;
  }

  static getAppStatusVersionLabel(application: IApplication): string {
    switch(application.PublishStatus) {
      case AppStatus.DEVELOPMENT:
      case AppStatus.INITIALIZING:
      case AppStatus.BETA:
      case AppStatus.TESTING:
      case AppStatus.DISABLED:
      case AppStatus.DEPRECATED:
        return`${application.VersionNumber} (${application.PublishStatus})`;
      case AppStatus.DOMAIN_PUBLISHED:
        return`${application.VersionNumber} (Domain published)`;
      // We don't display status of 'Published' because it's default/most common
      case AppStatus.PUBLISHED:
      default:
        return application.VersionNumber;
    }
  }
}
