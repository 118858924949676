import { Injectable } from '@angular/core';
import _ from 'lodash';
import { ISearchResourceConfig } from '../../resource-dictionary/search-resource-dictionary-types';
import { DatePipe } from '@angular/common';
import { ISearchFilter } from './search-query-types.service';

export interface ISearchQueryService {
    buildQueryByIdString(resourceId: string): string;
    buildQueryString(baseQuery?: string, rawTextQuery?: string, msearchFilters?: any[], resourceConfig?: ISearchResourceConfig): string;
    restrictQueryToNameField(rawQuery: string, resourceConfig: ISearchResourceConfig): string;
    excludeHiddenFiles(): string;
}

/**
 * Manages search query formatting necessary to interact with `v2/search` bssh api
 * Angular 1 Code base reference: Tang.Modules/Directives/Search/searchQuery.service.ts
 */
@Injectable({
    providedIn: 'root'
})
export class SearchQueryService implements ISearchQueryService {

    // Not all methods are used currently. Ported over the service as is from the Angular 1 code base.
    // Will be used once we add more search functionality on the A8 Site.
    private static wildcard = '*';
    private static andDelimiter = ' AND ';
    private static orDelimiter = ' OR ';
    // private static assigner = ":";
    private searchOperatorRegex: RegExp = /\*|!|:|\sand\s|\sor\s|\snot\s|~|\^|\?|\\|\||\(|\[|\{|\"/i;

    constructor(private datePipe: DatePipe) {
    }

    /**
     * Return a query string that looks for an entity with the specified ID.
     */
    public buildQueryByIdString(resourceId: string): string {
        return '(id:' + resourceId + ')';
    }

    /**
     * Builds a Elastic search specific query string that can be passed in the `v2/search` api request params
     * @param baseQuery The base query for the resource
     * @param rawTextQuery Raw text query
     * @param searchFilters Search Filters
     * @param resourceConfig Config
     */
    public buildQueryString(
        baseQuery: string = '',
        rawTextQuery: string = '',
        searchFilters: ISearchFilter[] = [],
        resourceConfig?: ISearchResourceConfig
    ): string {
        let finalQueryString;
        let structuredQueryString = '';

        const isAlreadyStructured = rawTextQuery.search(this.searchOperatorRegex) > -1;

        if (isAlreadyStructured) {
            finalQueryString = rawTextQuery;
        } else {
            rawTextQuery = rawTextQuery.trim();
            if (resourceConfig) {
                finalQueryString = rawTextQuery ? this.restrictQueryToNameField(rawTextQuery, resourceConfig) : SearchQueryService.wildcard;
            } else {
                finalQueryString = rawTextQuery ? rawTextQuery : SearchQueryService.wildcard;
            }
        }

        // tslint:disable-next-line: prefer-for-of
        for (let i = 0; i < searchFilters.length; i++) {
            const filter: ISearchFilter = searchFilters[i];
            const queryParam = this.buildQueryForFacets(filter);
            if (structuredQueryString) {
                structuredQueryString = [structuredQueryString, queryParam].join(SearchQueryService.andDelimiter);
            } else {
                structuredQueryString = queryParam;
            }
        }

        if (structuredQueryString) {
            finalQueryString = [finalQueryString, structuredQueryString].join(SearchQueryService.andDelimiter);
        }

        if (baseQuery) {
            finalQueryString = '(' + [finalQueryString, baseQuery].join(')' + SearchQueryService.andDelimiter + '(') + ')';
        }

        if (resourceConfig && resourceConfig.baseQuery) {
            finalQueryString = '(' + [finalQueryString, resourceConfig.baseQuery].join(')' + SearchQueryService.andDelimiter + '(') + ')';
        }
        return finalQueryString;
    }

    public excludeHiddenFiles(): string {
        const filteredDate = this.datePipe.transform(this.getTime(), 'yyyy-MM-ddTHH:mm:ss');
        return `+AND+-(path:%27indexes//data*%27)\
              +AND+-(path:%27coverage//data*%27)\
              +AND+datemodified:[*+TO+${filteredDate}]\
              +AND+filestatus:UploadComplete\
              +AND+size:[1+TO+*]`;
    }

    private buildQueryForFacets(filter: ISearchFilter): string {
        const queryBits = _.map(filter.facets, (facet) => {
            let processedFacet = `(${facet}:${filter.currentChoice.value})`;
            if (facet === 'fileextension') {
                // by default, the 'strict' flag is off and the search result will include zipped files (ending with .gz).
                // e.g. fileextension = '.csv', search result will include files that end with '.csv.gz' as well.
                // add the 'strict' flag to exclude .gz files
                if (filter.currentChoice.strict) {
                    processedFacet = `(${processedFacet})`;
                } else {
                    processedFacet = `(${processedFacet} OR ((name:${filter.currentChoice.value}) AND (fileextension:\\.gz)))`;
                }
            }
            return processedFacet;
        });
        return queryBits.length === 1 ? queryBits[0] : '(' + queryBits.join(' OR ') + ')';
    }

    private toUTCDate(date: Date) {
        // tslint:disable-next-line:no-unnecessary-local-variable
        const utc = new Date(
            date.getUTCFullYear(),
            date.getUTCMonth(),
            date.getUTCDate(),
            date.getUTCHours(),
            date.getUTCMinutes(),
            date.getUTCSeconds()
        );
        return utc;
    }

    private millisToUTCDate(millis: number) {
        return this.toUTCDate(new Date(millis));
    }

    private getTime() {
        const date = new Date();
        return this.millisToUTCDate(date.getTime()).getTime();
    }

    public restrictQueryToNameField(rawQuery: string, resourceConfig: ISearchResourceConfig): string {
        if (resourceConfig.searchObjectName && resourceConfig.searchProperties && resourceConfig.searchProperties.length === 1) {
            // Build a query like Project.Name:"ABC"
            const prefix = resourceConfig.searchObjectName + '.' + resourceConfig.searchProperties[0] + ':';
            return prefix + '"' + rawQuery + '"';
        } else if (resourceConfig.searchObjectName && resourceConfig.searchProperties) {
            // Build a query like (Run.ExperimentName:"ABC" OR Run.FlowcellBarcode:"ABC")
            const propertyQueries = _.map(resourceConfig.searchProperties, (key) => {
                return `${resourceConfig.searchObjectName}.${key}:"${rawQuery}"`;
            });
            return '(' + propertyQueries.join(' OR ') + ')';
        } else {
            return rawQuery;
        }
    }
}
