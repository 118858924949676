
/* Constant file for all possible user subscription plans.
 * TODO: does this cover all the plans? Include any missing.
 */
export class SubscriptionPlan {
    public static readonly FREE_TRIAL = 'FreeTrial';
    public static readonly BASIC = 'Basic';
    public static readonly PROFESSIONAL = 'Professional';
    public static readonly ENTERPRISE = 'Enterprise';
    public static readonly ICA = 'Ica';
    public static readonly NONE = 'None';
}
