import { Component, OnInit, AfterViewInit, ChangeDetectorRef } from '@angular/core';
import { BaseModalComponent } from '../../base-modal/base-modal.component';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { NgxSmartModalService, ToastrService, NgxSmartModalComponent } from '@bssh/comp-lib';
import { IPrepTabLibraryPrepKit, ICreatePrepTabLibraryPrepKitRequest } from '@app/core/model/settings/prep-tab-library-prep-kits';
import { ICreatePrepTabLibraryPrepKitModalOutput } from '../../model/action-modal';
import { PrepTabLibraryPrepKitsMapperService, ValidReadTypes, ValidIndexingStrategies } from '@app/core/services/mapper/prep-tab-library-prep-kits-mapper.service';
import environment from '@environments/environment';
import { HttpClient } from '@angular/common/http';
import { isEmpty } from 'lodash';

const V1PRE3LIBRARYPREPKITURL: string = 'v1pre3/LibraryPrepKits';

@Component({
  selector: 'prep-tab-library-prep-kit-modal',
  templateUrl: './prep-tab-library-prep-kit-modal.component.html',
  styleUrls: ['./prep-tab-library-prep-kit-modal.component.scss']
})
export class PrepTabLibraryPrepKitModalComponent extends BaseModalComponent implements OnInit, AfterViewInit {
  public modalType = 'prepTabLibraryPrepKit';
  // Modal Text
  public title = 'Custom Library Prep Kit';
  public closeButtonText = 'Cancel';
  public confirmButtonText = 'Create New Kit';

  // Error message
  public errorMessage: string;

  // LPK Form validation rules
  public ValidationRule = {
    name: {
      pattern: new RegExp(/^[a-zA-Z0-9_\-\s]+$/),
      maxLength: 50
    },
    integer: {
      pattern: new RegExp(/^(0|[1-9]\d*)$/)
    },
    minReadLengths: 0,
    maxReadLengths: 1000
  }

  // Initial data
  public readTypeOptions = this.mapper.mapReadTypes(ValidReadTypes.values);
  public indexStrategies = this.mapper.mapPrepTabIndexReads(ValidIndexingStrategies.values);
  // If only no-index strategy is selected
  public onlyNoIndexReadTypeSelected = false;

  public selectedCSVFile: string = "";

  constructor(private mapper: PrepTabLibraryPrepKitsMapperService = null,
    public ngxSmartModalService: NgxSmartModalService,
    private httpClient: HttpClient,
    private toastr: ToastrService) {
    super(ngxSmartModalService);
  }

  // Initialize form with validations
  public lpkFormGroup: FormGroup = new FormGroup({
    lpkName: new FormControl('', [Validators.required,
    Validators.pattern(this.ValidationRule.name.pattern),
    Validators.maxLength(this.ValidationRule.name.maxLength)]),
    readType: new FormControl(ValidReadTypes.values.slice(0), [Validators.required]),
    indexStrategy: new FormControl(ValidIndexingStrategies.values.slice(0), [Validators.required]),
    defaultReadCycle1: new FormControl('', [Validators.required,
    Validators.pattern(this.ValidationRule.integer.pattern),
    Validators.max(this.ValidationRule.maxReadLengths),
    Validators.min(this.ValidationRule.minReadLengths)]),
    defaultReadCycle2: new FormControl('', [Validators.required,
    Validators.pattern(this.ValidationRule.integer.pattern),
    Validators.max(this.ValidationRule.maxReadLengths),
    Validators.min(this.ValidationRule.minReadLengths)]),
    csvFile: new FormControl(null, [Validators.required])
  });

  get lpkName() { return this.lpkFormGroup ? this.lpkFormGroup.get('lpkName') : null; }
  get readType() { return this.lpkFormGroup ? this.lpkFormGroup.get('readType') : null; }
  get indexStrategy() { return this.lpkFormGroup ? this.lpkFormGroup.get('indexStrategy') : null; }
  get defaultReadCycle1() { return this.lpkFormGroup ? this.lpkFormGroup.get('defaultReadCycle1') : null; }
  get defaultReadCycle2() { return this.lpkFormGroup ? this.lpkFormGroup.get('defaultReadCycle2') : null; }
  get csvFile() { return this.lpkFormGroup ? this.lpkFormGroup.get('csvFile') : null; }

  public getLPKNameErrorMessage() {
    if (!this.lpkName.touched || !this.lpkName.errors) {
      return null;
    }
    if (this.lpkName.errors.maxlength) {
      return "Maximum 50 characters.";
    }
    if (this.lpkName.errors.required) {
      return "Kit Name is required";
    }
    if (this.lpkName.errors.pattern) {
      return "Kit Name can contain only alphanumeric, dash, or underscore characters.";
    }
    return "";
  }

  public subscribeToFormChange() {
    // When only no-index readytype is selected, shouldn't allow submit
    this.indexStrategy.valueChanges.subscribe(x => {
      this.onlyNoIndexReadTypeSelected = x && x.length === 1 && x[0] === ValidIndexingStrategies.None;
      if (this.onlyNoIndexReadTypeSelected) {
        this.indexStrategy.setErrors({ 'incorrectValue': true });
      } else if (!isEmpty(x)) {
        this.indexStrategy.setErrors(null);
      }
    })
  }

  ngOnInit() {
    super.ngOnInit();
    this.subscribeToFormChange();
  }

  ngAfterViewInit() {
    super.ngAfterViewInit();
  }

  /**
   * Upon submission, process the form and submit request to GSS to create LPK and handle return result
   */
  accept(modal: NgxSmartModalComponent) {
    const requestBody: ICreatePrepTabLibraryPrepKitRequest = {
      Name: this.lpkName.value,
      ValidReadTypes: this.readType.value,
      ValidIndexingStrategies: this.indexStrategy.value,
      DefaultRead1Cycles: this.defaultReadCycle1.value,
      DefaultRead2Cycles: this.defaultReadCycle2.value,
      file: this.csvFile.value
    };

    const formData = new FormData();
    Object.keys(requestBody)
      .forEach(key => formData.append(key, requestBody[key]));

    let path = `${environment.apiUrl.endsWith('/') ? environment.apiUrl : environment.apiUrl + '/'}${V1PRE3LIBRARYPREPKITURL}`;
    this.subs.sink = this.httpClient.post<any>(path, formData).subscribe({
      next: response => this.processSuccessfulKitCreation(response.Response, modal),
      error: response => this.parseErrorResponse(response.error)
    });
  }

  /**
   * Parse error to proper error messages.
   * GSS error code mapping should handle here.
   * @returns error message as HTML string
   */
  private parseErrorResponse(err) {
    let errMessage = 'Something is wrong, please try again later.';
    const errData = err || {};
    if (errData.Notifications && errData.Notifications.length > 0 && errData.Notifications[0].Item) {
      this.errorMessage = errData.Notifications[0].Item;
      errMessage = `<h4>${errData.Notifications[0].Item}</h4>`;
    } else {
      this.errorMessage = errMessage;
    }
    this.toastr.error(errMessage);
  }

  /**
   * Prompt successful toast message, emit the event and close the modal.
   * @param libraryPrepKitCreated created lpk data returned from GSS
   * @param modal instance needed to close the modal
   */
  private processSuccessfulKitCreation(libraryPrepKitCreated: IPrepTabLibraryPrepKit, modal: NgxSmartModalComponent) {
    this.toastr.success(`Your "${libraryPrepKitCreated.Name}" library prep kit has been successfully saved.`);
    const modalOuptut: ICreatePrepTabLibraryPrepKitModalOutput = { libraryPrepKitCreated };
    this.confirm.emit(modalOuptut);
    modal.close();
  }

  onCSVFileChange(event) {
    const reader = new FileReader();
    if (event.target.files && event.target.files.length) {
      const file = event.target.files[0];
      this.selectedCSVFile = file.name;
      this.lpkFormGroup.patchValue({
        csvFile: file
      });
    }
  }

}
